import React, { useState, useEffect } from "react";

import ls from "helpers/ls";
import api from "services/api";

import { Card, Dropdown } from "react-bootstrap";

export default function TopSalesProducts(props) {

    const { sync } = props;

    const [products, setProducts] = useState([])
    const [upd, setUpd] = useState(0)
    const [loadingProducts, setLoadingProducts] = useState(false)

    const [filter, setFilter] = useState({
        name: 'Mês Atual',
        date: {
            start: window.moment().startOf('month').format('YYYY-MM-DD'),
            end: window.moment().endOf('month').format('YYYY-MM-DD'),
        }
    });

    const lsKeyProducts = 'reports@initials:salesTop10Products';

    async function getTopSalesProducts(options) {
        setProducts([])
        setLoadingProducts(true);
        try {
            const { data } = await api.get('/reports/sales/salesOfProducts', {filters: {date: filter.date, limit: 10 }});
            if (typeof options === "undefined" || options.storage === true) ls.set(lsKeyProducts, data);
            comTopSalesProducts(data);
        } catch (error) {
            
        }
        setLoadingProducts(false);
    }
    
    function comTopSalesProducts(data) {
        setProducts(data);
    }
    function onChangeFilter(e, {target}) {
        const { dateStart, dateEnd } = target.dataset;
        const filter = {
            name: target.innerHTML,
            date: {
                start: dateStart,
                end: dateEnd
            }
        }
        setFilter(filter);
    }

    function getDate(n, s){
        return window.moment().subtract(n, s).format('YYYY-MM-DD');
    }

    useEffect(() => {
        if (ls.has(lsKeyProducts)) {
            try {
                let data = JSON.parse(ls.get(lsKeyProducts));
                comTopSalesProducts(data);
            } catch (error) {
                ls.remove(lsKeyProducts);
                getTopSalesProducts();
            }
        } else {
            getTopSalesProducts();
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sync > 0) {
            // ls.remove(lsKeyProducts);
            getTopSalesProducts();
        }
    // eslint-disable-next-line
    }, [sync]);

    useEffect(() => {
        if (upd === 0) {
            return setUpd(upd + 1);
        }
        getTopSalesProducts({ storage: false });
    // eslint-disable-next-line
    }, [filter])

    return (
        <Card>
            <Card.Body>
                <Dropdown onSelect={onChangeFilter}>
                    <Dropdown.Toggle variant="link" className="float-right text-muted" id="dropdown-basic">
                        {filter.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item 
                            data-date-start={window.moment().format('YYYY-MM-DD')} 
                            data-date-end={window.moment().format('YYYY-MM-DD')} 
                        >Hoje</Dropdown.Item>
                        <Dropdown.Item 
                            data-date-start={getDate(1, 'day')}
                            data-date-end={getDate(1, 'day')}
                        >Ontem</Dropdown.Item>
                        <Dropdown.Item 
                            data-date-start={window.moment().format('YYYY-MM-01')}
                            data-date-end={window.moment().endOf('month').format('YYYY-MM-DD')}
                        >Mês Atual</Dropdown.Item>
                        <Dropdown.Item 
                            data-date-start={getDate(7, 'days')}
                            data-date-end={window.moment().format('YYYY-MM-DD')} 
                        >Há 7 dias</Dropdown.Item>
                        <Dropdown.Item 
                            data-date-start={getDate(30, 'days')}
                            data-date-end={window.moment().format('YYYY-MM-DD')}
                        >Há 30 dias</Dropdown.Item>
                        <Dropdown.Item 
                            data-date-start={getDate(1, 'year')}
                            data-date-end={window.moment().format('YYYY-MM-DD')}
                        >Há 12 meses</Dropdown.Item>
                        <Dropdown.Item 
                            data-date-start={getDate(2, 'year')}
                            data-date-end={window.moment().format('YYYY-MM-DD')}
                        >Há 24 meses</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <h4 className="header-title mt-2 mb-4"><i className="mdi mdi-shopping"></i> 10 Produtos mais vendidos</h4>
                {   loadingProducts ? 
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status"></div>
                    </div>
                : 
                    products.length === 0 ? <span className="text-muted">Não há dados para exibir</span>
                :
                <div className="table-responsive">
                        <table className="table table-sm table-centered mb-0">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td className="font-weight-bold">Descrição</td>
                                    <td className="text-center font-weight-bold">Quantidade</td>
                                    <td className="text-right font-weight-bold">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map((Product, idx) => (
                                    <tr key={Product.Id}>
                                        <td>
                                            <h5 className="font-14 mb-1 font-weight-normal">{idx+1}</h5>
                                        </td>
                                        <td>
                                            <h5 className="font-14 mb-1 font-weight-normal">{Product.Description}</h5>
                                        </td>
                                        <td className="text-center">
                                            <h5 className="font-14 mb-1 font-weight-normal">{Product.Quantity}</h5>
                                        </td>
                                        <td>
                                            <h5 className="font-14 mb-1 font-weight-normal text-right">{
                                                parseFloat(Product.Total)
                                                .toLocaleString("pt-BR",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    })
                                            }</h5>
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </Card.Body>
        </Card>
    )
}