import React from 'react'
import { Pagination } from 'react-bootstrap'

export function LaravelPagination({ data, onClickPage, justify, rounded, size }) {

    if (data === null)          return <></>;
    if (data.last_page === 1)   return <></>;

    let active = data.current_page;
    let items = [];
    let classes = [];

    if (justify) {
        classes.push('justify-content-' + justify);
    }
    if (rounded) {
        classes.push('pagination-rounded');
    }
    if (size) {
        classes.push('pagination-' + size);
    }

    for (let number = 1; number <= data.last_page; number++) {
        items.push(
            <Pagination.Item 
                onClick={item => {
                    if (typeof onClickPage === "function") {
                        onClickPage.call(item, number);
                    }
                }} 
                key={number} 
                active={number === active}
            >
            {number}
            </Pagination.Item>,
        );
    }

    return (
        <Pagination className={classes.join(' ')}>
            {items}
        </Pagination>
    )
}