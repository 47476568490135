import React, {useState, useEffect} from 'react';
import {removeAccentuation} from 'helpers/functions';
import {Row, Col, Card, Form, Button, Alert, Spinner} from 'react-bootstrap';
import api from 'services/api';

export default function StepInstance({setStep}) {
	const [instanceName, SetInstanceName] = useState('');
	const [databaseName, SetDatabaseName] = useState('');
	const [serverName, SetServerName] = useState('');
	const [port, SetPort] = useState('');
	const [username, SetUsername] = useState('');
	const [password, SetPassword] = useState('');

	const [databasesInUse, setDatabasesInUse] = useState([]);
	const [runningProcess, setRunningProcess] = useState(false);
	const [databaseRegistered, setDatabaseRegistered] = useState(false);
	const [minDbVersionId, setMinDbVersionId] = useState(0);
	const [gettingDatabases, setGettingDatabases] = useState(false);
	const [debounceTimeout, setDebounceTimeout] = useState(null);
	const [isFormValid, setIsFormValid] = useState(false);
	const [alertMessage, setAlertMessage] = useState(true);
	const [message, setMessage] = useState({message: '', type: ''});

	async function getListOfDatabases() {
		if (isFormValid) {
			setGettingDatabases(true);
			try {
				const {data} = await api.get('/settings/instances/testConnection?databasesWithVersions=1', {
					host: serverName,
					port: port,
					username: username,
					password: password,
				});
				setMinDbVersionId(data.minDbVersionId);
				setDatabasesInUse(data.databases);
			} catch (error) {
				setAlertMessage(true);
				setMessage({
					message: `Erro ao tentar conectar (${error})`,
					type: 'danger',
				});
			} finally {
				setGettingDatabases(false);
			}
		}
	}

	async function register(e) {
		e.preventDefault();

		setRunningProcess(true);

		try {
			const response = await api.post('/settings/instances/register', {
				name: instanceName,
				server: serverName,
				port: port,
				username: username,
				password: password,
				database: databaseName,
				existing: 1,
			});

			setStep({
				current: 'Logo',
				data: {
					instance: {
						name: instanceName,
						server: serverName,
						port: port,
						username: username,
						password: password,
						existing: true,
						id: response.data.instance,
					},
				},
			});
		} catch (err) {
			if (err.response) {
				alert(err.response.data);
			}
		} finally {
			setRunningProcess(false);
		}
	}

	useEffect(() => {
		if (!isFormValid) {
			setMessage({message: 'Preencha todos os campos para poder listar o(s) banco(s) de dados!', type: 'warning'});
		}
		const valid = username && serverName && port && password;
		setIsFormValid(valid);
		if (valid) {
			setAlertMessage(false);
		}

		if (debounceTimeout) {
			clearTimeout(debounceTimeout);
		}

		const newTimeout = setTimeout(() => {
			getListOfDatabases();
		}, 1000);

		setDebounceTimeout(newTimeout);

		return () => clearTimeout(newTimeout);
	}, [username, serverName, port, password]);

	return (
		<Row className="justify-content-center">
			<Col md="7">
				<Card className="shadow-none">
					<Card.Body>
						<Form onSubmit={register}>
							{alertMessage ? <Alert variant={message.type}>{message.message}</Alert> : null}
							<Row>
								<Col xs="12" className="mt-4 mb-3">
									<Form.Group className="mb-3" controlId="controlinstancename">
										<Form.Label column={true}>Nome da Instância</Form.Label>
										<Form.Control
											type="text"
											value={instanceName}
											className="text-start border-1 shadow-none"
											style={{fontSize: 14}}
											autoComplete="off"
											placeholder="Ex: ExemploDB, DBExemplo, ExemploInstance"
											onChange={e => {
												let b = e.currentTarget.value;
												SetInstanceName(b);
											}}
										/>
									</Form.Group>
								</Col>
								<Col xs="12">
									<Row className="justify-content-around mb-3">
										<Col>
											<Form.Label column={true}>Username</Form.Label>
											<Form.Control
												type="text"
												style={{fontSize: 14}}
												autoComplete="off"
												placeholder="Ex: sa, master, root"
												className="text-start border-1 shadow-none"
												value={username}
												onChange={e => SetUsername(removeAccentuation(e.currentTarget.value).replace(/([^A-Za-z]*)/g, ''))}
											/>
										</Col>
										<Col>
											<Form.Label column={true}>Servidor</Form.Label>
											<Form.Control
												type="text"
												style={{fontSize: 14}}
												autoComplete="off"
												placeholder="Ex: sa.exemplo.com, @master.host.com, exemplo.com"
												className="text-start border-1 shadow-none"
												value={serverName}
												onChange={e => SetServerName(e.currentTarget.value)}
											/>
										</Col>
										<Col>
											<Form.Label column={true}>Porta</Form.Label>
											<Form.Control
												type="number"
												style={{fontSize: 14}}
												autoComplete="off"
												placeholder="Ex: 1442, 8883, 90"
												className="text-start border-1 shadow-none"
												value={port}
												onChange={e => SetPort(e.currentTarget.value)}
											/>
										</Col>
										<Col>
											<Form.Label column={true}>Senha</Form.Label>
											<Form.Control
												type="password"
												style={{fontSize: 14}}
												autoComplete="off"
												placeholder=""
												className="text-start border-1 shadow-none"
												value={password}
												onChange={e => SetPassword(e.currentTarget.value)}
											/>
										</Col>
									</Row>
									<Row className="justify-content-around mb-3">
										<Col>
											<Form.Label column={true}>Banco de Dados</Form.Label>
											{gettingDatabases ? (
												<span className="align-content-center">
                                                    <Spinner size="sm" variant="primary" animation="border"
																														 className="mr-1"/> Buscando dados...
                                                </span>
											) : (
												<Form.Control
													disabled={gettingDatabases}
													className="border-bottom-1 text-start shadow-none"
													as="select"
													style={{WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none'}}
													onChange={e => SetDatabaseName(e.target.value)}
												>
													{databasesInUse.map((db, idx) => (
														<option key={idx} disabled={minDbVersionId >= db.version.id} value={db.name}>
															{db.name}{minDbVersionId >= db.version.id && (' (Versão Incompatível)')}
														</option>
													))}
												</Form.Control>
											)}
										</Col>
									</Row>
								</Col>
							</Row>

							{!runningProcess ? (
								<div className="text-center mt-4">
									<Button
										variant={'success'}
										className={`shadow-none`}
										type="submit"
										disabled={[instanceName, databaseName].indexOf('') > -1}
									>
										Continuar
									</Button>
								</div>
							) : (
								<div className="d-flex justify-content-center mt-4">
									<div className="spinner-border text-success" role="status"></div>
								</div>
							)}
						</Form>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}
