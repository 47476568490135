import React, { useState, useEffect } from "react";

import Cookies    from "helpers/cookies";
import api        from "services/api";

import { Toast }  from "helpers/Hyper";

// import Loading    from "components/loading";
import Login      from "Login";
import Dashboard  from "Dashboard";

// import ModulesAvailable from "Login/_ModulesAvailable";

export default function App() {

  const [isLogged, setLogged]   = useState(false);
  const [inLoading, setLoading] = useState(true);
  const [user, setUser]         = useState({});

  // const [module, setModule]     = useState(null);

  const [sync, setsync]         = useState(0);

  function synchronize() {
    api.cancelAllRequests();
    window.localStorage.clear();
    setsync(1);
    setTimeout(() => {
      setsync(0);
    }, 0);
  }

  useEffect(() => {
    const Token = Cookies.get(process.env.REACT_APP_COOKIE_AUTH);
    if (Token) {
      hasSession();
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Finalize Session
   */
  function logoutSession() {
    setLogged(false);
    setUser({});
    window.localStorage.clear();

    // Prevent the request being canceled (all xhr requests are canceled when components are unmounted)
    setTimeout(() => {
      api.delete('/auth').then(() => {
        Cookies.remove(process.env.REACT_APP_COOKIE_MODLOCK, '/', process.env.REACT_APP_COOKIE_DOMAIN);
        Cookies.remove(process.env.REACT_APP_COOKIE_AUTH, '/', process.env.REACT_APP_COOKIE_DOMAIN);
      }).catch(() => {});
    }, 1);
    
  }

  /**
   * Check if has session
   */
  async function hasSession() {
    try {
      const { data } = await api.get('/auth');
      if (data.has) {

        const { session, user } = data;

        const mod_lock = Cookies.get(process.env.REACT_APP_COOKIE_MODLOCK);

        if (mod_lock && !session.modules.active) {
          let mod_act = session.modules.available.filter(x => x.Lock === mod_lock)[0];
          if (mod_act) {
            session.modules.active = mod_act.Name;
          } else {
            Cookies.remove(process.env.REACT_APP_COOKIE_MODLOCK, '/', process.env.REACT_APP_COOKIE_DOMAIN);
          }
        }

        if (session.modules.active === 'Settings' || (session.ready.instance && session.ready.companies)) {
          setLogged(true);
        }

        setUser({
          ...user,
          session: session
        });

      } else {
        Cookies.remove(process.env.REACT_APP_COOKIE_AUTH, '/', process.env.REACT_APP_COOKIE_DOMAIN);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1);
    } catch (error) {
      Toast(
        'Atenção',
        ['Tentativa de conexão com servidor retornou um erro.', 'A página irá atualizar para tentar uma nova conexão!', 'Se o erro persistir, verifique sua conexão com a Internet ou entre em contato com Rhaimes Soluções.'],
        'top-center',
        undefined,
        'error',
        15000
      );
      window.$('#root .loading').css('background','#fa5c7c').find('.spinner-center').addClass('text-white');
      setTimeout(() => {
        window.location.reload()
      }, 15000);
    }
  }

  return (
    <>
      {inLoading ? <>Carregando...</> : isLogged ? (
        <Dashboard
          user={user}
          setUser={setUser}
          logoutSession={logoutSession}
          sync={sync}
          synchronize={synchronize}
        />
      ) : (
        <Login user={user} setUser={setUser} setLogged={setLogged} />
      )}
    </>
  );
}
