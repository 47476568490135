import React, { useState, useEffect, useCallback } from 'react'

import api from 'services/api'
import moment from 'moment'
import CurrencyFormat from 'react-currency-format'

import Datetime from 'react-datetime'

import { Row, Col, Button, Modal, Spinner, ButtonGroup, Card, Form } from 'react-bootstrap'

import MonthlyAverage from './components/MonthlyAverage'
import TableSalesYearMeasure from './components/TableSalesYearMeasure'
import TableSalesYear from './components/TableSalesYear'

import { realCurrency } from 'helpers/functions'

import undrawVisualData from 'assets/img/undraw_visual_data_b1wx.svg'

import 'moment/locale/pt-br'

const _ = {};
      _.ww        = window.innerWidth >= 768;
      _.months    = moment.months();
      _.cMonth    = new Date().getMonth();
      _.cDay      = new Date().getDate();
      _.slcDates  = _.months.map((label, idx) => ({ value: idx + 1, label }));
      _.cYear     = new Date().getFullYear();
      _.lYear     = _.cYear-1;
      _.blYear    = _.lYear-1;

function calcMonthsAverage(sales) {
  // sales.currentYear
  // sales.lastYear
  // sales.yearBeforeLast

  let sl = Object.entries(sales);
  let years = sl.length;

  return _.months.map((month, idx) => {
    
    let div = years;
    let total = sl.reduce((v, year) => {
      if (year[0] === "currentYear" && idx === _.cMonth) {
        div = div-1;
        return v;
      }
      let u = year[1].find(i => i.Month === (idx+1));
      if (u.Total === 0) {
        div = div-1;
      }
      return u.Total + v;
    }, 0)
    
    return {
      Month: idx + 1,
      MonthName: month,
      Total: total / div
    }
  });
}


function RenderCardtargetStats({ title, data }) {

  const [bgColor, txtColor] = (
    data.pv < -95 ? ['light','dark'] : 
    data.pv < -45 ? ['info', 'white'] : 
    data.pv < -10 ? ['primary','white'] : 
    data.pv < 10 ? ['success','white'] : 
    data.pv < 25 ? ['warning','dark'] : ['danger','white']
  );
  const iconCtl = 'mdi-'+ (
    data.pv < -45 ? 'snowflake' : 
    data.pv < -10 ? 'weather-windy' : 
    data.pv < 10 ? 'waves' : 
    data.pv < 25 ? 'alert' : 
    'fire'
  );

  return (
    <Col>
    <Card text="dark" className="shadow-none mb-0">
      <Card.Body className="p-2 font-weight-bold text-uppercase">
        <h6 className="mt-0">{title}</h6>
        <div className={`p-1 mb-1 text-${txtColor} bg-${bgColor} rounded shadow-sm`}>
          <i className={`text-${txtColor} mdi mdi-arrow-${data.pv < 0 ? 'down':'up'}-bold`}></i>
          <i className={`mdi ${iconCtl} mr-1`}></i>
          {data.pv.toFixed(0)}%
        </div>
        <div>
          <i className={`mdi mdi-${data.pv < 0 ? 'minus':'plus'}-circle text-${data.pv < 0 ? 'success':'danger'} mr-1`}></i>
          {realCurrency(Math.abs(data.diffVal))}
        </div>
      </Card.Body>
    </Card>
    </Col>
  )
}


const _defSalesYear = {
  pending: true,
  loaded: false,
  data: {
    currentYear: [],
    lastYear: [],
    yearBeforeLast: []
  },
  monthsAverage: []
}

const _defTargetMonthStats = {
  lastMonth: {
    name: '',
    pv: 0,
    diffVal: 0
  },
  sameMonthOfLastYear: {
    name: '',
    pv: 0,
    diffVal: 0
  },
  monthAverage: {
    pv: 0
  }
}

export default function ModalTargetsRegister({ company, year, setYear, month, btnText, forceReloadSalesTarget }) {

  const [slcDate, setSlcDate] = useState({ month: month || _.cMonth, year: year || _.cYear });

  const [mdShow, stMdShow] = useState(false);
  const mdTgg = () => stMdShow(!mdShow);

  const [modeVw, setModeVw]             = useState(_.cYear)
  const [salesYears, setSalesYears]     = useState(_defSalesYear)

  const [baseTypeCalc, setBaseTypeCalc] = useState('last_twelve_months')
  const [datesCustomBaseCalc, setCustomBaseCalc] = useState({ 
    start: `${_.cYear}-01-01`, 
    end: `${_.cYear}-${(_.cMonth+1).toString().padStart(2, '0')}-${_.cDay.toString().padStart(2, '0')}`
  })

  const [targetVal, setTargetVal]           = useState(0)
  const [targetStats, setTargetStats]       = useState(_defTargetMonthStats)

  const [reqST, setReqST] = useState(false);

  const calcStatisticsGoalMonth = useCallback(
    () => {

      if (!salesYears.loaded) 
        return;

      const stats = {..._defTargetMonthStats};
      
      let lastMonth = (
        _.cMonth === 0 ? 
          salesYears.data.lastYear
        :
          salesYears.data.currentYear
      ).find(
        x => (x.Month - 1) === (_.cMonth === 0 ? 11 : _.cMonth - 1)
      );

      let sameMonthOfLastYear = (
        salesYears.data.lastYear
      ).find(x => (x.Month - 1) === _.cMonth);

      let monthAverage = (
        salesYears.monthsAverage
      ).find(x => (x.Month - 1) === _.cMonth);

      stats.lastMonth.name    = lastMonth.MonthName;
      stats.lastMonth.pv      = ((targetVal-(lastMonth.Total))/(lastMonth.Total))*100;
      stats.lastMonth.diffVal = targetVal-(lastMonth.Total);

      stats.sameMonthOfLastYear.name    = sameMonthOfLastYear.MonthName;
      stats.sameMonthOfLastYear.pv      = ((targetVal - sameMonthOfLastYear.Total)/sameMonthOfLastYear.Total)*100;
      stats.sameMonthOfLastYear.diffVal = targetVal - sameMonthOfLastYear.Total;

      stats.monthAverage.pv = ((targetVal - monthAverage.Total)/monthAverage.Total)*100;
      stats.monthAverage.diffVal = targetVal - monthAverage.Total;

      
      setTargetStats(stats);

    },
    [targetVal, salesYears]
  )


  function getSalesByYear(year, companies) {

    const filters = {
      date: {
        start: year + '-01-01',
        end: year + '-12-31'
      },
      companies
    }

    return api.get('/reports/sales/salesOfYear', { filters });
  }

  function santySalesMonths(data) {
    let yms = data;
    if (!data.length) {
      return _.months.map((month, idx) => ({
        Month: idx+1,
        MonthName: month,
        Total: 0
      }));
    }
    return yms.map(x => ({
      Month: x.Month -1+1,
      MonthName: _.months[x.Month - 1],
      Total: Number(x.Total || 0)
    }));
  }

  async function onSubConfirmSaleTarget(event) {
    event.preventDefault();

    setReqST(true);

    const base = { type: baseTypeCalc };

    if (baseTypeCalc === 'custom') {
      
      base.startDate = datesCustomBaseCalc.start;
      base.endDate = datesCustomBaseCalc.end;
    
    }

    try {
      await api.post('/reports/sales/targets', {
        companyId: company.Id,
        month: slcDate.month + 1,
        year: slcDate.year,
        value: targetVal,
        base
      });

      mdTgg();

      setTimeout(() => {
        if (year !== slcDate.year) {
          setYear(slcDate.year);
        } else {
          forceReloadSalesTarget();
        }
      }, 500);
      
    } catch (err) {
      alert('Não foi possível definir meta! Erro interno no sistema.');
      console.error(err.response);
    }
  }

  useEffect(() => {

    calcStatisticsGoalMonth();

  }, [calcStatisticsGoalMonth])
  
  useEffect(() => {

    if (mdShow && !salesYears.loaded) {
      async function getSales() {

        const defSalesYear = { ..._defSalesYear }
        
        const [
          currentYear, 
          lastYear,
          yearBeforeLast,
        ] = await Promise.all([
          getSalesByYear(_.cYear, company.Id),
          getSalesByYear(_.lYear, company.Id),
          getSalesByYear(_.blYear, company.Id)
        ]);

        defSalesYear.pending = false;
        defSalesYear.loaded = true;

        defSalesYear.data.currentYear    = santySalesMonths(currentYear.data);
        defSalesYear.data.lastYear       = santySalesMonths(lastYear.data);
        defSalesYear.data.yearBeforeLast = santySalesMonths(yearBeforeLast.data);

        defSalesYear.monthsAverage = calcMonthsAverage(defSalesYear.data);

        setSalesYears(defSalesYear);

      }
      getSales();
    }
    
  }, [company, salesYears, mdShow])
  

  return (
    <>
      <Button 
        size="sm" 
        onClick={mdTgg}
        className="bg-success text-white shadow-sm border-0 font-13"
      >
        {btnText || 'DEFINIR META'}
      </Button>

      <Modal restoreFocus={false} size="lg" show={mdShow} onHide={mdTgg}>
        <Modal.Body>

          <div className="d-flex justify-content-between align-items-center">
            <div 
              style={{
                fontSize: '45px',
                color: '#3f3d55',
              }}
            >
              <div style={{letterSpacing:3,fontSize:13,color:'#545454'}}>DEFINIR</div>
              META
              <span style={{color:'#eb6b70'}}>VENDAS</span>
            </div>
            <img 
              src={undrawVisualData} 
              alt="" 
              height="164"
            />
          </div>

          <div className="my-4">

            {salesYears.loaded ? (
              <>
                <div className="d-flex justify-content-end align-items-center mb-3">
                  <div>
                    <ButtonGroup>
                      <Button 
                        size="sm" 
                        onClick={() => setModeVw(_.blYear)} 
                        className="font-12 shadow-sm border-0" 
                        variant={modeVw === _.blYear ? 'dark' : 'light'}
                      >
                        {_.blYear}
                      </Button>
                      <Button 
                        size="sm" 
                        onClick={() => setModeVw(_.lYear)} 
                        className="font-12 shadow-sm border-0" 
                        variant={modeVw === _.lYear ? 'dark' : 'light'}
                      >
                        {_.lYear}
                      </Button>
                      <Button 
                        size="sm" 
                        onClick={() => setModeVw(_.cYear)} 
                        className="font-12 shadow-sm border-0" 
                        variant={modeVw === _.cYear ? 'dark' : 'light'}
                      >
                        {_.cYear}
                      </Button>
                      <Button 
                        size="sm" 
                        onClick={() => setModeVw('differYears')} 
                        className="font-12 shadow-sm border-0" 
                        variant={modeVw === 'differYears' ? 'dark' : 'light'}
                      >
                        Comparação
                      </Button>
                    </ButtonGroup>

                    <Button 
                      size="sm" 
                      onClick={() => setModeVw('monthlyAverage')} 
                      className="font-12 ml-2 shadow-sm border-0" 
                      variant={modeVw === 'monthlyAverage' ? 'dark' : 'primary'}
                    >
                      Média Mensal
                    </Button>
                  </div>


                </div>

                {modeVw === 'differYears' ? (
                  <TableSalesYearMeasure {...salesYears.data} _={_} />
                ) : modeVw === 'monthlyAverage' ? (
                  <MonthlyAverage data={salesYears.monthsAverage} salesOf={salesYears.data} _={_} />
                ) : (
                  <TableSalesYear 
                    data={salesYears.data[
                      (
                        modeVw === _.cYear ? 
                        'currentYear' : 
                        modeVw === _.lYear ? 
                        'lastYear' : 
                        modeVw === _.blYear ? 
                        'yearBeforeLast' :
                        -1
                      )
                    ]}
                    year={modeVw}
                    _={_}
                  />
                )}

                <Row>
                  <Col md="6">
                    <h3 className="mb-1 text-dark font-weight-normal">DEFINIR META</h3>
                    <div className="font-11 mb-1">para</div>
                    <Datetime 
                      viewMode="months"
                      closeOnSelect={true}
                      timeFormat={false}
                      dateFormat="MMMM YYYY"
                      value={new Date(slcDate.year, slcDate.month)}
                      onChange={(mm) => setSlcDate({ month: mm.month(), year: mm.year() })}
                    />
                    <Form onSubmit={onSubConfirmSaleTarget} className="mt-1">
                      <div className="font-11 mb-1">R$</div>
                      <Form.Group controlId="uP4RtsrPV8S1">
                        <CurrencyFormat
                          value={targetVal || ''}
                          displayType={'input'}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="." 
                          placeholder="R$ 0.000,00" 
                          className="form-control font-18 py-3"
                          onValueChange={($) => {
                            setTargetVal(isNaN($.floatValue) ? 0 : $.floatValue);
                          }}
                        />
                      </Form.Group>
                      <Button 
                        type="submit" 
                        variant="dark" 
                        className="mt-3 shadow-none" 
                        disabled={reqST || targetVal === 0}
                        block
                      > 
                        {!reqST ? 'DEFINIR' : 'CARREGANDO...'}
                      </Button>
                    </Form>
                  </Col>
                  <Col md="6">
                    <div>
                      <div className="my-2">Qual período deve ser calculado a média de valores que serão distribuídos pelos dias da semana?</div>
                      <Form.Check
                        custom
                        type="radio"
                        label="Últimos 12 meses"
                        name="base_type_calc"
                        id="J9i35qYdb4QaxcFcyL"
                        onChange={() => setBaseTypeCalc('last_twelve_months')}
                        checked={baseTypeCalc === 'last_twelve_months'}
                      />
                      <Form.Check
                        custom
                        type="radio"
                        label={`${_.months[slcDate.month]} de ${_.lYear}`}
                        name="base_type_calc"
                        id="1EBYk6Min6QVCKVE"
                        onChange={() => setBaseTypeCalc('same_month_previous_year')}
                        checked={baseTypeCalc === 'same_month_previous_year'}
                      />
                      <Form.Check
                        custom
                        type="radio"
                        label={`Média ${_.months[slcDate.month]}`}
                        name="base_type_calc"
                        id="wj3iElzau1S4xFFFAp"
                        onChange={() => setBaseTypeCalc('average_month')}
                        checked={baseTypeCalc === 'average_month'}
                      />
                      <Form.Check
                        custom
                        type="radio"
                        label="Período Personalizado"
                        name="base_type_calc"
                        id="NrMyHLjcI7rgHzjUwMw"
                        onChange={() => setBaseTypeCalc('custom')}
                        checked={baseTypeCalc === 'custom'}
                      />
                      {baseTypeCalc === 'custom' ? (
                      <Row className="mt-3">
                        <Col>
                          <b>Data Início</b>
                          <Datetime 
                            closeOnSelect={true}
                            timeFormat={false}
                            dateFormat="DD MMMM YYYY"
                            defaultValue={new Date(_.cYear, 0, 1)}
                            onChange={(mm) => setCustomBaseCalc({ ...datesCustomBaseCalc, start: mm.format('YYYY-MM-DD') })}
                          />
                        </Col>
                        <Col>
                          <b>Data Final</b>
                          <Datetime 
                            closeOnSelect={true}
                            timeFormat={false}
                            dateFormat="DD MMMM YYYY"
                            defaultValue={new Date(_.cYear, _.cMonth, _.cDay)}
                            onChange={(mm) => setCustomBaseCalc({ ...datesCustomBaseCalc, end: mm.format('YYYY-MM-DD') })}
                          />
                        </Col>
                      </Row>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs="12" className="mt-4">
                    <Row>
                      <RenderCardtargetStats data={targetStats.lastMonth} title={"mês anterior"} />
                      <RenderCardtargetStats data={targetStats.sameMonthOfLastYear} title={targetStats.sameMonthOfLastYear.name + ' ' + _.lYear} />
                      <RenderCardtargetStats data={targetStats.monthAverage} title={`média ${_.months[_.cMonth]}`} />
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner animation="border" variant="dark"/>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}