import React, { useEffect } from 'react'

import { Row, Col, Card, Button } from "react-bootstrap";
import { realCurrency } from "helpers/functions";

export default function Flows({
    setActivePageFlow,
    activePageFlow,
    filtersPageFlow,
    dataAccountsBalancesPrevious,
    dataAccountsBalancesCurrent,
    dataCashFlowsCredits,
    dataCashFlowsDebts,
    getReportAccountPlan,
    applyFilterDatesPageFlow
}) {

    useEffect(() => {
        let mm = window.moment;
        window.$('.flows button[data-toggle-custom="daterangepicker"]').daterangepicker({
            showDropdowns: true,
            ranges: {
                'Hoje': [mm(), mm()],
                'Ontem': [mm().subtract(1, 'days'), mm().subtract(1, 'days')],
                'Últimos 7 dias': [mm().subtract(6, 'days'), mm()],
                'Últimos 30 dias': [mm().subtract(29, 'days'), mm()],
                'Este mês': [mm().startOf('month'), mm().endOf('month')],
                'Mês anterior': [mm().subtract(1, 'month').startOf('month'), mm().subtract(1, 'month').endOf('month')],
                'Últimos 6 meses': [mm().subtract(6, 'month').startOf('month'), mm()],
                'Últimos 12 meses': [mm().subtract(1, 'year'), mm()]
            },
            opens: 'center',
            locale: {
                applyLabel: "Aplicar",
                cancelLabel: "Cancelar",
                customRangeLabel: "Intervalo personalizado",
            },
            ...filtersPageFlow
        }).on('apply.daterangepicker', applyFilterDatesPageFlow);
    // eslint-disable-next-line
    }, [])

    return (
        <div className="flows">
            <Row className="mb-4">
                <Col sm="2">
                    <Button variant="link" onClick={() => setActivePageFlow(!activePageFlow)}>Contas</Button>
                </Col>
                <Col>
                    <div className="text-center">
                        <h3 className="mb-3">DEMONSTRATIVO FLUXO DE CAIXA</h3>
                        <Button variant="link" data-toggle-custom="daterangepicker">Período {filtersPageFlow.startDate} - {filtersPageFlow.endDate}</Button>
                    </div>
                </Col>
                <Col  sm="2">
                    {/* <div className="text-right">
                        <Button variant="link">Imprimir <i className="mdi mdi-printer"></i></Button>
                    </div> */}
                </Col>
            </Row>

            <Row className="mb-3">
                <Col xl="3" lg="6">
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="float-right">
                                <i className="mdi mdi-transfer-left widget-icon bg-primary rounded-circle text-white" />
                            </div>
                            <h5 className="text-muted font-weight-normal mt-0" title="Revenue">Saldo Anteriror</h5>
                            <h3 className="mt-4 mb-3">{
                                dataAccountsBalancesPrevious !== null ?
                                    realCurrency(dataAccountsBalancesPrevious.balances.reduce((v, i) => parseFloat(i.AccountBalance) + v,0))
                                : 
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                            }</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" lg="6">
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="float-right">
                                <i className="mdi mdi-bank-transfer-in widget-icon bg-success rounded-circle text-white" />
                            </div>
                            <h5 className="text-muted font-weight-normal mt-0" title="Revenue">Total Entradas</h5>
                            <h3 className="mt-4 mb-3">{
                                dataCashFlowsCredits !== null ?
                                    realCurrency(dataCashFlowsCredits.reduce((v, i) => i.Total + v, 0))
                                :
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                            }</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" lg="6">
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="float-right">
                                <i className="mdi mdi-bank-transfer-out widget-icon bg-warning rounded-circle text-white" />
                            </div>
                            <h5 className="text-muted font-weight-normal mt-0" title="Revenue">Total Saídas</h5>
                            <h3 className="mt-4 mb-3">{
                                dataCashFlowsDebts !== null ?
                                    realCurrency(dataCashFlowsDebts.reduce((v, i) => i.Total + v, 0))
                                :
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                            }</h3>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl="3" lg="6">
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="float-right">
                                <i className="mdi mdi-wallet widget-icon bg-info rounded-circle text-white" />
                            </div>
                            <h5 className="text-muted font-weight-normal mt-0" title="Revenue">Saldo Final</h5>
                            <h3 className="mt-4 mb-3">{
                                dataAccountsBalancesCurrent !== null ?
                                    realCurrency(dataAccountsBalancesCurrent.balances.reduce((v, i) => parseFloat(i.AccountBalance) + v,0))
                                : 
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                            }</h3>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col>
                        <div className="card mb-3 bg-transparent shadow-none">
                            <div className="card-header p-0" id="headingOne">
                                <h4 className="m-0 pb-1">
                                    CONTAS
                                    <small className="float-right">Saldo Anteriror</small>
                                </h4>
                            </div>
                            {dataAccountsBalancesPrevious !== null ? (
                                <div className="card-body accprevious px-2 pt-2">
                                    {
                                        dataAccountsBalancesPrevious.balances.map((x, idx) => (
                                            <div className="px-1 rounded" key={idx}>
                                                <span>{x.Description}</span>
                                                <span className={`float-right${parseFloat(x.AccountBalance) < 0 ? ' text-danger' : ''}`}>{realCurrency(parseFloat(x.AccountBalance))}</span>
                                            </div>
                                        ))
                                    }
                                    <div className="px-1 mt-2 rounded">
                                        <strong>TOTAL</strong>
                                        <strong className="float-right">
                                            {realCurrency(dataAccountsBalancesPrevious.balances.reduce((v, i) => parseFloat(i.AccountBalance) + v,0))}
                                        </strong>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <div className="spinner-border" role="status"></div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col lg="6">
                        <div className="card mb-0 bg-transparent shadow-none">
                            <div className="card-header p-0" id="headingOne">
                                <h4 className="m-0 pb-1">
                                    ENTRADAS
                                    <span className="float-right">{dataCashFlowsCredits !== null && realCurrency(dataCashFlowsCredits.reduce((v, i) => i.Total + v, 0))}</span>
                                </h4>
                            </div>
                                <div className="card-body px-0 pt-1">
                                    {
                                    dataCashFlowsCredits === null ?
                                        (
                                            <div className="d-flex justify-content-center mt-3">
                                                <div className="spinner-border" role="status"></div>
                                            </div>
                                        )
                                    : dataCashFlowsCredits.map((item, idx) => (
                                        <div className="mb-1" key={idx}>
                                            <div className="px-2 py-1 f-acc-title">
                                                <span>{item.name === 'NotInformed' ? 'NÃO INFORMADO' : item.name}</span>
                                                <div className="float-right"><strong>{realCurrency(item.Total)}</strong></div>
                                            </div>
                                            <div>
                                                <ul className="list-group list-group-flush">
                                                    {item.data.map((dt, idxs) => (
                                                        <li className="list-group-item" onClick={() => getReportAccountPlan('C', dt.AccountSyntheticId, dt.AccountPlanId)} key={idxs}>
                                                            <Row>
                                                                <Col>
                                                                    <span className="w-50">{dt.AccountPlan === 'NotInformed' ? 'NÃO INFORMADO' : dt.AccountPlan }</span>
                                                                </Col>
                                                                <Col>
                                                                    <div className="float-right">{realCurrency(parseFloat(dt.Value))}</div>
                                                                </Col>
                                                            </Row>
                                                            <div className="p-2 bg-warning d-none">
                                                                asdasd
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="card mb-0 bg-transparent shadow-none">
                            <div className="card-header p-0" id="headingTwo">
                                <h4 className="m-0 pb-1">
                                    SAÍDAS
                                    <span className="float-right">{dataCashFlowsDebts !== null && realCurrency(dataCashFlowsDebts.reduce((v, i) => i.Total + v, 0))}</span>
                                </h4>
                            </div>
                            <div className="card-body px-0 pt-1">
                            {
                            dataCashFlowsDebts === null ?
                                (
                                    <div className="d-flex justify-content-center mt-3">
                                        <div className="spinner-border" role="status"></div>
                                    </div>
                                )
                            : dataCashFlowsDebts.map((item, idx) => (
                                <div className="mb-1" key={idx}>
                                    <div className="px-2 py-1 f-acc-title">
                                        <span>{item.name === 'NotInformed' ? 'NÃO INFORMADO' : item.name}</span>
                                        <div className="float-right"><strong>{realCurrency(item.Total)}</strong></div>
                                    </div>
                                    <div>
                                        <ul className="list-group list-group-flush">
                                            {item.data.map((dt, idxs) => (
                                                <li className="list-group-item py-1" onClick={() => getReportAccountPlan('D', dt.AccountSyntheticId, dt.AccountPlanId)} key={idxs}>
                                                    <Row>
                                                        <Col>
                                                            <span className="w-50">{dt.AccountPlan === 'NotInformed' ? 'NÃO INFORMADO' : dt.AccountPlan }</span>
                                                        </Col>
                                                        <Col>
                                                            <div className="float-right">{realCurrency(parseFloat(dt.Value))}</div>
                                                        </Col>
                                                    </Row>
                                                    <div className="p-2 bg-warning d-none">
                                                        asdasd
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="card mb-3 bg-transparent shadow-none">
                            <div className="card-header p-0" id="headingOne">
                                <h4 className="m-0 pb-1">
                                    CONTAS
                                    <small className="float-right">Saldo Atual</small>
                                </h4>
                            </div>
                            {dataAccountsBalancesCurrent !== null ? (
                                <div className="card-body accprevious px-2 pt-2">
                                    {
                                        dataAccountsBalancesCurrent.balances.map((x, idx) => (
                                            <div className="px-1 rounded" key={idx}>
                                                <span>{x.Description}</span>
                                                <span className={`float-right${parseFloat(x.AccountBalance) < 0 ? ' text-danger' : ''}`}>{realCurrency(parseFloat(x.AccountBalance))}</span>
                                            </div>
                                        ))
                                    }
                                    <div className="px-1 mt-2 rounded">
                                        <strong>TOTAL</strong>
                                        <strong className="float-right">
                                            {realCurrency(dataAccountsBalancesCurrent.balances.reduce((v, i) => parseFloat(i.AccountBalance) + v,0))}
                                        </strong>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <div className="spinner-border" role="status"></div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>

            </div>
                
        </div>
    )
}