import React from 'react'

import { Table } from 'react-bootstrap'

import { realCurrency } from 'helpers/functions'


export default function TableSalesYearMeasure({ yearBeforeLast, lastYear, currentYear, _ }) {

	const mergeLC = _.months.map((Month, idx) => {

		let idxL = lastYear.findIndex(i => i.Month === (idx + 1));
		let idxC = currentYear.findIndex(i => i.Month === (idx + 1));
		let idxB = yearBeforeLast.findIndex(i => i.Month === (idx + 1));
		
		return {
			Month: idx,
			MonthName: Month,
			_: {
				yearBeforeLast: yearBeforeLast[idxB].Total,
				pv1: (((lastYear[idxL].Total-yearBeforeLast[idxB].Total)/yearBeforeLast[idxB].Total)*100),
				lastYear: lastYear[idxL].Total,
				pv2: (((currentYear[idxC].Total-lastYear[idxL].Total)/lastYear[idxL].Total)*100),
				currentYear: currentYear[idxC].Total,
			}
		}
	});

	console.log(mergeLC);
	

	return (
		<>
		<Table className="font-12 mb-1" size="sm">
			<thead>
				<tr>
					<th>#</th>
					<th className="text-right" width="100">{_.blYear}</th>
					<th className="text-right" width="100">{_.lYear}</th>
					<th className="text-right" width="75">&nbsp;</th>
					<th className="text-right" width="100">{_.cYear}</th>
					<th className="text-right" width="75">&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				{mergeLC.map(x => (
					<tr className={_.cMonth === x.Month?'font-weight-bold text-dark':''} key={x.Month}>
						<td className="py-1">{x.MonthName}</td>
						<td className="py-1 text-right">{realCurrency(x._.yearBeforeLast)}</td>
						<td className="py-1 text-right">{realCurrency(x._.lastYear)}</td>
						<td className="py-1 px-0 text-right">
							<span>{x._.pv1 === Infinity ? '0' : realCurrency(x._.pv1).replace('R$', '')}%</span>
							<i className={`ml-1 mdi mdi-arrow-${x._.pv1 > 0 ? 'up-bold text-success':'down-bold text-danger'}`}></i>
						</td>
						<td className="py-1 text-right">{realCurrency(x._.currentYear)}</td>
						<td className="py-1 px-0 text-right">
							<span>{x._.pv2 === Infinity ? '0' : realCurrency(x._.pv2).replace('R$', '')}%</span>
							<i className={`ml-1 mdi mdi-arrow-${x._.pv2 > 0 ? 'up-bold text-success':'down-bold text-danger'}`}></i>
						</td>
					</tr>
				))}
			</tbody>
		</Table>
		<hr />
		</>
	);
}