import React, { useState, useEffect } from "react";

import { Donut, Legend, ResponsiveContainer } from "britecharts-react";
import { Card, Dropdown } from "react-bootstrap";
import api from "services/api";
import ls from "helpers/ls";

export default function ChartSalesPaymentMethods(props) {

    const { sync } = props;

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [highlightedSliceId, setHighlightedSliceId] = useState(9999)

    const [upd, setUpd] = useState(0)
    const [filter, setFilter] = useState({
        name: 'Mês Atual',
        date: {
            start: window.moment().format('YYYY-MM-01')
        }
    })

    const lsKeyPaymentMethods = 'reports@initials:salesPaymentMethods';

    async function getSalesPaymentMethods() {
        setLoading(true);
        try {
            const { data } = await api.get('/reports/sales/salesOfPaymentMethods', {filters: {date: filter.date}});
            let paymentMethods = data.map((i, idx) => {
                return {
                    id: idx+1,
                    quantity: parseFloat(i.Total),
                    name: i.Description
                }
            });
            ls.set(lsKeyPaymentMethods, paymentMethods);

            comSalesPaymentMethods(paymentMethods);
        } catch (error) {
            setLoading(false);    
        }
    }
    function comSalesPaymentMethods(data) {
        setData(data);
        setLoading(false);
    }

    function getDate(n, s){
        return window.moment().subtract(n, s).format('YYYY-MM-DD');
    }

    function onChangeFilter(e, {target}) {
        const { dateStart, dateExact } = target.dataset;
        const filter = {
            name: target.innerHTML,
            date: {
                start: dateStart
            }
        }
        if (typeof dateExact !== "undefined") {
            filter.date.exact = dateExact;
        }
        setFilter(filter);
    }

    useEffect(() => {
        if (ls.has(lsKeyPaymentMethods)) {
            try {
                let data = JSON.parse(ls.get(lsKeyPaymentMethods));
                comSalesPaymentMethods(data);
            } catch (error) {
                ls.remove(lsKeyPaymentMethods);
                getSalesPaymentMethods();
            }
        } else {
            getSalesPaymentMethods();
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sync > 0) {
            // ls.remove(lsKeyPaymentMethods);
            getSalesPaymentMethods();
        }
    // eslint-disable-next-line
    }, [sync]);

    useEffect(() => {
        if (upd === 0) {
            return setUpd(upd + 1);
        }
        getSalesPaymentMethods();
    // eslint-disable-next-line
    }, [filter])

    return (
        <Card>
            <Card.Body>
                    <Dropdown onSelect={onChangeFilter}>
                        <Dropdown.Toggle variant="link" className="float-right text-muted" id="dropdown-basic">
                            {filter.name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item data-date-start={window.moment().format('YYYY-MM-DD')} data-date-exact="true">Hoje</Dropdown.Item>
                            <Dropdown.Item data-date-start={getDate(1, 'day')} data-date-exact="true">Ontem</Dropdown.Item>
                            <Dropdown.Item data-date-start={window.moment().format('YYYY-MM-01')}>Mês Atual</Dropdown.Item>
                            <Dropdown.Item data-date-start={getDate(7, 'days')}>Há 7 dias</Dropdown.Item>
                            <Dropdown.Item data-date-start={getDate(30, 'days')}>Há 30 dias</Dropdown.Item>
                            <Dropdown.Item data-date-start={getDate(1, 'year')}>Há 12 meses</Dropdown.Item>
                            <Dropdown.Item data-date-start={getDate(2, 'year')}>Há 24 meses</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <h4 className="header-title mt-2 mb-4"><i className="mdi mdi-wallet"></i> Métodos de pagamentos</h4>
                    {loading ?
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    : 
                        data.length === 0 ? <span className="text-muted">Não há dados para exibir</span>
                    :
                    <ResponsiveContainer 
                        render={
                            ({width}) => 
                            <div>
                                <Donut
                                    data={data}
                                    height={width}
                                    width={width}
                                    internalRadius={80}
                                    isAnimated={false}
                                    highlightSliceById={1}
                                    hasFixedHighlightedSlice={false}
                                    shouldShowLoadingState={loading}
                                    customMouseOver={(data) => setHighlightedSliceId(data.data.id)}
                                    customMouseOut={() => setHighlightedSliceId(9999)}
                                />
                                <Legend
                                    data={data}
                                    width={width}
                                    highlightEntryById={highlightedSliceId}
                                    numberFormat={",.2r"} // ,.2r
                                    margin={{
                                        top: 10,
                                        bottom: 10,
                                        left: 0,
                                        right: 30,
                                    }}
                                />
                            </div>
                        }
                    />
                }
            </Card.Body>
        </Card>
    )
}