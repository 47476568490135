import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Button, ListGroup, Form, Badge, Dropdown } from 'react-bootstrap';

import api from 'services/api';
import { removeAccentuation as rmac } from 'helpers/functions';

import UserProfileModal from 'Dashboard/components/UserProfileModal'

import './style.css';

export default function Navbar({ logoutSession, user, setUser, synchronize }) {

    const [Companies, SetCompanies] = useState(null);
    const [gettingCompanies, setGettingCompanies] = useState(false);
    const [ControlCurrentCompanies, SetControlCurrentCompanies] = useState(null);

    const [settingNotify, setSettingNotify] = useState(false)

    const [userProfileModalVisibility, setuserProfileModalVisibility] = useState(0);

    async function getCompanies() {

        if (gettingCompanies) return;

        setGettingCompanies(true);
        try {
            const { data } = await api.get('/auth/session/instance/companies');
            if (data.length === 1) {
                data[0].Selected = true;
            }
            let to = data.filter(e => e.Selected === true).map(e => e.Id).join(',');
            SetControlCurrentCompanies(to);
            SetCompanies(data);
            setGettingCompanies(false);
        } catch (err) {
            SetCompanies([]);
            if (err.response) {
                if (err.response.status === 500) {
                    alert('Erro interno! Não foi possível obter a lista de empresas.');
                }
            }
        }
    }

    function resetSession(e) {
        e.preventDefault();
        api.put('/auth/session/reset')
        .then(() => {
            window.location.reload();
        }).catch(() => {});
    }
    async function saveSwitchCompanies(e) {
        e.preventDefault();
        let selectedCompanies = Companies.filter(e => e.Selected === true);
        let companies = selectedCompanies.map(e => e.Id).join(',');
        window.$('body').click();
        if (ControlCurrentCompanies === companies) {
            return;
        }

        try {
            await api.put('/auth/session/instance/companies', { companies: companies });
            SetControlCurrentCompanies(companies);
            synchronize();
        } catch (err) {}
    }

    async function getCountRequestsLinkUsers() {
        try {
            const { data } = await api.get('/management/users/requestsLinkInstances', { countOnly: 1 });
            if (data.requests) {
                setSettingNotify(true);
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (user.master) {
            getCountRequestsLinkUsers();
        }
    // eslint-disable-next-line
    }, []);

    return (
        <>
        <div className="navbar-custom shadow-none">
            <ul className="list-unstyled topbar-right-menu float-right mb-0">

                <li className="dropdown notification-list">
                    <a 
                        className="nav-link" 
                        href="reload" 
                        onClick={e => {
                            synchronize()
                            let iconSync = e.currentTarget.querySelector('.mdi-sync')
                            iconSync.classList.add('mdi-spin')
                            setTimeout(() => {
                                iconSync.classList.remove('mdi-spin');
                            }, 2000)
                            e.preventDefault()
                        }}
                        title=""
                    >
                        <i className="mdi mdi-sync text-dark mdi-24px noti-icon"></i>
                    </a>
                </li>

                <Dropdown 
                    as={'li'} 
                    className="notification-list companies"
                    onToggle={(isOpen) => {
                        if (isOpen) {
                            if (Companies === null) {
                                getCompanies();
                            }
                            setTimeout(() => {
                                window.$('.navbar-custom .topbar-right-menu .companies.dropdown .slimscroll').slimscroll();
                            }, 0);
                        }
                    }}
                >
                    <Dropdown.Toggle id="dropdown-basic" className="nav-link arrow-none" variant="link">
                        <i className="mdi mdi-domain text-dark noti-icon"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-animated dropdown-menu-right" style={{width:415}}>
                        <div className="noti-title">
                            <h5 className="mt-0 mb-3">
                                <span className="float-right">
                                {user.session.instances.length > 1 && (
                                    <a href="/" onClick={resetSession} className="text-dark">
                                        <small>Alterar Instância</small>
                                    </a>
                                )}
                                </span><i className="mdi mdi-domain mr-1"></i>Empresas
                            </h5>
                            <Form onSubmit={e => e.preventDefault()} className="d-flex justify-content-between align-items-center">
                                <div className="align-items-center d-inline-flex font-13 text-muted">
                                    <span>ID</span>
                                    <Form.Group controlId="frmCmxSrch" className="mb-0">
                                        <Form.Control
                                            type="text" 
                                            size="sm" 
                                            className="border-0 pl-1 ml-1" 
                                            placeholder="Pesquisar"
                                            autoComplete="off"
                                            disabled={Companies === null}
                                            onChange={e => {
                                                let t = e.currentTarget.value;
                                                let m = Companies.map(x => {
                                                    if (t === '') {
                                                        x.filtered = undefined;
                                                    } else {
                                                        x.filtered = rmac(x.Name.toLowerCase()).indexOf(rmac(t.toLowerCase())) !== -1;
                                                    }
                                                    return x;
                                                });
                                                SetCompanies([ ...m ]);
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        disabled={Companies === null}
                                        checked={
                                            Companies !== null 
                                            && (
                                            Companies.filter(
                                                x => (x.filtered === undefined || x.filtered) && x.Selected
                                            ).length
                                            ===
                                            Companies.filter(
                                                x => x.filtered === undefined || x.filtered
                                            ).length)
                                        } 
                                        onChange={e => {
                                            let ckd = e.currentTarget.checked;
                                            let cmp = Companies.map(x => {
                                                if (x.filtered === undefined || x.filtered) {
                                                    x.Selected = ckd;
                                                }
                                                return x;
                                            });
                                            SetCompanies([ ...cmp ])
                                        }}
                                        className="custom-control-input" 
                                        id="t44499fvqio04398ti" 
                                    />
                                    <label className="custom-control-label" htmlFor="t44499fvqio04398ti"></label>
                                </div>
                            </Form>
                        </div>

                        <div className="slimscroll">
                            <ListGroup>
                                {Companies === null 
                                ? 
                                    <ListGroup.Item className="d-flex justify-content-center border-0 fadeInUp animated">
                                        <div className="spinner-border" role="status"></div>
                                    </ListGroup.Item>
                                :
                                    Companies.filter(
                                        x => x.filtered === undefined || x.filtered
                                    ).map(($Company) => (
                                    <ListGroup.Item 
                                        className="py-1 d-flex justify-content-between border-0 fadeInUp faster animated" 
                                        key={$Company.Id}
                                        onClick={() => {
                                            SetCompanies([
                                                ...Companies.map(x => {
                                                    if (x.Id === $Company.Id) {
                                                        x.Selected = !x.Selected;
                                                    }
                                                    return x;
                                                })
                                            ])
                                        }}
                                    >
                                        <div className="pr-3 d-inline-flex">
                                            <div style={{width:25}} className="text-muted font-13">{$Company.Id}</div>
                                            <label htmlFor={'cpx-slc-'+btoa($Company.Id)}>{$Company.Name}</label>
                                            {$Company.Inactive === "S" && (
                                                <b className="text-danger font-10 ml-1">INATIVA</b>
                                            )}
                                        </div>
                                        {   // Render two components to fix: "Input elements should not switch from uncontrolled to controlled (or vice versa)."
                                            ["undefined","boolean"].indexOf(typeof $Company.Selected) !== -1 && (
                                                <Form.Check 
                                                    custom
                                                    type="checkbox"
                                                    checked={$Company.Selected || false}
                                                    readOnly
                                                    label={``}
                                                />
                                            )
                                        }
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>

                        <div className="p-2 d-flex align-items-center justify-content-between">
                            <span className="font-12 text-muted ml-1">
                                {(
                                    Companies !== null 
                                    &&
                                    Companies.filter(e => e.Selected === true).length !== 0 
                                    &&
                                    Companies.filter(e => e.Selected === true).map(e => e.Id).join(',') !== ControlCurrentCompanies
                                ) && (
                                    <Badge variant="danger-lighten mx-1">Não Aplicado</Badge>
                                )}
                                {Companies === null ? 
                                    'Carregando...'
                                : Companies.filter(e => e.Selected).length === 1 ?
                                    '1 empresa selecionada'
                                : Companies.filter(e => e.Selected).length > 1 ?
                                    `${Companies.filter(e => e.Selected).length} empresas selecionadas`
                                : 'Nenhuma empresa selecionada'
                                }
                            </span>
                            <Button 
                                variant="primary" 
                                size="sm" 
                                className="font-12 shadow-none"
                                onClick={saveSwitchCompanies}
                                disabled={Companies === null || Companies.filter(e => e.Selected).length === 0}
                            ><i className="mdi mdi-check mr-1"></i>Aplicar</Button>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                <li className="mx-2">
                    <a href="/" onClick={e => { e.preventDefault();setuserProfileModalVisibility(userProfileModalVisibility+1)}} className="nav-link">
                        {
                            user.picture === null 
                            ?
                            (
                                <div className="d-inline-block text-uppercase text-dark rounded-circle shadow-sm profile-picture-none">{user.name.substr(0,2)}</div>
                            ) : (
                                <img src={process.env.REACT_APP_API_STORAGE_BASEURL + '/users/images/pp/' + user.picture} width="32" height="32" style={{margin:'19px 0'}} className="shadow-sm rounded-circle" alt="" />
                            )
                        }
                    </a>
                </li>
                {user.master && (
                <li className="notification-list">
                    <Link to={'/management' + (settingNotify ? '/users':'')} onClick={() => setSettingNotify(false)} className="position-relative nav-link p-2 arrow-none my-2 mx-0 font-16"> {/* animated infinite heartBeat text-danger  */}
                        <i className="position-relative text-dark dripicons-gear" style={{top:-2}}></i>
                        {settingNotify === true && (
                        <span style={{top:10,right:5}} className="noti-icon-badge"></span>
                        )}
                    </Link>
                </li>
                )}
                <li>
                    <Link to="/" onClick={() => logoutSession()}  className="nav-link p-2 arrow-none my-2 font-16">
                        <i className="position-relative dripicons-power text-dark" style={{top:-2}}></i>
                    </Link>
                </li>
            </ul>
            <button className="button-menu-mobile open-left disable-btn">
                <i className="mdi mdi-menu"></i>
            </button>
        </div>

        <UserProfileModal {...{user, setUser, logoutSession}} triggerVisibility={userProfileModalVisibility} />

        </>
    )
}