import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Container, Navbar as BNavbar} from 'react-bootstrap'

import UserProfileModal from 'Dashboard/components/UserProfileModal'

import './style.css';

export default function Navbar({user, setUser, logoutSession, Navigation}) {

	const [userProfileModalVisibility, setuserProfileModalVisibility] = useState(0);

	useEffect(() => {

		// hide vertical menu
		window.$('#settings .topnav .topnav-menu .nav-item .nav-link').on('click', function () {
			window.$('div.navbar-custom.topnav-navbar a.navbar-toggle.open').trigger('click');
		});

	}, [])

	return (
		<>
			<div className="navbar-custom topnav-navbar">
				<Container fluid>
					<Link to="/" className="topnav-logo">
                        <span className="topnav-logo-lg">
                            <h1>Orul</h1>
													{/* <img src={process.env.PUBLIC_URL + '/assets/images/logo-light.png'} alt="" height="16" /> */}
                        </span>
						<span className="topnav-logo-sm">
                            <h1>O</h1>
							{/* <img src={process.env.PUBLIC_URL + '/assets/images/logo_sm.png'} alt="" height="16" /> */}
                        </span>
					</Link>

					<ul className="list-unstyled topbar-right-menu float-right mb-0">

						<li className="mr-2">
							<a href="/" onClick={e => {
								e.preventDefault();
								setuserProfileModalVisibility(userProfileModalVisibility + 1)
							}} className="nav-link">
								{
									user.picture === null
										?
										(
											<div
												className="d-inline-block text-uppercase text-dark rounded-circle shadow-sm profile-picture-none">{user.name.substr(0, 2)}</div>
										) : (
											<img src={process.env.REACT_APP_API_STORAGE_BASEURL + '/users/images/pp/' + user.picture} width="32"
													 height="32" style={{margin: '19px 0'}} className="shadow-sm rounded-circle" alt=""/>
										)
								}
							</a>
						</li>
						<li>
							<Link to="/" onClick={() => logoutSession()} className="nav-link p-2 arrow-none my-2 font-16">
								<i className="position-relative dripicons-power text-dark" style={{top: -2}}></i>
							</Link>
						</li>

					</ul>
					<a href="#/" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#topnav-menu-content"
						 aria-expanded="false">
						<div className="lines">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</a>
				</Container>
			</div>
			<div className="topnav">
				<Container fluid>
					<BNavbar variant="dark" expand="lg" className="topnav-menu">
						<div className="navbar-collapse active collapse justify-content-center" id="topnav-menu-content">
							<ul className="navbar-nav">

								{Navigation.map((item, idx) => (
									<li key={idx} className="nav-item dropdown active">
										<Link to={item.link} className="nav-link dropdown-toggle arrow-none"
													id="topnav-dashboards" {...(item.items.length > 0 ? {
											role: 'button',
											'data-toggle': 'dropdown'
										} : {})} aria-haspopup="true" aria-expanded="false">
											<i className={item.icon + ' mr-1'}></i> {item.text}
										</Link>
										{item.items.length > 0 && (
											<div className="dropdown-menu" aria-labelledby="topnav-dashboards">
												{item.items.map((litem, lidx) => (
													<Link key={lidx} className="dropdown-item" to={litem.link}>{litem.text}</Link>
												))}
											</div>
										)}
									</li>
								))}
							</ul>
						</div>
					</BNavbar>
				</Container>
			</div>

			<UserProfileModal user={user} setUser={setUser} logoutSession={logoutSession}
												triggerVisibility={userProfileModalVisibility}/>
		</>
	);
}