import React from 'react'

import MainModule       from './Main/Main'
import SettingsModule   from './Settings/Settings'

export default function Dashboard(w) {
    
    const Module = w.user.session.modules.active;

    return (
        Module === 'Main'
        ?
            <MainModule {...w} />
        :
        Module === 'Settings'
        ?
            <SettingsModule {...w} />
        :
            <>Module unprocessable</>
    );
}