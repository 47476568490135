import React, { useState, useEffect } from 'react';

import ReactApexChart from "react-apexcharts";

import { Row, Col, Card } from 'react-bootstrap';

import { getLast12Months, realCurrency, decimalAdjust } from "helpers/functions";
import ls from "helpers/ls";
import api from "services/api";

export default function ChartSalesLast12Months(props) {

    const { sync, totalSalesOfMonth } = props;
    const [series, setSeries] = useState([{data:[]},{data:[]}]);
    const [loading, setLoading] = useState(false);

    const lsKeySalesLast12Months = 'reports@initials:salesLast12Months';

    const options = {
        chart: {
            animations: {
                enabled: false
            },
            toolbar: {
                tools: {
                    download: '<i className="mdi mdi-18px mdi-download"></i>'
                }
            }
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: 'light',
                opacityFrom: .9,
                opacityTo: .5,
                stops: [0, 100]
            }
        },
        colors: ['#00E396', '#727cf5'],
        dataLabels: {enabled: false},
        stroke: {curve: 'smooth', width: 3},
        markers: {
            size: 0,
            style: "hollow"
        },
        yaxis: {
            show: false
        },
        legend: {
            height: window.innerWidth >= 768 ? 40 : 20,
            offsetY: window.innerWidth >= 768 ? -20 : 0,
            itemMargin: {
                vertical: 10
            },
        },
        xaxis: {type: 'category',categories: getLast12Months().arrayOfString},
        tooltip: {
            y: [
                {
                    formatter: function (y, opt) {
                        if(typeof y !== "undefined" && y !== null) {
                            if (typeof opt.series[1] !== "undefined") {
                                let ly = opt.series[1][opt.dataPointIndex];
                                if (typeof ly === "number" && ly > 0) {
                                    let calc = decimalAdjust('round', (((y-ly)/ly)*100), -1);                                    
                                    ly = calc === 0 ? '' : ` (${(calc > 0) ? '+' : ''}${calc}%)`
                                } else {
                                    ly = '';
                                }
                                return realCurrency(y) + ly;
                            } else {
                                return realCurrency(y);
                            }
                        }
                        return y;
                        
                    }
                },
                {formatter: y => realCurrency(y)}
            ]
        }
    }

    // eslint-disable-next-line
    function getReportsSalesLast12Months() {
        setLoading(true);
        const salesChartLast12Months = api.get('/reports/sales/salesLast12Months');
        salesChartLast12Months.then( response => {
            ls.set(lsKeySalesLast12Months, response.data);
            comReportsSalesLast12Months(response.data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }
    function comReportsSalesLast12Months(data) {        

        let last12MonthsNumber = getLast12Months().arrayOfNumber;
        
        let series = [];
        series.push({ name: 'Últimos 12 meses', type: 'area', data: [] });
        series.push({ name: 'Ano anterior', type: 'area', data: [] });
        
        if (data.last12Months.length > 0) {
            // let l = series.length-1;
            data.last12Months.map(item => {
                series[0].data[last12MonthsNumber.indexOf(parseInt(item.Month)-1)] = item.Total;
                return item;
            });
        }

        if (data.lastYear.length > 0) {
            // let l = series.length-1;
            data.lastYear.map(item => {
                series[1].data[last12MonthsNumber.indexOf(parseInt(item.Month)-1)] = (item.Total);
                return item;
            });
        }
        setSeries(series);
    }

    useEffect(() => {
        if (ls.has(lsKeySalesLast12Months)) {
            try {
                let data = JSON.parse(ls.get(lsKeySalesLast12Months));
                comReportsSalesLast12Months(data);
            } catch (error) {
                ls.remove(lsKeySalesLast12Months);
                getReportsSalesLast12Months();
            }

        } else {
            getReportsSalesLast12Months();
        }
    // eslint-disable-next-line
    }, []);

    useEffect(()=>{
        if ( sync > 0 ) {
            setSeries([{data:[]},{data:[]}]);
            getReportsSalesLast12Months();
        }
    }, [sync, getReportsSalesLast12Months])

    return (
        <Card>
            <Card.Body>
                <Row className="text-center">
                    <Col>
                        <p className="text-muted mb-0 mt-3">Mês Atual</p>
                        <h2 className="font-weight-normal mb-3">
                            <span>{totalSalesOfMonth}</span>
                        </h2>
                    </Col>
                    {/* <Col md="6">
                        <p className="text-muted mb-0 mt-3">Previsão</p>
                        <h2 className="font-weight-normal mb-3">
                            <span>...</span>
                        </h2>
                    </Col> */}
                    
                </Row>
                <ReactApexChart options={options} style={{opacity: loading ? 0.5 : 1 }} series={series} type="area" height="350" />
            </Card.Body>
            { loading && <div className="card-disabled"><div className="card-portlets-loader"></div></div> }
        </Card>
    )
}