import React from 'react'

import ModalTargetsRegister from './ModalTargetsRegister'

import { Row, Col, Card, Button } from 'react-bootstrap'

import Datetime from 'react-datetime'

import undrawTarget from 'assets/img/undraw_target_kriv.svg'

export default function TargetsUndefinedYear({ company, year, zmonth, isUnavailable, setYear, forceReloadSalesTarget }) {

  return (
    <>
      <Card className="shadow-none" text="dark" body>
        <Row>
          <Col>
            <h3>SEM METAS PARA {year}</h3>

            {!isUnavailable ? (
              <div className="mt-3">
                <ModalTargetsRegister
                  year={year}
                  setYear={setYear}
                  month={zmonth}
                  company={company}
                  forceReloadSalesTarget={forceReloadSalesTarget}
                />
                <div className="mt-4">
                  <Datetime 
								    viewMode="years"
                    closeOnSelect={true}
                    timeFormat={false}
								    dateFormat="YYYY"
                    onChange={(mm) => setYear(mm.year())}
                    renderInput={(props, openCalendar) => {
                      return (
                        <Button 
                          size="sm"
                          variant="link" 
                          className="font-12 p-0 font-weight-bold"
                          onClick={openCalendar}
                        >
                          <i className="mdi mdi-calendar-text mr-1"></i>
                          SELECIONAR OUTRO ANO
                        </Button>
                      )
                    }} 
                  />
                </div>
              </div>
            ) : (
              <i>Definição de meta indisponível.</i>
            )}
          </Col>
          <Col>
            <img src={undrawTarget} className="float-right" alt="" height="250" />
          </Col>
        </Row>
      </Card>
    </>
  );
}