import React, { useState, useEffect } from 'react'

import { Row, Col, Nav } from 'react-bootstrap'
import { Link, Switch, Route } from 'react-router-dom'

import api from 'services/api'

import Initial      from './Initial'
import Users        from './Users'
import Accesses     from './Accesses'

import './style.css';

export default function Management({ route }) {

    const { match } = route;

    const navItems = [
        { name: 'initial',   to: '',            text: 'Início',    icon: 'mdi mdi-layers-outline', },
        { name: 'users',     to: '/users',      text: 'Usuários',  icon: 'mdi mdi-account-multiple-outline' },
        { name: 'accesses',  to: '/accesses',   text: 'Acessos',   icon: 'mdi mdi-format-list-checks' }
    ];

    const [navActivedKey, setNavActivedKey] = useState('')
    
    let historyListen;

    useEffect(() => {

        function listen(location) {
            
            let navActived = navItems.reverse().map(x => {
                let y = (match.url + x.to)
                let z = location.pathname;
                x.match = y === z ? true : z.indexOf(y) !== -1 ? 1 : null;
                return x;
            }).filter(x => x.match === true || x.match === 1)[0];

            navItems.reverse();
            
            setNavActivedKey(typeof navActived === "object" ? navActived.name : '');
        }
        // eslint-disable-next-line
        historyListen = route.history.listen(listen);

        listen(window.location);

        return () => {
            api.cancelAllRequests();
            historyListen();
        };
    }, [historyListen, setNavActivedKey])

    return (
        <div id="management" className="pt-3">
            <Row>
                <Col>
                    <Nav activeKey={navActivedKey}>
                        {navItems.map((item, idx) => (
                        <Nav.Item key={idx} className="mr-0 mr-md-2 mb-3 mb-md-0">
                            <Nav.Link eventKey={item.name} to={match.url + item.to} as={Link}><i className={item.icon + ' mr-1'}></i>{item.text}</Nav.Link>
                        </Nav.Item>
                        ))}
                    </Nav>
                </Col>
            </Row>
            <Switch>
                <Route exact path={match.url + '/'} component={Initial} />
                <Route path={match.url + '/users'} component={Users} />
                <Route exact path={match.url + '/accesses'} component={Accesses} />
            </Switch>
        </div>
    )
}