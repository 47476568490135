import React, { useState, useRef } from 'react'

import { Row, Col, Card, Button } from 'react-bootstrap'

import api from 'services/api'

export default function StepLogo({ setStep, data }) {

    const fileInput    = useRef(null);
    const imageEl = useRef(null);

    const [imageUpdated, setimageUpdated] = useState(false);
    const [errResponse, seterrResponse] = useState(null);
    const [bgTestGradient, setBgTestGradient] = useState(false);
    
    async function setImage(e) {
        seterrResponse(null);
        let image = e.currentTarget.files[0];
        const frm = new FormData();

        frm.append('instance', data.instance.id);
        frm.append('image', image, image.filename);

        if (image) {
            try {
                const response = await api.instance().post('/settings/instances/image', frm, { headers: { 'Content-Type': 'multipart/form-data' } });
                imageEl.current.src = `${process.env.REACT_APP_API_STORAGE_BASEURL}/instances/brands/${response.data.filename}`;
                setimageUpdated(true);
            } catch (err) {
                if (err.response) {
                    seterrResponse(err.response.data);
                }
            }
        }
    }

    function proceed() {
        if (errResponse !== null) return;
        setStep({
            current: 'User',
            data: data
        });
    }

    return (
        <Row className="justify-content-center">
            <Col md="7">
                <Card className="shadow-none fadeInUp animated">
                    <Card.Body>
                        <div className="text-center">
                            <h3 className="mb-3">{data.instance.name}</h3>
                            <div className={bgTestGradient ? 'bg-gradient-animation py-2':''}>
                                <img style={{cursor:'pointer'}} src={process.env.PUBLIC_URL + '/assets/images/database.png'} width="150" ref={imageEl} onClick={() => fileInput.current.click()} alt="" />
                            </div>
                            <input type="file" ref={fileInput} onChange={setImage} accept="image/*" className="d-none" />
                            {errResponse !== null && (
                                <p className="text-danger my-4">{errResponse.message}</p>
                            )}
                            {imageUpdated && (
                                <div>
                                    <Button type="button" variant="link" className="mt-3" onClick={() => setBgTestGradient(!bgTestGradient)}>Background Gradient Tester</Button>
                                </div>
                            )}
                            <Button variant="success" className="shadow-none mt-3" onClick={proceed}>{imageUpdated ? 'Salvar & Continuar':' Pular Etapa'}</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}