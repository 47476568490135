import React, { useState, useEffect } from "react";

import api from "services/api";
import { Row, Col, Container, Card, Button, Modal, Collapse, InputGroup, FormControl } from "react-bootstrap";
import { realCurrency } from "helpers/functions";

import Accounts from './accounts';
import Flows from './flows';

import './style.css';

export default function FinancialCashFlow({ sync }) {

    const [accounts, setDataAccounts]                                       = useState(null);
    const [dataAccountsBalancesPrevious, setDataAccountsBalancesPrevious]   = useState(null);
    const [dataAccountsBalancesCurrent, setDataAccountsBalancesCurrent]     = useState(null);
    const [dataCashFlowsCredits, setDataCashFlowsCredits]                   = useState(null);
    const [dataCashFlowsDebts, setDataCashFlowsDebts]                       = useState(null);

    const [ActivePageFlow, setActivePageFlow]                               = useState(false);
    const [MobileOptionsActive, setMobileOptionsActive]                     = useState(false);
    const [ModalVisibility, setModalVisibility]                             = useState(false);
    const [dataModal, setDataModal]                                         = useState(null);

    const [filtersPageFlow, setFiltersPageFlow] = useState({
                                                            startDate: window.moment().startOf('month').format('DD/MM/YYYY'),
                                                            endDate: window.moment().endOf('month').format('DD/MM/YYYY')
                                                        });

    
    function getIdAccountsAltSelecteds() {
        let selecteds = accounts.map(item => item.data.filter(x => x.selected)).filter(x => x.length > 0);
        let ids = [];
        for (let v = 0; v < selecteds.length; v++) {
            for (let c = 0; c < selecteds[v].length; c++) {
                ids.push(selecteds[v][c].Id);
            }            
        }
        return ids;
    }
    async function getReportCashFlow(type, date){
        let params = {flowType : type, filters: { date: {} }};
        date = typeof date === "undefined" ? { 
            start: filtersPageFlow.startDate.split('/').reverse().join('-'),
            end: filtersPageFlow.endDate.split('/').reverse().join('-') 
        } : date;
        params.filters.date = date;
        let accounts = getIdAccountsAltSelecteds();
        if (accounts.length > 0) {
            params.filters.accounts = accounts;
        }

        try {
            const { data: { data } } = await api.get('/reports/financial/cashflow/flows', params);
    
            let AccountSyntheticIds = [];
            let AccountSyntheticNames = [];
            let Flows = [];
    
            for (let i = 0; i < data.length; i++) {
                if (AccountSyntheticIds.indexOf(data[i].AccountSyntheticId) === -1) {
                    AccountSyntheticIds.push(data[i].AccountSyntheticId);
                    AccountSyntheticNames.push(data[i].AccountSyntheticName);
                }
            }
            for (let x = 0; x < AccountSyntheticIds.length; x++) {
                Flows.push({
                    name: AccountSyntheticNames[x],
                    id: AccountSyntheticIds[x],
                    data: data.filter(t => t.AccountSyntheticId === AccountSyntheticIds[x])
                });
                Flows[Flows.length-1].Total = Flows[Flows.length-1].data.reduce((i, c) => parseFloat(c.Value) + i, 0);
            }
            
            if (type === 'C') {
                setDataCashFlowsCredits(Flows);
                
            } else if (type === 'D') {
                setDataCashFlowsDebts(Flows);
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    function getAccounts() {
        api.get('/reports/financial/cashflow/accounts')
        .then( response => {
            let bl = response.data.accounts;
            let banks = [];
            let data = [];
            
            for (let x = 0; x < bl.length; x++) {
                let idx = banks.indexOf(bl[x].BankCode);
                if (idx === -1) {
                    banks.push(bl[x].BankCode);
                    let bankIconIndex = window.ibb.findIndex(i => i.bankCode === bl[x].BankCode);
                    data.push({bankCode: bl[x].BankCode, bankIcon: (bankIconIndex !== -1) ? window.ibb[bankIconIndex] : false,  bankName: bl[x].BankName, data: [bl[x]] });
                } else {
                    data[idx].data.push(bl[x]);
                }
            }
            setDataAccounts(data);
        })
        .catch(err => {
            if (err.response) {
                console.error('ReportErrorMessage: ' + err.response.data.message);
            }
            setDataAccounts([]);
        });
    }
    function getReportCashFlowPreviousBalances(date) {
        let end = window.moment(date).set('date', 1).subtract(1, 'day').format('YYYY-MM-DD');
        let params = { filters: { date: { end : end } }};
        let accounts = getIdAccountsAltSelecteds();
        if (accounts.length > 0) {
            params.filters.accounts = accounts;
        }
        api.get('/reports/financial/cashflow/balances', params)
        .then( response => {
            setDataAccountsBalancesPrevious(response.data);
        })
        .catch(() => {});
    }
    async function getReportCashFlowBalancesCurrent(end) {
        let params = { filters: { date: { end : end } }};
        let accounts = getIdAccountsAltSelecteds();
        if (accounts.length > 0) {
            params.filters.accounts = accounts;
        }

        try {
            const { data } = await api.get('/reports/financial/cashflow/balances', params);
            setDataAccountsBalancesCurrent(data);
        } catch (error) {
            console.error(error); 
        }
    }

    function applyFilterDatesPageFlow(ev, picker){
        api.cancelAllRequests();
        let applied = {
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY')
        }
        let toFilter = {
            start: picker.startDate.format('YYYY-MM-DD'),
            end: picker.endDate.format('YYYY-MM-DD')
        }
        setFiltersPageFlow({...applied});
        
        setDataCashFlowsCredits(null);
        setDataCashFlowsDebts(null);
        setDataAccountsBalancesPrevious(null);
        setDataAccountsBalancesCurrent(null);

        getReportCashFlowPreviousBalances(toFilter.start);
        getReportCashFlowBalancesCurrent(toFilter.end);
        getReportCashFlow('C', toFilter);
        getReportCashFlow('D', toFilter);
    }

    async function getReportAccountAnalytics(account, idx) {
        let params = {
            flowType: account.flowType,
            analyticalAccountId: account.AnalyticalAccountId === null ? 'NULL' : account.AnalyticalAccountId,
            accountSyntheticId: account.AccountSyntheticId === null ? 'NULL' : account.AccountSyntheticId,
            accountPlanId: account.AccountPlanId === null ? 'NULL' : account.AccountPlanId,
            filters: {
                date: {
                    start: filtersPageFlow.startDate.split('/').reverse().join('-'),
                    end: filtersPageFlow.endDate.split('/').reverse().join('-')
                }
            }
        };
        let accounts = getIdAccountsAltSelecteds();
        if (accounts.length > 0) {
            params.filters.accounts = accounts;
        }
        try {
            const { data } = await api.get('/reports/financial/cashflow/accountsAnalytical', params );
            let dataModal_tmp = dataModal;
            dataModal_tmp[idx].dataAnalytic = data;
            setDataModal([...dataModal_tmp]);
        } catch (error) {
            console.log(error);
        }
    }

    async function getReportAccountPlan(flowType, AccountSyntheticId, AccountPlanId) {
        setDataModal(null);
        setModalVisibility(true);
        let params = {
            flowType: flowType,
            accountSyntheticId: AccountSyntheticId === null ? 'NULL' : AccountSyntheticId,
            accountPlanId: AccountPlanId === null ? 'NULL' : AccountPlanId,
            filters: {
                date: {
                    start: filtersPageFlow.startDate.split('/').reverse().join('-'),
                    end: filtersPageFlow.endDate.split('/').reverse().join('-')
                }
            }
        };
        let accounts = getIdAccountsAltSelecteds();
        if (accounts.length > 0) {
            params.filters.accounts = accounts;
        }
        try {
            const response = await api.get('/reports/financial/cashflow/accountsPlans', params );
            let data = response.data.map((item, idx) => {
                return {
                    ...item,
                    flowType: flowType,
                    pendingDataAnalytic: false,
                    dataAnalytic: null,
                    collapsed: false
                }
            });
            setDataModal(data);
        } catch (error) {
            console.log(error);
        }
    }

    function toggleSelectionAccounts(b) {
        let x = accounts.map(item => ({...item, data: item.data.map(sitem => ({...sitem, selected: b}))}));
        setDataAccounts([...x]);
    }

    function filterDataModalAnalytic(e) {
        let $search = window.$(e.target);
        let searchval = $search.val();
        let $listAnalytic = $search.parents('.list-group.list-analytic');
        let $listInfo = $search.parent().find('.info-result');

        $listAnalytic.find('.list-group-item').removeClass('d-none match');
        $listAnalytic.find('.list-group-item div.cc.match').removeClass('match');
        if (searchval !== '') {
            let $cc = $listAnalytic.find('div.cc:contains("'+searchval.toUpperCase()+'")');
            if ($cc.length > 0) {
                for (let i = 0; i < $cc.length; i++) {
                    window.$($cc[i]).parents('.list-group-item').addClass('match');
                }
                $listAnalytic.find('.list-group-item:not(.match)').addClass('d-none');
                let $listmatch = $listAnalytic.find('.list-group-item.match.analytic');
                let $totalizer = $listmatch.find('.cc.analytic-value');
                let totalizer_calc = 0;
                for (let w = 0; w < $totalizer.length; w++) {
                    let value = parseFloat($totalizer[w].innerHTML.replace('R$&nbsp;','').replace('.','').replace(',','.'));
                    totalizer_calc += value;
                }
                $listInfo.html(`${$listmatch.length} registro${$listmatch.length > 1 ? 's':''}, ${realCurrency(totalizer_calc)}`).removeClass('text-danger').addClass('text-success');
                $cc.addClass('match');
            } else {
                $listAnalytic.find('.list-group-item').addClass('d-none');
                $listInfo.html('Nenhum registro encontrado').removeClass('text-success').addClass('text-danger');
            }
        } else {
            $listInfo.html('');
        }
        
    }

    useEffect(() => {
        if (ActivePageFlow) {
            setDataCashFlowsCredits(null);
            setDataCashFlowsDebts(null);
            setDataAccountsBalancesPrevious(null);
            setDataAccountsBalancesCurrent(null);

            getReportCashFlowPreviousBalances();
            getReportCashFlowBalancesCurrent(filtersPageFlow.endDate.split('/').reverse().join('-'))
            getReportCashFlow('C');
            getReportCashFlow('D');
        }
    // eslint-disable-next-line
    }, [ActivePageFlow, sync]);

    useEffect(() => {
        if (!ActivePageFlow && sync > 0) {
            setDataAccounts(null);
            getAccounts();
        }
    // eslint-disable-next-line
    }, [sync]);

    useEffect(() => {
        getAccounts();
        // window.$('.modal-account-plan-analytical').on('change', 'input.form-control.search-analytic', function(e) {
        //     e.preventDefault();
        //     console.log(e);
            
        // });
        return () => api.cancelAllRequests();
    // eslint-disable-next-line
    }, [])

    return (
        <>

        <Row>
            <Col>
                <div className="page-title-box">
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">Financeiro</li>
                            <li className="breadcrumb-item active">Fluxo de Caixa</li>
                        </ol>
                    </div>
                    <h4 className="page-title">Fluxo de Caixa</h4>
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card className="cbalances">
                    <Card.Body>
                        
                        {
                            !ActivePageFlow
                        ?
                            <Accounts 
                                setActivePageFlow={setActivePageFlow}
                                activePageFlow={ActivePageFlow}
                                accounts={accounts}
                                setDataAccounts={setDataAccounts}
                                toggleSelectionAccounts={toggleSelectionAccounts}
                            />
                        :
                            <Flows
                                setActivePageFlow={setActivePageFlow}
                                activePageFlow={ActivePageFlow}
                                filtersPageFlow={filtersPageFlow}
                                applyFilterDatesPageFlow={applyFilterDatesPageFlow}
                                dataAccountsBalancesPrevious={dataAccountsBalancesPrevious}
                                dataAccountsBalancesCurrent={dataAccountsBalancesCurrent}
                                dataCashFlowsCredits={dataCashFlowsCredits}
                                dataCashFlowsDebts={dataCashFlowsDebts}
                                getReportAccountPlan={getReportAccountPlan}
                            />
                        }
                        
                        <div className="mobile-actions d-block d-sm-none">
                            <div className={`options${MobileOptionsActive ? ' fadeInRight animated' : ' d-none'}`}>
                                <Button
                                    onClick={() => {
                                        setMobileOptionsActive(!MobileOptionsActive);
                                        setActivePageFlow(!ActivePageFlow)
                                    }}>{ActivePageFlow ? 'Voltar' : 'Fluxo de Caixa'}</Button>
                                {!ActivePageFlow && (
                                    <>
                                        <Button onClick={() => toggleSelectionAccounts(true)}>Marcar todos</Button>
                                        <Button onClick={() => toggleSelectionAccounts(false)}>Desmarcar todos</Button>
                                    </>
                                )}
                            </div>
                            <Button variant="dark" size="lg" className="btn-rounded" onClick={() => setMobileOptionsActive(!MobileOptionsActive)}>
                                <i className={`mdi ${MobileOptionsActive ? 'mdi-window-close' : 'mdi-menu'}`}></i>
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Modal show={ModalVisibility} onHide={() => setModalVisibility(false)} className="modal-account-plan-analytical" size="xl">
          <Modal.Header>
            <Modal.Title>{
                dataModal === null ? 'Carregando...' : dataModal[0].AccountPlan === 'NotInformed' ? 'NÃO INFORMADO' : dataModal[0].AccountPlan
            }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Container fluid={true}>{
                dataModal !== null ?
                <>
                {dataModal.map((item, idx) => (
                    <Row key={idx}>
                        <Col className="sticky" xs="12" onClick={() => {
                            // Collapse
                            let data = dataModal;
                            data[idx].collapsed = !data[idx].collapsed;
                            setDataModal([ ...data ]);
                        }}>
                            <Row className="list-group-item-action rounded m-0" style={{cursor:'pointer'}}>
                                <Col md="6"><h5>{item.AnalyticalAccountName || 'NÃO INFORMADO'}</h5></Col>
                                <Col md="6" className="text-right"><h5>{realCurrency(parseFloat(item.Value))}</h5></Col>
                            </Row>
                        </Col>
                        <Col xs="12">
                            <Collapse 
                            onEnter={() => {
                                if (!item.pendingDataAnalytic) {
                                    let data = dataModal;
                                    data[idx].pendingDataAnalytic = true;
                                    setDataModal([ ...data ]);
                                    getReportAccountAnalytics(item, idx);
                                }
                                // console.log('onEnter');
                            }}
                            in={item.collapsed}>
                            <div>{
                                    item.dataAnalytic !== null
                                    ?
                                    <ul className="list-group list-analytic my-3 mx-2">
                                        <div className="sticky" style={{ top: 36, zIndex: 2 }}>
                                            <InputGroup>
                                                <FormControl
                                                    spellCheck={false}
                                                    className="text-center search-analytic text-uppercase"
                                                    onChange={filterDataModalAnalytic}
                                                    placeholder="Pesquisar"
                                                    aria-label="search"
                                                />
                                                <div className="info-result position-absolute" style={{right:10, zIndex: 3, fontSize: 11, marginTop: 11}}></div>
                                            </InputGroup>
                                        </div>
                                        {item.dataAnalytic.map((Analytic, x1) => (
                                            <li key={x1} className="list-group-item border-0">
                                                <h4 style={{ top: 64, zIndex:1 }} className="sticky text-center py-3">{Analytic.CompanyName}</h4>
                                                <div className="mx-2 mb-3">
                                                    <ul className="list-group">
                                                        {Analytic.data.map((AnalyticItem, x2) => (
                                                            <li key={x2} className="list-group-item analytic">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div>
                                                                            <strong>Documento</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.DocumentID}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>{AnalyticItem.ProviderType}</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.ProviderName}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Conta</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.AccountName}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Centro de Custo</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.CostCenter}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Tipo de Documento</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.DocumentType}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Forma de Pagamento</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.PaymentMethod}</div>  
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div>
                                                                            <strong>Valor</strong><div className="float-right cc text-truncate w-50 text-right analytic-value">{realCurrency(AnalyticItem.Value)}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Usuário</strong><div className="float-right cc text-truncate w-50 text-right" title={AnalyticItem.UserFullName}>{AnalyticItem.UserName}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Emissão</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.DateEmission}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Vencimento</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.DateExpiration}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Pagamento</strong><div className="float-right cc text-truncate w-50 text-right">{AnalyticItem.DatePayment}</div>  
                                                                        </div>
                                                                        <div>
                                                                            <strong>Histórico</strong><div className="float-right cc w-75 text-right">{AnalyticItem.Historic}</div>  
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                        <div className="d-flex justify-content-center mt-3 mb-3">
                                            <div className="spinner-border" role="status"></div>
                                        </div>
                            }</div>
                            </Collapse>
                        </Col>
                    </Row>
                ))}
                </>
                :
                <div className="d-flex justify-content-center mt-3 mb-3">
                    <div className="spinner-border" role="status"></div>
                </div>
            }</Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={() => setModalVisibility(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

        </>
    )
}