import React, { useRef, useState, useEffect } from 'react'

import { UserAgentProvider, UserAgent } from '@quentin-sommer/react-useragent'

import { Modal, Tabs, Tab, Button, Row, Col, Form, Card, Badge } from 'react-bootstrap'

import api from 'services/api'

import './style.css';

export default function UserProfileModal({ user, setUser, logoutSession, triggerVisibility }) {

    
    // Visibiity of Modal User Profile
    const [visibility, setvisibility] = useState(false);
    
    // Controller Active Tab
    // let currentActiveTab = null;
    
    // Reference Control Input type File of User Picture 
    const inputProfilePicture = useRef(null);


    const [rpupf, setrpupf] = useState(false);
    const [rsupf, setrsupf] = useState(null);

    const [rpuac, setrpuac] = useState(false);
    const [rsuac, setrsuac] = useState(null);

    const [devicesConnected, setDevicesConnected] = useState([]);

    /**
     * Get user informations
     */
    // async function getUserInformations() {
    //     const informations = await api.get('/users/informations');
    //     console.log(informations.data);
    // }

    /**
     * Get devices user sessions connected
     */
    async function getDevicesConnected() {
        try {
            const { data } = await api.get('/auth/session/devicesConnected');
            setDevicesConnected(data);
        } catch (err) {
            if (err.response) {
                console.error(err.response.data);
            }
        }
    } 

    async function delSpecificSession(token) {
        setDevicesConnected([
            ...devicesConnected.filter(item => item.Token !== token)
        ]);
        try {
            await api.delete('/auth/session/' + token);
        } catch (err) {
            if (err.response) {
                console.error(err.response.data);
            }
        }
    }

    /**
     * Get data object by serialized array from jquery
     */
    function getDataForm(formElement) {
        let data = {};
        window.$(formElement).serializeArray().forEach(element => {
            data[element.name] = element.value;
        });
        return data;
    }

    /**
     * Profile Update Password
     * 
     * @param e Event Listener
     * @return void
     * 
     * Hooks
     * @const rpupf [bool] Request Pending
     * @const rsupf [mix] Response (null default)
     */ 
    async function onSubmitUpdatePasswordForm(e) {
        e.preventDefault();

        setrsupf(null);

        let $form = e.currentTarget;

        let data = getDataForm($form);

        if (Object.values(data).map(e => e.trim()).indexOf('') !== -1) {
            setrsupf({ type: 'warning', text: 'Tá faltando algo...' });
            return;
        }

        if (data.newPasswordConfirm !== data.newPassword) {
            setrsupf({ type: 'danger', text: 'Senhas não correspondem.' });
            return;
        }

        if (data.newPassword.length < 4) {
            setrsupf({ type: 'danger', text: 'Senha muito curta.' });
            return;
        }

        delete data.newPasswordConfirm;

        setrpupf(true);
        try {
            await api.post('/users/changes/password', data);
            window.$($form).trigger('reset');
            setrsupf({ type: 'success', text: 'Senha Alterada :)' });
        } catch (err) {
            if (err.response) {
                const { status } = err.response.data;
                const sttmsg = {
                    CurrentPasswordInvalid: 'Senha Atual Incorreta!',
                    PasswordsNotMatch: 'Senhas não correspondem!'
                }
                setrsupf({ type: 'danger', text: sttmsg[status] });
            }
        }
        setrpupf(false);
    }

    /**
     * Profile update access
     * 
     * @param e Event Listener
     * @return void
     * 
     * Hooks
     * @const rpuac [bool] Request Pending
     * @const rsuac [mix] Response (null default)
     */ 
    async function onSubmitUpdateAccess(e) {
        e.preventDefault();

        setrsuac(null);

        let data = getDataForm(e.currentTarget);

        if (Object.values(data).filter(item => item === "").length === 3) {
            return;
        } 
        // eslint-disable-next-line
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key] === '') data[key] = null;
            }
        }

        if (!data.email || !data.phone || !data.user) {
            setrsuac({ type: 'warning', text: 'Preenha todos os campos!' });
            return;
        }

        let letsChange = {};

        if (data.email !== user.email) {
            letsChange.email = data.email;
            if (data.email !== null && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data.email)) {
                setrsuac({ type: 'warning', text: 'E-mail inválido!' });
                return;
            }
        }
        if (data.phone !== user.phone) {
            letsChange.phone = data.phone;
            if (data.phone !== null && !/^[0-9]+$/.test(data.phone)) {
                setrsuac({ type: 'warning', text: 'Telefone inválido!' });
                return;
            }
        }
        if (data.user !== user.user) {
            if (data.user.length < 3) {
                setrsuac({ type: 'warning', text: 'Nome de usuário muito curto!' });
                return;
            } else if (!isNaN(data.user)) {
                setrsuac({ type: 'warning', text: 'Nome de usuário não pode conter somente números!' });
                return;
            } else if (!isNaN(data.user[0])) {
                setrsuac({ type: 'warning', text: 'Nome de usuário não pode iniciar com número!' });
                return;
            } else if (data.user.length > 25) {
                setrsuac({ type: 'warning', text: 'Nome de usuário muito longo!' });
                return;
            }
            letsChange.user = data.user;
        }

        if (Object.keys(letsChange).length === 0) {
            return;
        }

        setrpuac(true);
        try {
            await api.post('/users/changes/access', letsChange);
            let usr = user;
            // eslint-disable-next-line
            for (const key in letsChange) {
                if (letsChange.hasOwnProperty(key)) {
                    usr[key] = letsChange[key]
                }
            }
            setUser({ ...usr });
            setrsuac({ type: 'success', text: 'Acessos alterados :)' });
        } catch (err) {
            if (err.response) {
                let rs = err.response.data;
                if (rs.email && rs.email.indexOf('AlreadyTaken') !== -1) {
                    setrsuac({ type: 'danger', text: 'E-mail já está em utilização.' });
                } else if (rs.phone && rs.phone.indexOf('AlreadyTaken')  !== -1) {
                    setrsuac({ type: 'danger', text: 'Telefone já cadastrado.' });
                } else if (rs.user && rs.user.indexOf('AlreadyTaken')  !== -1) {
                    setrsuac({ type: 'danger', text: 'Usuário indisponível.' });
                }
            }
        }

        setrpuac(false);
    }

    /**
     * Set new profile picture
     * 
     * @param e Event Listener
     * @return void
     */
    async function setProfilePicture(e) {
        let image = e.currentTarget.files[0];
        const frm = new FormData();

        frm.append('image', image, image.filename);

        if (image) {
            try {
                const response = await api.instance().post('/users/changes/profilePicture', frm, { headers: { 'Content-Type': 'multipart/form-data' } });
                let usr = user;
                usr.picture = response.data.filename;
                setUser({ ...usr });
            } catch (err) {
                if (err.response) {
                    console.log(err.response.data);
                }
            }
        }
    }


    useEffect(() => {

        if (triggerVisibility !== 0) setvisibility(!visibility);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerVisibility])
    
    useEffect(() => {

        if (visibility) {
            if (!devicesConnected.length) {
                getDevicesConnected();
            }
        } else {
            // Reset on close modal
            setrsupf(null);
            setrsuac(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibility]);


    return (
        <Modal id="user-profile" backdropClassName="nopacity bg-white" show={visibility} onHide={() => setvisibility(false)}>
            <Modal.Body>
                <div className="d-flex justify-content-between">
                    <Button variant="link" onClick={() => logoutSession()} className="font-13 text-dark">
                        <i className="position-relative dripicons-power mr-1 text-dark" style={{top:-2}}></i>Finalizar Sessão
                    </Button>
                    <Button onClick={() => setvisibility(false)} variant="link" className="text-dark">
                        <i className="mdi mdi-window-close"></i>
                    </Button>
                </div>
                <div className="text-center mt-1 mb-4">
                    <input type="file" accept="image/png,image/jpeg" className="d-none" onChange={setProfilePicture} ref={inputProfilePicture} />
                    {
                        user.picture === null ?
                        (
                            <div
                                style={{cursor:'pointer',width:128,height:128,fontSize:28,letterSpacing:'4px',lineHeight:'128px'}} className="shadow-sm d-inline-block text-uppercase text-dark bg-white rounded-circle"
                                onMouseEnter={e => e.currentTarget.innerHTML = '<i class="mdi mdi-camera fadeInDown faster animated"></i>'}
                                onMouseLeave={e => e.currentTarget.innerHTML = user.name.substr(0,2)}
                                onClick={() => inputProfilePicture.current.click()}
                            >
                                {user.name.substr(0,2)}
                            </div>
                        ) : (
                            <img onClick={() => inputProfilePicture.current.click()} style={{cursor:'pointer',border:'3px solid #fff'}} src={process.env.REACT_APP_API_STORAGE_BASEURL + '/users/images/pp/' + user.picture} width="128" height="128" className="shadow-sm rounded-circle" alt="" />
                        )
                    }
                    <h4 className="mt-3">{user.name}</h4>
                    <p className="text-muted">{user.email}</p>
                </div>
                <div>
                    <Tabs
                        className="justify-content-center border-0"
                        defaultActiveKey="Home"
                        id="userNavigation-tab"
                        // onSelect={key => {
                        //     let keys = ['Home', 'Informations', 'Secutiry', 'ConnectedDevices'];
                        //     if (currentActiveTab === key) return;
                        //     let direction = currentActiveTab === null ? 'Right' : ( keys.indexOf(currentActiveTab) < keys.indexOf(key) ? 'Right' : 'Left' );
                        //     setTimeout(() => {
                        //         window.$('#user-profile .tab-content .tab-pane.active').toggleClass(`animated fadeIn${direction} faster`);
                        //         currentActiveTab = key;
                        //     }, 1);
                        // }}
                    >
                        <Tab eventKey="Home" title={<><i className="mdi mdi-home-outline"></i> Início</>} tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border border-dark">
                            
                            <Row className="justify-content-center">
                                <Col md="6">
                                    <Row className="mt-4">
                                        <Col md="6">
                                            <h2 className="mt-3 mb-2">Olá</h2>
                                            <p>Mantenha seus dados pessoais atualizados :)</p>
                                            {/* <div className="mb-3">
                                                <p>Caso precise de ajuda:</p>
                                                <a href="./" style={{fontSize: 24, marginRight: 8, color: '#25d366'}}><i className="mdi mdi-whatsapp"></i></a>
                                                <a href="./" style={{fontSize: 24, marginRight: 8, color: '#1877f2'}}><i className="mdi mdi-facebook"></i></a>
                                                <a href="./" style={{fontSize: 24, marginRight: 8, color: '#1da1f2'}}><i className="mdi mdi-twitter"></i></a>
                                                <a href="./" style={{fontSize: 24, marginRight: 8, color: '#03a5f0'}}><i className="mdi mdi-email-outline"></i></a>
                                            </div> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Tab>

                        <Tab eventKey="Informations" title={<><i className="mdi mdi-account-outline"></i> Meus Dados</>} tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border border-dark">

                            <Row className="justify-content-center my-4">
                                <Col md="6">
                                    <Form onSubmit={onSubmitUpdateAccess}>
                                        <h2 className="my-3">Dados de Acesso</h2>
                                        <Form.Group as={Row} controlId="frmUsrInformations-access--email">
                                            <Form.Label column sm="5">E-mail</Form.Label>
                                            <Col sm="7">
                                                <Form.Control name="email" defaultValue={user.email || ''} type="text" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="frmUsrInformations-access--username">
                                            <Form.Label column sm="5">Usuário</Form.Label>
                                            <Col sm="7">
                                                <Form.Control name="user" defaultValue={user.user || ''} type="text" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="frmUsrInformations-access--cellphone">
                                            <Form.Label column sm="5">Telefone</Form.Label>
                                            <Col sm="7">
                                                <Form.Control name="phone" maxLength="16" defaultValue={user.phone || ''} type="text" />
                                            </Col>
                                        </Form.Group>
                                        <Row>
                                            <Col md="7">
                                                <small className="text-muted">Cuidado ao alterar seus dados de acesso. Lembre-se, você pode acessar com e-mail, usuário ou telefone. Quanto mais dados, melhor.</small>
                                            </Col>
                                            <Col md="5" className="text-right">
                                                <Button
                                                    type="submit"
                                                    variant={rsuac !== null ? rsuac.type : 'success'} 
                                                    className="shadow-none font-12"
                                                    onMouseEnter={ e => {
                                                        if (!rpuac && rsuac !== null && rsuac.type === 'danger') {
                                                            e.target.innerHTML = 'Tentar novamente?'
                                                        }
                                                    }}
                                                    onMouseLeave={ e => {
                                                        if (!rpuac && rsuac !== null && rsuac.type === 'danger') {
                                                            e.target.innerHTML = rsuac.text
                                                        }
                                                    }}
                                                >
                                                    {rpuac === true ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : rsuac !== null ? rsuac.text : 'Salvar'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {/* <Form className="mt-4" onSubmit={e => e.preventDefault()}>
                                        <h2 className="my-3">Seu Endereço <br /><small className="text-muted">Recurso indisponível.</small></h2>
                                        <Form.Group as={Row} controlId="frmUsrInformations-address--street">
                                            <Form.Label column sm="5">Logradouro/Nome</Form.Label>
                                            <Col sm="7">
                                                <Form.Control disabled type="text" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="frmUsrInformations-address--neighborhood">
                                            <Form.Label column sm="5">Bairro/Distrito</Form.Label>
                                            <Col sm="7">
                                                <Form.Control disabled type="text" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="frmUsrInformations-address--federation-unity">
                                            <Form.Label column sm="5">Localidade/UF</Form.Label>
                                            <Col sm="7">
                                                <Form.Control disabled type="text" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="frmUsrInformations-address--cep">
                                            <Form.Label column sm="5">CEP</Form.Label>
                                            <Col sm="7">
                                                <Form.Control disabled type="text" />
                                            </Col>
                                        </Form.Group>
                                        <div className="text-right">
                                            <Button className="shadow-none" disabled type="submit" variant="success">Salvar</Button>
                                        </div>
                                    </Form> */}
                                </Col>
                            </Row>

                        </Tab>

                        <Tab eventKey="Secutiry" title={<><i className="mdi mdi-shield-key-outline"></i> Segurança</>} tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border border-dark">
                            
                            <Row className="justify-content-center my-4">
                                <Col md="6">
                                    <Row>
                                        <Col md="6">
                                            <h2 className="my-3">Senha</h2>
                                            <Form onSubmit={onSubmitUpdatePasswordForm}>
                                                <Form.Group controlId="frmUserInformation-currentPassword">
                                                    <Form.Control type="password" name="currentPassword" placeholder="Senha Atual" />
                                                </Form.Group>
                                                <Form.Group controlId="frmUserInformation-newPassword">
                                                    <Form.Control type="password" name="newPassword" placeholder="Nova Senha" />
                                                </Form.Group>
                                                <Form.Group controlId="frmUserInformation-confirmNewPassword">
                                                    <Form.Control type="password" name="newPasswordConfirm" placeholder="Confirmar Senha" />
                                                </Form.Group>
                                                <Button
                                                    type="submit"
                                                    block
                                                    variant={rsupf !== null ? rsupf.type : 'success'} 
                                                    className="shadow-none mt-3"
                                                >
                                                    {rpupf === true ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : rsupf !== null ? rsupf.text : 'Alterar Minha Senha'}
                                                </Button>
                                            </Form>
                                        </Col>
                                        {/* <Col md="6" className="text-center mt-3">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/authenticator.png'} width="64" className="mb-2" alt="" />
                                            <h2>2FA</h2>
                                            <p>Autenticação por dois fatores será possível, nos dê mais um tempinho... :)</p>
                                        </Col> */}
                                    </Row>
                                </Col>
                            </Row>

                        </Tab>

                        <Tab eventKey="ConnectedDevices" title={<><i className="mdi mdi-cellphone-link"></i> Dispositivos Conectados</>} tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border border-dark">
                            
                            <Row className="justify-content-center my-4">
                                <Col md="6">
                                    {devicesConnected.map((device) => (
                                        <Card key={device.Id} className="shadow-none rounded-0">
                                            <Card.Body className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <UserAgentProvider ua={device.UserAgent}>
                                                        <>
                                                            <UserAgent ios>
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/iphone.svg'} className="mr-2" width="64" alt="" />
                                                            </UserAgent>
                                                            <UserAgent android>
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/smartphone.svg'} className="mr-2" width="64" alt="" />
                                                            </UserAgent>
                                                            <UserAgent mac>
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/apple.svg'} className="mr-2" width="64" alt="" />
                                                            </UserAgent>
                                                            <UserAgent windows>
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/windows.svg'} className="mr-2" width="64" alt="" />
                                                            </UserAgent>
                                                            <UserAgent linux>
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/linux.svg'} className="mr-2" width="64" alt="" />
                                                            </UserAgent>
                                                            <div className="ml-2">
                                                                <UserAgent returnFullParser>
                                                                    {parser => (
                                                                        <div>
                                                                            {parser.getOS().name+", "+parser.getBrowser().name}
                                                                        </div>
                                                                    )}
                                                                </UserAgent>
                                                                <Badge>expira {window.moment().to(device.Expire)}</Badge>
                                                                <br />
                                                                <Badge>último acesso {window.moment().from(device.LastAccess).replace('em', 'há')}</Badge>
                                                            </div>
                                                        </>
                                                    </UserAgentProvider>
                                                </div>
                                                <div>
                                                    {
                                                        window.navigator.userAgent === device.UserAgent
                                                        ?
                                                            <Badge variant="success" className="rounded-pill py-1 px-2">Atual</Badge>
                                                        :
                                                            <Button onClick={() => delSpecificSession(device.Token)} variant="link" size="sm" className="p-0 font-10 text-danger">Desconectar</Button>
                                                    }
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Col>
                            </Row>

                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
        </Modal>
    )
}