import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';
import Cookies from "helpers/cookies";
import api from "services/api";

export default function StageSelectInstance({ data: { user, instances, session, modules }, setStage }) {

    //
    const [slcIns, setSlcIns]   = useState({});
    
    //
    const [inst, setinst]       = useState([]);

    //
    const [rscf, setrscf]       = useState(false);

    async function setSessionInstance(InstanceId, InstanceName, idx) {
        let InstancesAll = instances.map(r => {
            r.Selected = false;
            return r;
        });
        
        InstancesAll[idx].Selected = true;
        try {
            await api.put('/auth/session/instance', { instance: InstanceId });
            setStage({ to: 'SelectCompaniesInstance', data: {
                InstanceName,
                forback: { user, instances, session, modules }
            }});
        } catch (err) {
            
        }
    }

    function logout() {
        api.delete('/auth').then(() => {
            Cookies.remove(
                process.env.REACT_APP_COOKIE_AUTH,
                '/',
                process.env.REACT_APP_COOKIE_DOMAIN
            );
        }).catch(() => {});
        setStage({ to: 'Login', data: {} });
    }

    function onClickInstance({ Id, Name }, idx) {
        setSessionInstance(Id, Name, idx);
    }

    async function requestAccess() {
        let instances = inst.map(x => x.name);

        try {
            await api.post('/users/accessInstances', { instances });
            setrscf(true);
            window.$('#asdjnc89023yjkas').attr('disabled', true);
        } catch (err) {
            console.error(err.response);
        }
    }

    useEffect(() => {
        if ("text" in slcIns) {
            if (inst.findIndex(x => x.name === slcIns.text) === -1) {
                setinst([...inst, {name: slcIns.text, brand: slcIns.brand}]);
            }
        }
    // eslint-disable-next-line
    }, [slcIns])

    useEffect(() => {
        
        let $stage = window.$('.stage.select-instance');
        $stage.find('.instances').slimScroll();
        let heightTo = (window.innerHeight-$stage.height())/2;
        $stage.css('margin-top',  heightTo < 0 ? 10 : heightTo + 'px');

        if (!instances.length) {

            let token = Cookies.get(process.env.REACT_APP_COOKIE_AUTH);

            window.$('#asdjnc89023yjkas').select2({
                minimumInputLength: 3,
                placeholder: "Pesquisar",
                language: {
                    inputTooShort: () => "Digite no mínimo 3 letras para pesquisar...",
                    noResults: () => "Nenhum grupo encontrado :/",
                    searching: () => "Pesquisando..."
                },
                templateResult: (state) => {
                    if (isNaN(state.id)) {
                        return state.text;
                    }
                    let $state = window.$(
                        `<div><div style="width:75px;display:inline-block;text-align:center;"><img src="${state.brand}" style="height:50px;max-width:65px" class="mr-2" alt="${state.text}" /></div>${state.text}</div>`
                    );

                    return $state;
                },
                ajax: {
                    headers: {
                        authorization: `Bearer ${token}`
                    },
                    delay: 250,
                    url: process.env.REACT_APP_API_BASEURL + '/users/accessInstances',
                    dataType: 'json',
                    data: pr => ({ search: pr.term }),
                    processResults: function (data) {
                        if (data.length) {
                            data = data.map((x, idx) => ({
                                id: Math.random(),
                                text: x.Name,
                                brand: x.Brand !== null ? (process.env.REACT_APP_API_STORAGE_BASEURL + '/instances/brands/' + x.Brand) : (process.env.PUBLIC_URL + '/assets/images/database.png')
                            }))
                        }
                        return {
                            results: data,
                        };
                    },
                    cache: true
                  }
            }).on('select2:select', function(e) {
                let data = window.$(e.currentTarget).select2('data')[0];
                let news = { text: data.text, brand: data.brand };

                setSlcIns({...news});

                window.$(e.currentTarget).val(null).trigger('change');
            });
        }
    // eslint-disable-next-line
    }, []);

    return (
        <Container className="stage select-instance pulse animated">
            <Row className="justify-content-center">
                <Col>
                    <Card className="select-instance shadow-none bg-transparent fadeIn animated">
                        <Card.Body>
                            <Card.Header className="text-center bg-transparent border-0">
                                <h1 className="text-dark">Olá {user.name.split(' ')[0]}.</h1>
                                { 
                                    (instances.length > 1 && user.type === "CLT")
                                    && 
                                    <div className="mb-3">Selecione a instância para continuar!</div>
                                }
                            </Card.Header>
                            {
                                instances.length > 0
                                ?
                                    <Row className="instances justify-content-center px-2">
                                        {instances.map((Instance, idx) => (
                                            <Col key={idx} md="3" className="instance">
                                                <Card className="bg-transparent shadow-none" onClick={() => onClickInstance(Instance, idx)}>
                                                    <Card.Body className="text-center">
                                                        <div>
                                                            <img src={Instance.Brand !== null ? `${process.env.REACT_APP_API_STORAGE_BASEURL}/instances/brands/${Instance.Brand}` : process.env.PUBLIC_URL + '/assets/images/database.png'} alt="" height="64" />
                                                        </div>
                                                        <h4 className="text-dark mt-2">{Instance.Name}</h4>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                        {/* <p className="text-center mb-0 mt-3">
                                            <Button
                                                variant="link"
                                                className="py-0"
                                                onClick={() => logout()}
                                            >Cancelar</Button>
                                        </p> */}
                                    </Row>
                                :
                                    <div className="text-center">
                                        <p>Até o momento você não está vinculado a nenhum grupo de empresas.</p>
                                        <p>Mas se quiser, pode enviar uma solicitação de acesso.</p>
                                        <Row className="mt-4 justify-content-center">
                                            <Col xs="12" md="4">
                                                <div>
                                                    <Form.Group controlId="asdjnc89023yjkas">
                                                        <Form.Control as="select">
                                                            <option></option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </div>
                                                {inst.length > 0 && (
                                                    <div className="mt-4">
                                                        {inst.map(el => (
                                                            <div className="d-flex justify-content-between align-items-center mb-3" key={Math.random()}>
                                                                <div>
                                                                    <div style={{width:75,display:'inline-block'}}>
                                                                        <img src={el.brand} style={{height:50, maxWidth:65}} className="mr-2" alt={el.name} />
                                                                    </div>
                                                                    {el.name}
                                                                </div>
                                                                <Button variant="link" onClick={() => setinst([...inst.filter(x => x.name !== el.name)])} className="text-danger">
                                                                    <i className="mdi mdi-close"></i>
                                                                </Button>
                                                            </div>
                                                        ))}
                                                        {!rscf ? (
                                                            <Button variant="success" onClick={requestAccess} className="shadow-none mt-3">Enviar</Button>
                                                        ) : (
                                                            <Alert variant="success">Sua solicitação foi enviada, agora é só aguardar.</Alert>
                                                        )}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <p className="mt-4 mb-0">
                                            <Button
                                                variant="link"
                                                className="p-0"
                                                onClick={() => logout()}
                                            >Acessar com outra conta</Button>
                                        </p>
                                    </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}