import React, { useState, useEffect, useMemo, useCallback } from 'react'

import api from 'services/api'

import ModalTargetsRegister from './ModalTargetsRegister'

import { Button, Row, Col, Card, Badge, Table, Spinner } from 'react-bootstrap'

import CountUp from 'react-countup'

import { Donut, ResponsiveContainer } from 'britecharts-react'

import ReactApexChart from 'react-apexcharts'

import Datetime from 'react-datetime'

import { realCurrency } from 'helpers/functions'

import moment from 'moment'
import 'moment/locale/pt-br'

const tm = {};
			tm.months					= moment.months();
			tm.weekdays 			= moment.weekdays();
			tm.weekdaysShort 	= moment.weekdaysShort();
			tm.month					= moment().month();
			tm.date						= moment().date();


const isValidDateOnlyMonth = (current, year, month) => {
	const mm = moment().set({'year': year, 'month': month});

	return (
		current.isSameOrAfter(mm.startOf('month')) 
		&& 
		current.isSameOrBefore(mm.endOf('month'))
	);
}

const CountUpCurrency = (end) => (
	<CountUp
		end={end}
		duration={1}
		separator="."
		decimals={2}
		decimal=","
		prefix="R$ "
	/>
);

const getTargetsWeekdayMessageByType = (type) => {
	let msg = 'Previsão dos dias da semana com base ';
	switch (type) {
		
		case 'last_twelve_months':
			msg = msg + 'nos últimos 12 meses.';
			break;
		case 'same_month_previous_year':
				msg = msg + 'no mesmo mês do ano anterior.';
			break;
		case 'average_month':
				msg = msg + ' na média do mês.';
		break;
		
		default:
			if (type.slice(0,6) === 'custom') {
				let dates = (type.split(':')[1]).split(',');
				msg = msg + 'em um período que inicia em ' + moment(dates[0]).format('DD MMMM YYYY') + ' até ' + moment(dates[1]).format('DD MMMM YYYY') + '.';
			} else {
				return '';
			}
	}
	return msg;
}


const _fnParseSalesOfMonth = (year, salesOfMonth, salesOfProducts, currentMonthTgts) => {
	const mm = moment().set({ 'year': year, 'month': currentMonthTgts.month });
	const lastDayMonth = mm.endOf('month').date();

	const products = salesOfProducts.map(product => {
		product.TotalRealCurrency = realCurrency(Number(product.Total));
		return product;
	});
	
	const ctrl = {
		value: 0,
		days: [],
		weekdays: [],
		products: {
			total: products.reduce((x,y) => Number(y.Total) + x, 0),
			items: products
		}
	};

	ctrl.value = salesOfMonth.reduce((x, y) => Number(y.Total) + x, 0);

	for (let d = 1; d <= lastDayMonth; d++) {
		mm.set({ 'date': d });
		let idx = salesOfMonth.findIndex(item => Number(item.Day) === d);
		let bb = idx !== -1;

		ctrl.days.push({
			day: d,
			week: mm.weekday(),
			date: mm.format('YYYY-MM-DD'),
			value: bb ? Number(salesOfMonth[idx].Total) : 0
		});
	}

	for (let w = 0; w < 7; w++) {
		let k = ctrl.days.filter(x => x.week === w);
		let q = k.length;
		let t = k.reduce((x, y) => y.value + x, 0);
		ctrl.weekdays.push({
			week: w,
			quantity: q,
			valueTotal: t
		});
	}

	ctrl.target 				= currentMonthTgts.value;
	ctrl.month 					= currentMonthTgts.month;
	ctrl.monthName 			= currentMonthTgts.monthName;
	ctrl.targetCurrency = realCurrency(ctrl.target);
	ctrl.valueCurrency 	= realCurrency(ctrl.value);
	ctrl.dt 						= currentMonthTgts.dt;

	ctrl.pv = (ctrl.target !== 0 && ctrl.value !== 0) ? (((ctrl.value-ctrl.target)/ctrl.target)*100) : 0;

	for (let x = 0; x < ctrl.days.length; x++) {
		let wkv = currentMonthTgts.weekdays.find(item => item.week === ctrl.days[x].week);
		ctrl.days[x].target = wkv.value;
	}

	for (let x = 0; x < ctrl.weekdays.length; x++) {
		let wkv = currentMonthTgts.weekdays.find(item => item.week === ctrl.weekdays[x].week);
		ctrl.weekdays[x].weekName = tm.weekdays[ctrl.weekdays[x].week];
		ctrl.weekdays[x].targetPerDay = wkv.value;
		ctrl.weekdays[x].targetTotal =	wkv.value * wkv.quantity;
		ctrl.weekdays[x].pv = ctrl.weekdays[x].targetTotal > 0 && ctrl.weekdays[x].valueTotal > 0 ? (((ctrl.weekdays[x].valueTotal-ctrl.weekdays[x].targetTotal)/ctrl.weekdays[x].targetTotal)*100) : 0;
	}
	
	return ctrl;
}

function StatsCurrentDate({ year, month, data }) {

	const [dayNow, setDayNow] = useState(tm.date);

	const date = useMemo(() => data.days.find(item => item.day === dayNow), [dayNow, data]);

	const kv = date.target-date.value;
	const pv = date.value !== 0 && date.target !== 0 ? ((date.value-date.target)/date.target)*100 : 0;

	return (
		<Row>
			<Col md="4">
				<Card className="shadow-none mb-0">
					<Card.Body className="p-1">
						<h5 className="text-muted">
							<Datetime 
								closeOnSelect={true}
								timeFormat={false}
								value={new Date(year, month, dayNow)}
								isValidDate={(current) => isValidDateOnlyMonth(current, year, month)}
								onChange={(mm) => setDayNow(mm.date())}
								renderInput={(props, openCalendar) => {
								return (
									<Button 
										size="sm"
										variant="link" 
										className="font-12 p-0 font-weight-bold"
										onClick={openCalendar}
									>
										<i className="mdi mdi-calendar-text mr-1"></i>
										{`${dayNow}/${month+1}/${year}`} 
									</Button>
								)
							}} />
						</h5>
						<h4 className="text-uppercase">{tm.weekdays[date.week]}</h4>
					</Card.Body>
				</Card>
			</Col>
			<Col md="2">
				<Card className="shadow-none mb-0">
					<Card.Body className="p-1">
						<h5 className="text-muted">META</h5>
						<h4>
							{CountUpCurrency(date.target)}
						</h4>
					</Card.Body>
				</Card>
			</Col>
			<Col md="2">
				<Card className="shadow-none mb-0">
					<Card.Body className="p-1">
						<h5 className="text-muted">VENDIDO</h5>
						<h4>{CountUpCurrency(date.value)}</h4>
					</Card.Body>
				</Card>
			</Col>
			<Col md="2">
				<Card className="shadow-none mb-0">
					<Card.Body className="p-1">
						<h5 className="text-muted">FALTA</h5>
						<h4>{kv < 0 ? 'R$ 0,00' : CountUpCurrency(kv)}</h4>
					</Card.Body>
				</Card>
			</Col>
			<Col md="2">
				<Card className="shadow-none mb-0">
					<Card.Body className="p-1">
						<h5 className="text-muted">ALCANÇADO</h5>
						<h4 className={`text-${pv < 0 ? 'warning':'success'}`}>
							{pv > 0 && (<i className="mdi mdi-plus mr-1"></i>)}
							{(pv === 0 ? 0 : pv < 0 ? (100+pv) : pv).toFixed(0) + '%'}
						</h4>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}


export default function TargetsView({ $, company, year, setYear, zmonth, setMonth, forceReloadSalesTarget }) {
	
	const [dtViewMode, setDtViewMode] 	= useState('months')
	const [dtOpen, setDtOpen] 					= useState(false)
	const [tgts, setTgts]								= useState(null)

	const monthsTgts = useMemo(() => {

		let months = $.data.time.months;

		return tm.months.map((month, n) => {
			let idx = months.findIndex(item => item.i === n);
			let bb = idx !== -1;
			let mm 	= moment().set({'year': year, 'month': n});

			let type = !bb ? null : (typeof months[idx].t !== "undefined") ? months[idx].t : null;

			return {
				type,
				month: n,
				monthName: month,
				value: bb ? months[idx].v : 0,
				valueCurrency: bb ? realCurrency(months[idx].v) : null,
				dt: { start: mm.startOf('month').format('YYYY-MM-DD'), end: mm.endOf('month').format('YYYY-MM-DD') },
				weekdays: bb ? months[idx].w.map(x => ({week: x.i, quantity: x.q, value: x.v})) : [0,1,2,3,4,5,6].map(x => ({week: x, quantity: 0, value: 0}))
			}
		});

	}, [$, year])

	const currentMonthTgts 	= useMemo(() => monthsTgts.find(item => item.month === zmonth), [zmonth, monthsTgts])

	const getSales = useCallback(async () => {

		const fSalesOfMonth = {
			date: {
				month: currentMonthTgts.month + 1,
				year: year
			},
			companies: company.Id
		};
		
		const fSalesOfProducts = {
			date: currentMonthTgts.dt,
			companies: company.Id,
			limit: 25
		};

		setTgts(null);

		try {
			const salesOfMonth = await api.get('/reports/sales/salesOfMonth', { filters: fSalesOfMonth });
			const salesOfProducts = await api.get('/reports/sales/salesOfProducts', { filters: fSalesOfProducts });

			const result = _fnParseSalesOfMonth(
				year,
				salesOfMonth.data.data,
				salesOfProducts.data,
				currentMonthTgts
			);

			setTgts(result);

		} catch (err) {
			if (err.response) {
				alert('Não foi possível obter os dados de vendas.');
				window.location.reload();
			}
		}
		
	}, [company, year, currentMonthTgts])
	

	useEffect(() => {
		getSales();
	}, [zmonth, getSales])

	return (
		<>
			<Row className="mt-4">

				{/* Left side */}
				<Col lg="5" xl="3">
					<Card>
						<Card.Body>
							<Datetime 
								viewMode={dtViewMode}
								open={dtOpen}
								closeOnSelect={true}
								timeFormat={false}
								dateFormat={dtViewMode === 'years' ? 'YYYY' : 'MM'}
								value={dtViewMode === 'years' ? null : new Date(year, zmonth)}
								onViewModeChange={(md) => setDtViewMode(md)}
								onChange={(mm) => {
									if (mm.year() !== year) {
										setYear(mm.year());
									} else {
										setDtOpen(false);
										setMonth(mm.month())
									}
								}}
								renderInput={() => (
									<Button
										onClick={() => setDtOpen(!dtOpen)}
										variant="link"
										className="p-0"
									>
										<h1>{currentMonthTgts.monthName} {year}</h1>
									</Button>
								)}
							/>
							<Row className="mt-4">

								<Col className="mb-3">
									<b className="text-muted font-12">META</b>
									<div className="d-flex justify-content-between">
									<h3 className="m-0">{
										tgts !== null ? 
											CountUpCurrency(currentMonthTgts.value)
										||
											(
												<i className="text-muted">INDEFINIDA</i>
											)
										:
											'...' 
									}</h3>
									{tgts !== null ? (
										<ModalTargetsRegister 
											month={zmonth}
											year={year}
											company={company} 
											btnText={currentMonthTgts.valueCurrency !== null ? 'REDEFINIR META' : 'DEFINIR META'}
											forceReloadSalesTarget={forceReloadSalesTarget}
										/>
									) : null}
									</div>
								</Col>

								<div className="w-100"></div>

								<Col className="mb-3">
									<b className="text-muted font-12">VENDIDO</b>
									<h3 className="m-0">{
										tgts !== null ? 
											CountUpCurrency(tgts.value)
										:
											'...'
									}</h3>
								</Col>

								<div className="w-100"></div>

								<Col className="mb-3">
									<b className="text-muted font-12">SITUAÇÃO</b>
									{tgts !== null ? (
										<h3 className={`m-0 text-${tgts.pv > 0 ? 'success':'info'}`}>
											{tgts.pv > 0 && (<i className="mdi mdi-plus mr-1"></i>)}
											{(tgts.pv < 0 ? 100+tgts.pv : tgts.pv).toFixed(2) + '%'}
										</h3>
									) : null}
								</Col>

								{tgts !== null ? (
								<Col className="mb-3">
									<b className="text-muted font-12">{tgts.pv > 0 ? 'SUPEROU':'NECESSÁRIO'}</b>
									<h4 className={`m-0 text-${tgts.pv > 0 ? 'success':'warning'}`}>{realCurrency(Math.abs(tgts.target-tgts.value))}</h4>
								</Col>
								) : null}
								

								<div className="w-100"></div>
								
								<Col>
									<ResponsiveContainer 
										render={({width}) => (
											<div>
												<Donut
													data={tgts !== null ? [
														{ id:1, quantity: tgts.value, name: 'VENDIDO' },
														{ id:2, quantity: tgts.target-tgts.value, name: 'A VENDER' },
													] : [
														{ id:1, quantity:1, name: '...'}
													]}
													height={width}
													width={width}
													internalRadius={80}
													isAnimated={false}
													highlightSliceById={1}
													hasFixedHighlightedSlice={true}
												/>
											</div>
										)}
									/>
								</Col>

							</Row>
						</Card.Body>
					</Card>
				</Col>

				{/* Right side */}
				<Col lg="7" xl="9">

					{/* Stats by day selected of month */}
					<Card>
						<Card.Body>
							{tgts !== null ? (
								<StatsCurrentDate 
									month={zmonth}
									year={year}
									data={tgts}
								/>
							) : (
								<div className="my-4 d-flex justify-content-center">
									<Spinner animation="border" variant="dark" />
								</div>
							)}
						</Card.Body>
					</Card>
					
					{/* Stats by weekdays */}
					<Card>
						<Card.Body>
							<h4>DIAS DA SEMANA</h4>
							{tgts !== null ? (
							<Row>
								{tgts.weekdays.map(week => (
									<Col key={week.week}>
										<Card className="shadow-none mb-0">
											<Card.Body className="p-2">
												<h5 className="text-uppercase">{week.weekName}</h5>
												<hr />
												<b className="font-10 text-muted">META</b>
													<h6 className="mt-0 mb-1">{week.quantity} x {realCurrency(week.targetPerDay)}</h6>
												<b className="font-10 text-muted">PREVISTO</b>
													<h6 className="mt-0 mb-1">{realCurrency(week.targetTotal)}</h6>
												<b className="font-10 text-muted">ALCANÇADO</b>
													<h6 className="mt-0 mb-1">
														{realCurrency(week.valueTotal)}
													</h6>
												<Badge variant={week.pv === 0 ? 'light' : week.pv < 0 ? 'warning-lighten':'success-lighten'} className="font-12 mt-2">
													<i className={`mr-1 mdi mdi-${week.pv > 0 ? 'plus':'tilde'}`}></i>
													{(week.pv === 0 ? 0 : week.pv < 0 ? (100+week.pv) : week.pv).toFixed(0) + '%'}
												</Badge>
											</Card.Body>
										</Card>
									</Col>
								))}
								{currentMonthTgts.type !== null && (
									<Col xs="12">
										<div className="font-11 mt-3">
											{getTargetsWeekdayMessageByType(currentMonthTgts.type)}
										</div>
									</Col>
								)}
							</Row>
							) : (
								<div className="my-5 d-flex justify-content-center">
									<Spinner animation="border" variant="dark" />
								</div>
							)}
						</Card.Body>
					</Card>

					{/* Stats by days */}
					<Card>
						<Card.Body>
							<h4>VENDAS POR DIAS</h4>
							<ReactApexChart 
								type="line" 
								height="350"
								series={tgts !== null ? [{
									name: 'Meta',
									data: tgts.days.map(x => x.target)
								}, {
									name: 'Vendido',
									data: tgts.days.map(x => x.value)
								}] : []}
								options={{								
									dataLabels: {
										enabled: false
									},
									stroke: {
										show: true,
										width: 3,
										curve: 'smooth'
									},
									xaxis: {
										categories: tgts !== null ? tgts.days.map(x => x.day) : [],
									},
									yaxis: {
										title: {
											text: 'R$ (reais)'
										},
										labels: {
											formatter: (value) => { return Math.floor(value) },
										},
									},
									fill: {
										opacity: 1
									},
									tooltip: {
										x: {
											// show: true,
											// format: 'dd MMM',
											formatter: (dayIndex, config) => {
												if (tgts !== null) {
													return `${tm.weekdays[tgts.days[tgts.days.findIndex(item => item.day === dayIndex)].week]}, ${dayIndex}`;
												}
												return `${dayIndex}`;
											},
										},
										y: {
											formatter: function (val) {
												return realCurrency(val);
											}
										}
									}
								}}
							/>
						</Card.Body>
					</Card>
							
					{/* List of products */}
					<Card>
						<Card.Body>
							<h4>PRODUTOS</h4>
							<Table size="sm" className="font-13">
							<thead>
								<tr>
									<th>Produto</th>
									<th width="150">Quantidade</th>
									<th width="150">Total</th>
								</tr>
							</thead>
							<tbody>
								{tgts !== null ? (
									tgts.products.items.map(product => (
										<tr key={product.Id}>
											<td className="text-uppercase">{product.Description}</td>
											<td>{product.Quantity}</td>
											<td>{product.TotalRealCurrency}</td>
										</tr>
									))
								) : null}
							</tbody>
							</Table>
						</Card.Body>
					</Card>

				</Col>


			</Row>
		</>
	);
}