import qs from "qs";
import axios from "axios";
import Cookies from "../helpers/cookies";

class api {
    constructor() {
        
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_API_BASEURL
        });
        
        this.axios.interceptors.request.use(config => {
            let token = Cookies.get(process.env.REACT_APP_COOKIE_AUTH);
            if (token) config.headers['Authorization'] = 'Bearer ' + token;
            return config;
        }, err => {
            return Promise.reject(err);
        });

        this.axios.interceptors.response.use(
            response    => response,
            error       => {
                if (axios.isCancel(error)) {
                    return Promise.reject(error);
                } else {
                    if (error.response) {
                        const { data, status } = error.response;
                        if (status === 401 && data === "Unauthorized.") {
                          Cookies.remove(process.env.REACT_APP_COOKIE_AUTH);
                          window.location.reload();
                          return;
                        } else {
                          return Promise.reject(error);
                        }
                    } else {
                        return Promise.reject(error);
                    }
                }
            }
        );
        this.dataAlwaysSent = {};
        this.sourcesRequestsCancelable = [];
    }

    setAlwaysSent(key, data) {
        this.dataAlwaysSent[key] = data;
    }
    removeAlwaysSent(key) {
        delete this.dataAlwaysSent[key];
    }

    get(url, data) {
        return this.fetch("get", url, data);
    }
    post(url, data) {
        return this.fetch("post", url, data);
    }
    put(url, data) {
        return this.fetch("put", url, data);
    }
    delete(url, data) {
        return this.fetch("delete", url, data);
    }
    patch(url, data) {
        return this.fetch("patch", url, data);
    }

    instance() {
        return this.axios;
    }

    cancelAllRequests() {
        for (let i = 0; i < this.sourcesRequestsCancelable.length; i++) {
            this.sourcesRequestsCancelable[i].cancel();
        }
        this.sourcesRequestsCancelable = [];
    }

    createCancelToken() {
        const CancelToken = axios.CancelToken;
        this.sourcesRequestsCancelable.push(CancelToken.source());
        return this.sourcesRequestsCancelable[
            this.sourcesRequestsCancelable.length - 1
        ].token;
    }

    fetch(method, url, data) {
        let config = {
            cancelToken: this.createCancelToken()
        };
        // eslint-disable-next-line
        for (const key in this.dataAlwaysSent) {
            if (typeof data === "undefined") data = {};
            if (this.dataAlwaysSent.hasOwnProperty(key)) {
                data[key] = this.dataAlwaysSent[key];
            }
        }
        
        let dataApplicable = ["post", "put", "patch"].indexOf(method) !== -1;

        if (dataApplicable) {
            data = qs.stringify(data);
        } else {
            config.params = data;
            data = config;
            config = undefined;
        }

        return this.axios[method](url, data, config);
    }
}

export default new api();
