import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function StageMessage({ data }) {

    useEffect(() => {
        let $stage = window.$('.stage.message');
        let heightTo = (window.innerHeight-$stage.height())/2;
        $stage.css('margin-top',  heightTo < 0 ? 10 : heightTo + 'px');
    }, [])

    return (
        <Container className="stage message fadeIn animated">
            <Row className="justify-content-center">
                <Col md="6" lg="4">
                    <Card className={`shadow-none message bg-${data.type} ${data.animate ? data.animate : 'shake'} animated`}>
                        <Card.Body className={`text-center text-${data.color}`}>
                            <i className={`dripicons-${data.icon} h1`}></i>
                            <h4 className="mt-2">{data.title}</h4>
                            <p className="mt-3"><data.description /></p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}