import React, { useState } from 'react'

import ReactApexChart from 'react-apexcharts'

import { Row, Col, Button, ButtonGroup, Card } from 'react-bootstrap'

import { realCurrency } from 'helpers/functions'

const fnSortYearMonths = (x,y) => x.Month > y.Month ? 1 : x.Month < y.Month ? -1 : 0;

export default function MonthlyAverage({ data, salesOf, _ }) {

	const [view, setView] = useState('table');

	const months = [...data];

	const chartOptions = {
		chart: { animations: { enabled: true, }, toolbar: { show: false, }, zoom: { enabled: false, } },
		stroke: { curve: 'smooth', width: 3 },
		legend: { showForSingleSeries: true, height: _.ww ? 40 : 20, offsetY: _.ww >= 768 ? -20 : 0, itemMargin: { vertical: 10 }, },
		tooltip: { 
			y: { formatter: y => realCurrency(y) },
		},
		xaxis: {
				categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
		},
		yaxis: { show: false }
	};

	[...months]
	.filter(item => !isNaN(item.Total))
	.sort((x,y) => x.Total > y.Total ? -1 : x.Total < y.Total ? 1 : 0)
	.slice(0,3)
	.map((item, idx) => {
		const monthsIdx = months.findIndex(x => x.Month === item.Month);
		if (monthsIdx !== -1) {
			months[monthsIdx].star = {
				icon: ['mdi mdi-numeric-1-box','mdi mdi-numeric-2-box','mdi mdi-numeric-3-box'][idx],
				color: ['danger','warning','info'][idx]
			}
		}
		return item;
	});
	
	
	return (
	<>
		<div className="text-right mb-2">
			<ButtonGroup>
				<Button size="sm" className="shadow-none font-12" variant={view === 'table' ? 'dark' : 'light'} onClick={() => setView('table')}>
					<i className="mdi mdi-table mr-1"></i>
					{/* Tabela */}
				</Button>
				<Button size="sm" className="shadow-none font-12" variant={view === 'chart' ? 'dark' : 'light'} onClick={() => setView('chart')}>
					<i className="mdi mdi-chart-line mr-1"></i>
					{/* Gráfico */}
				</Button>
			</ButtonGroup>
		</div>

		<Row className="justify-content-center">
			{view === 'chart' ? (
				<Col>
					<ReactApexChart 
						options={chartOptions} 
						series={[
							{ name: 'Média', data: data.sort(fnSortYearMonths).map(x => x.Total) },
							{ name: `${_.cYear}`, data: salesOf.currentYear.sort(fnSortYearMonths).map(x => x.Total) },
							{ name: `${_.lYear}`, data: salesOf.lastYear.sort(fnSortYearMonths).map(x => x.Total) },
							{ name: `${_.blYear}`, data: salesOf.yearBeforeLast.sort(fnSortYearMonths).map(x => x.Total) }
						]} 
						type="line" 
						height="350"
					/>
				</Col>
			) : view === 'table' ? (
				months.map(x => (
					<Col key={x.Month} md="3">
						<Card className="shadow-none">
							<Card.Body className="p-1">
								<h4 className="mb-0">
									{x.MonthName}
								</h4>
								<span>
									{x.star ? (
										<i className={`mr-1 font-18 ${x.star.icon} text-${x.star.color}`}></i>
									) : (
										<i className={`mr-1 font-18 mdi mdi-checkbox-blank text-light`}></i>
									)}
									{realCurrency(isNaN(x.Total) ? 0 : x.Total)}
								</span>
							</Card.Body>
						</Card>
					</Col>
				))
			) : null}
		</Row>

		{/* <Row>
			<Col>
				<div>
					<i className="mdi mdi-numeric-1-box text-danger"></i>
					<i className="mdi mdi-numeric-2-box text-warning"></i>
					<i className="mdi mdi-numeric-3-box text-info"></i>
					{" "}
					<small>TOP TOP TOP 3.</small>
				</div>
			</Col>
		</Row> */}
		<hr />
		</>
	);
}