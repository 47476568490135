import React, { useEffect, useState } from 'react'

import { Row, Col, Nav, ListGroup, Form, Button, Modal, ButtonGroup } from 'react-bootstrap'

import api from 'services/api'

export default function Accesses() {

    const [groups, setGroups] = useState([])

    const [groupsAccesses, setGroupsAccesses]   = useState([])

    const [reqPending, setReqPending]           = useState(false)

    const [addGroup, dispatchAddGroup] = useState({
        modalVisibility: false,
        requesting: false,
        allowAll: true,
        name: '',
    })

    const [confirmDelGroup, setConfirmDelGroup] = useState(false)

    const [usersLkdGroup, dispatchUsersLkdGroup] = useState({
        modalVisibility: false,
        group: {},
        users: []
    })

    function idxCurrGroup() {
        return groupsAccesses.findIndex(x => x.active === true);
    }

    async function newGroup() {
        let { name, allowAll } = addGroup;

        if (name.length === 0) {
            return;
        }
        
        dispatchAddGroup({ ...addGroup, requesting: true });
        try {
            const { data } = await api.post('/management/usersGroups', {
                name: name,
                allowAll: allowAll ? 1 : 0
            });

            setGroups([ ...groups, { Id: data.groupId, Group: name } ]);
            dispatchAddGroup({ modalVisibility: false, name: '', requesting: false });
            return;
        } catch (err) {
            if (err.response) {
                console.error(err.response);
                
            }
        }
        dispatchAddGroup({ ...addGroup, requesting: true });
    }

    async function getAccessesSpecificsAndForms(groupId) {
        if (reqPending) return;

        let already = groupsAccesses.findIndex(x => x.groupId === groupId);

        if (already !== -1) {
            setGroupsAccesses([
                ...groupsAccesses.map(x => {
                    x.active    = x.groupId === groupId;
                    x.specifics = x.specifics.map(v => ({...v, filtered: undefined}));
                    x.forms     = x.forms.map(v => ({...v, filtered: undefined}));
                    return x;
                })
            ])
            return;
        }

        setReqPending(true);
        try {
            const { data } = await api.get('/management/accesses/specificsAndFormsByGroupId', { groupId: groupId });
            setGroupsAccesses([
                ...groupsAccesses.map(x => {
                    x.active = false;
                    return x;
                }),
                { groupId: groupId, active: true, ...data }
            ]);
        } catch(err) {
            if (err.response) {
                console.error(err.response);
            }
        }
        setReqPending(false);
    }

    function zFilter(t,y) {
        return t === "" ? undefined : !Number(t) ? y.Name.toLowerCase().indexOf(t.toLowerCase()) !== -1 : (y.Id).toString().indexOf(t) !== -1;
    }

    // function filterAllAccesses(e) {
    //     let txt = e.currentTarget.value;
    //     let z = (t,y) => 
    //     setGroupsAccesses([
    //         ...groupsAccesses.map(x => {
    //             x.specifics = x.specifics.map(y => {
    //                 y.filtered = z(txt, y);
    //                 return y;
    //             });
    //             x.forms = x.forms.map(y => {
    //                 y.filtered = z(txt, y);
    //                 return y;
    //             });
    //             return x;
    //         })
    //     ]);
    // }

    async function deleteUserGroup() {

        let group = groupsAccesses[idxCurrGroup()];

        setReqPending(true);
        
        try {
            await api.delete('/management/usersGroups', { id: group.groupId });

            setGroups([
                ...groups.filter(x => Number(x.Id) !== Number(group.groupId))
            ]);
            setGroupsAccesses([]);
        } catch (err) {
            if (err.response) {
                const { status } = err.response.data;
                if (status === 'UsersLinkedGroup') {
                    dispatchUsersLkdGroup({
                        modalVisibility: true,
                        group: groups.filter(x => parseInt(x.Id) === group.groupId)[0],
                        users: err.response.data.users
                    });
                } else {
                    alert('Erro interno. Não foi possível deletar o grupo.');
                }
            }
        }
        setConfirmDelGroup(false);
        setReqPending(false);
    }

    async function toggleAllowedAccess(type, $access, event) {
        let idx     = idxCurrGroup();
        let _accg   = groupsAccesses;
        let groupId = _accg[idx].groupId;
        let checked = event.currentTarget.checked;
        let idxacc = _accg[idx][type].findIndex(x => x.Id === $access.Id);
        
        _accg[idx][type][idxacc].Allow = checked ? 'S' : 'N';

        setGroupsAccesses([..._accg]);

        try {
            await api.put('/management/accesses/' + type, {
                group: groupId,
                access: $access.Id,
                allowed: checked ? 1 : 0,
            });
        } catch (err) {
            if (err.response) {
                alert('Erro interno. Não foi possível atualizar acesso!');
            }
        }
    }

    async function toggleAllAllowedAccesses(type, event) {
        let idx     = idxCurrGroup();
        let _accg   = groupsAccesses;
        let groupId = _accg[idx].groupId;
        let checked = event.currentTarget.checked;
        let ids = [];

        _accg[idx][type].map(x => {
            let st = ['S','SIM'].indexOf(x.Allow) !== -1;
            if ((typeof x.filtered === "undefined" || x.filtered === true) && st !== checked) {
                ids.push(x.Id);
                x.Allow = checked ? 'S' : 'N';
            }
            return x;
        });

        setGroupsAccesses([..._accg]);

        setReqPending(true);

        try {
            await api.put('/management/accesses/' + type, {
                group: groupId,
                access: ids.join(','),
                allowed: checked ? 1 : 0,
            });
        } catch (err) {
            if (err.response) {
                alert('Erro interno. Não foi possível atualizar os acessos!');
            }
        }
        setReqPending(false);
    }

    function isAllChecked(type) {
        let idx = idxCurrGroup();
        let all = groupsAccesses[idx][type].filter(x => typeof x.filtered === "undefined" || x.filtered === true);
        let allChecked = all.filter(x => ['S','SIM'].indexOf(x.Allow) !== -1);
        
        return all.length !== 0 && allChecked.length === all.length;
    }
    
    useEffect(() => {

        api.get('/management/usersGroups')
        .then(({data}) => {
            setGroups(data);
        })
        .catch(() => {});

        return () => api.cancelAllRequests();
    }, [setGroups])

    return (
        <div className="mgmt--accesses">
            <Row className="mt-4">
                <Col md="2" className="mb-3">
                    <h3 className="mb-3 mt-0 text-center">Grupos</h3>
                    {groups.length !== 0
                    ?
                        <Nav className="flex-column list-groups" onSelect={key => getAccessesSpecificsAndForms(parseInt(key))}>
                        {groups.map(($Item, idx) => (
                            <Nav.Link key={idx} className="p-1 text-uppercase" eventKey={$Item.Id}>{$Item.Group}</Nav.Link>
                        ))}
                        </Nav>
                    :
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    }
                    <div className="mt-4">
                        <Button className="font-12 shadow-none" onClick={() => dispatchAddGroup({ ...addGroup, modalVisibility: true })} disabled={reqPending} block variant="success"><i className="mdi mdi-plus mr-1"></i>Adicionar Grupo</Button>
                        {
                            (groupsAccesses.length !== 0 && groupsAccesses[idxCurrGroup()].groupId !== 1)
                            &&
                            (
                                <>
                                    {confirmDelGroup ? (
                                        <div className="mt-3 text-center animated fadeInUp faster">
                                            <small>Confirmar exclusão do grupo</small>
                                            <ButtonGroup className="d-flex mt-2">
                                                <Button className="font-12 p-1 shadow-none" variant="success" disabled={reqPending} onClick={deleteUserGroup}>Sim</Button>
                                                <Button className="font-12 p-1 shadow-none" variant="danger" onClick={() => setConfirmDelGroup(false)}>Não</Button>
                                            </ButtonGroup>
                                        </div>
                                    ) : (
                                        <Button type="button" onClick={() => setConfirmDelGroup(true)} variant="danger" block className="font-12 shadow-none"><i className="mdi mdi-delete mr-1"></i>Deletar Grupo</Button>
                                    )}
                                </>
                            )
                        }
                    </div>
                </Col>
                <Col md="10">
                    <Row className={`accesses-columns${reqPending?' loading':''}`}>
                        <Col md="12" lg="6">
                            <h4 className="mb-1 text-center"><i className="mdi mdi-asterisk mr-1"></i>Acessos Específicos</h4>
                            <p className="text-center mb-3 font-10">Específicos para processos de liberações, alterações, exclusões.</p>
                            <div>{
                                groupsAccesses.length !== 0
                                ?
                                    <div>
                                        <ListGroup className="list-accesses-specifics">
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center border-0 mb-1">
                                                <div className="d-flex align-items-center">
                                                    <span className="pl-1 mr-2 text-muted">ID</span>
                                                    <Form.Control
                                                        type="text"
                                                        size="sm"
                                                        className="font-12 p-1 border-0"
                                                        placeholder="Pesquisar Acessos"
                                                        onChange={e => {
                                                            let txt = e.currentTarget.value;
                                                            let acc = groupsAccesses;
                                                            let idx = idxCurrGroup();
                                                            acc[idx].specifics = acc[idx].specifics.map(x => {
                                                                x.filtered = zFilter(txt, x);
                                                                return x;
                                                            });
                                                            setGroupsAccesses([ ...acc ]);
                                                        }}
                                                    />
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" checked={isAllChecked('specifics')} onChange={e => toggleAllAllowedAccesses('specifics', e)} className="custom-control-input" id="dhabs723t4t62" />
                                                    <label className="custom-control-label" htmlFor="dhabs723t4t62">{" "}</label>
                                                </div>
                                            </ListGroup.Item>
                                            {groupsAccesses[idxCurrGroup()]
                                            .specifics
                                            .filter(x => typeof x.filtered === "undefined" || x.filtered === true)
                                            .map(($item, idx) => (
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center" key={idx}>
                                                <div className="ml-1"><span className="d-inline-block mr-1 text-muted" style={{width:25}}>{$item.Id}</span>{$item.Name}</div>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" onChange={e => toggleAllowedAccess('specifics', $item, e)} checked={['SIM','S'].indexOf($item.Allow) !== -1} className="custom-control-input" id={btoa(unescape(encodeURIComponent($item.Name)))} />
                                                    <label className="custom-control-label" htmlFor={btoa(unescape(encodeURIComponent($item.Name)))}>{" "}</label>
                                                </div>
                                            </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </div>
                                :
                                    <div className="text-center mt-5 font-12 text-muted">Selecione um grupo</div>
                            }</div>
                        </Col>
                        <Col md="12" lg="6">
                            <h4 className="mb-1 text-center"><i className="mdi mdi-window-restore mr-1"></i>Acessos Formulários</h4>
                            <p className="text-center mb-3 font-10">Permite acessos a telas de formulários para cadastros, relatórios, configurações.</p>
                            <div>{
                                groupsAccesses.length !== 0
                                ?
                                    <div>
                                        <ListGroup className="list-accesses-forms">
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center border-0 mb-1">
                                            <div className="d-flex align-items-center">
                                                    <span className="pl-1 mr-2 text-muted">ID</span>
                                                    <Form.Control
                                                        type="text"
                                                        size="sm"
                                                        className="font-12 p-1 border-0"
                                                        placeholder="Pesquisar Acessos"
                                                        onChange={e => {
                                                            let txt = e.currentTarget.value;
                                                            let acc = groupsAccesses;
                                                            let idx = idxCurrGroup();
                                                            acc[idx].forms = acc[idx].forms.map(x => {
                                                                x.filtered = zFilter(txt, x);
                                                                return x;
                                                            });
                                                            setGroupsAccesses([ ...acc ]);
                                                        }}
                                                    />
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" checked={isAllChecked('forms')} onChange={e => toggleAllAllowedAccesses('forms', e)} className="custom-control-input" id="asdkjhi2u3" />
                                                    <label className="custom-control-label" htmlFor="asdkjhi2u3">{" "}</label>
                                                </div>
                                            </ListGroup.Item>
                                        {groupsAccesses[idxCurrGroup()]
                                        .forms
                                        .filter(x => typeof x.filtered === "undefined" || x.filtered === true)
                                        .map(($item, idx) => (
                                            <ListGroup.Item className="d-flex justify-content-between align-items-center" key={idx}>
                                                <div className="ml-1"><span className="d-inline-block mr-1 text-muted" style={{width:25}}>{$item.Id}</span>{$item.Name}</div>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" onChange={e => toggleAllowedAccess('forms', $item, e)} checked={['SIM','S'].indexOf($item.Allow) !== -1} className="custom-control-input" id={btoa(unescape(encodeURIComponent($item.Name)))} />
                                                    <label className="custom-control-label" htmlFor={btoa(unescape(encodeURIComponent($item.Name)))}>{" "}</label>
                                                </div>
                                            </ListGroup.Item>
                                        ))}
                                        </ListGroup>
                                    </div>
                                :
                                    <div className="text-center mt-5 font-12 text-muted">Selecione um grupo</div>
                            }</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal centered show={addGroup.modalVisibility} onHide={() => dispatchAddGroup({ ...addGroup, modalVisibility: false })}>
                <Modal.Body>
                    <h3 className="text-center">Adicionar Grupo</h3>
                    <div className="px-4 mt-4 d-flex justify-content-center">
                        <Form.Control
                            type="text"
                            maxLength="30"
                            className="font-16 font-weight-light py-3"
                            placeholder="Nome do Grupo"
                            value={addGroup.name}
                            disabled={addGroup.requesting}
                            onChange={e => {
                                let name = e.currentTarget.value.replace(/[^A-Za-z\s]+/g, '').substr(0, 30).toUpperCase();
                                dispatchAddGroup({ ...addGroup, name });
                            }}
                        />
                    </div>
                    <div className="mt-3 text-center">
                        <Form.Group controlId="formBasicChecbox">
                            <Form.Check type="checkbox" checked={addGroup.allowAll} onChange={e => dispatchAddGroup({ ...addGroup, allowAll: e.currentTarget.checked })} label="Permitir todos os acessos" />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <div className="d-flex justify-content-between px-3 pb-3">
                    <Button variant="link" disabled={addGroup.requesting} className="text-danger" onClick={() => dispatchAddGroup({ ...addGroup, modalVisibility: false })}>
                        Agora não!
                    </Button>
                    <Button variant="success" disabled={addGroup.requesting} className="shadow-none" onClick={newGroup}>
                        {!addGroup.requesting ? <><i className="mdi mdi-check mr-1"></i>Adicionar</> : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
                    </Button>
                </div>
            </Modal>
            <Modal centered show={usersLkdGroup.modalVisibility} onHide={() => dispatchUsersLkdGroup({ ...usersLkdGroup, modalVisibility: false })}>
                <Modal.Body className="text-center pb-3">
                    <div className="mb-2">
                        <i style={{fontSize:'28px'}} className="dripicons-warning text-danger"></i>
                    </div>
                    <div className="mb-2 font-11 text-muted">GRUPO</div>
                    <h3 className="mb-2">{usersLkdGroup.group.Group || ''}</h3>
                    <p className="font-12">Não é possível deletar o grupo, pois {usersLkdGroup.users.length > 1 ? 'os seguintes usuários estão vinculados.':'existe um usuário vinculado.'}</p>
                    <div className="my-3 px-2">
                        <ListGroup>
                            {usersLkdGroup.users.map(($User, idx) => (
                            <ListGroup.Item key={idx} className="d-flex justify-content-between">
                                <div><span className="mr-2 text-muted">{$User.Id}</span>{$User.Name}</div>
                                <div></div>
                            </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                    <Button size="sm" onClick={() => dispatchUsersLkdGroup({ ...usersLkdGroup, modalVisibility: false })} variant="secondary">Ok, entendi.</Button>
                </Modal.Body>
            </Modal>
        </div>
    )
}