import React from 'react'

import { Switch, Route }    from 'react-router-dom'

import { Container }        from 'react-bootstrap'

import UsersList    from './UsersList'
import UserAdd      from './UserAdd'
import UserLook     from './UserLook'

export default function Users() {

    return (
        <Container id="users">
            <h2 className="text-center mt-4">Usuários</h2>
            <div>
                <Switch>
                    <Route exact path="/users/" component={UsersList} />
                    <Route path="/users/add" component={UserAdd} />
                    <Route path="/users/:id/look" component={UserLook} />
                </Switch>
            </div>
            
        </Container>
    );
}