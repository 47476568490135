import React from 'react'

import { Row, Col, Badge, Table } from 'react-bootstrap'

import { realCurrency } from 'helpers/functions'


export default function TableSalesYear({ data, year, _ }) {
  
	if (!data || !data.length) {
		return null;
	}

	const total  = data.reduce((x, y) => y.Total + x, 0);
	const tmv    = data.reduce((x, y, idx, arr) => {
		let lm = arr.findIndex(z => z.Month === y.Month-1);
		if (y.Month > 1 && y.Total > 0 && arr[lm].Total > 0) {
			return x + (
				(
					(
						y.Total-arr[lm].Total
					)/arr[lm].Total
				)*100
			);
		} else {
			return x;
		}
	}, 0);
	
	const f6m    = [...data].sort((x,y) => x.Month > y.Month ? 1 : x.Month < y.Month ? -1 : 0).splice(0,6);
	const l6m    = [...data].sort((x,y) => x.Month > y.Month ? 1 : x.Month < y.Month ? -1 : 0).slice(6,12);


	const TrTd = ({ month, monthName, value }) => {
		const lastMonth = data.filter(i => i.Month === (month-1))[0];
		let pvL = 0;

		if (lastMonth && lastMonth.Total > 0) {
			pvL = ((value-lastMonth.Total)/lastMonth.Total)*100;
		}
		
		return (
			<tr>
				<td>
					<span className={_.cMonth === (month-1) ? 'font-weight-bold text-dark':''}>{monthName}</span>
					<div className="float-right">
					{realCurrency(value)}
					{(month > 1 && value > 0 && lastMonth.Total > 0) && (
						<Badge variant={pvL > 0 ? 'success' : 'danger'} className="ml-1">
							<i className={`mr-1 mdi mdi-arrow-${pvL>0?'up':'down'}-bold`}></i>{pvL.toFixed(1)}%
						</Badge>  
					)}
					<Badge variant="light" className={`ml-1${value>0?'':' px-2'}`}>
						{value > 0 ? ((value/total)*100).toFixed(1) + '%' : '-'}
					</Badge>
					</div>
				</td>
			</tr>
		)
	};

	return (
		<>
		<Row>
			<Col md="6">
				<Table className="font-12 mb-1" size="sm">
					<tbody>
						{f6m.map(x => (
							<TrTd key={x.Month} month={x.Month} monthName={x.MonthName} value={x.Total} />
						))}
					</tbody>
				</Table>
			</Col>
			<Col md="6">
				<Table className="font-12 mb-1" size="sm">
					<tbody>
						{l6m.map(x => (
							<TrTd key={x.Month} month={x.Month} monthName={x.MonthName} value={x.Total} />
						))}
					</tbody>
				</Table>
			</Col>
		</Row>
		<div className="d-flex justify-content-between align-items-center">
			<div>
				<div>
					<Badge variant="light">%</Badge>{" "}<small>Percentual equivalente ao valor total.</small>
				</div>
				<div>
					<Badge variant="success"><i className="mdi mdi-arrow-up-bold"></i></Badge>
					<Badge variant="danger"><i className="mdi mdi-arrow-down-bold"></i></Badge>{" "}
					<small>Variação percentual em relação ao mês anterior.</small>
				</div>
			</div>
			<div>
				<h6 className="text-right font-weight-normal">
					VARIAÇÃO
					<Badge variant={`${tmv > 0 ? 'success':'danger'}-lighten`} className="ml-2 font-10">{tmv.toFixed(2)}%</Badge>
				</h6>
				<h5 className="text-right font-weight-light">
					MÉDIA MENSAL
					<b className="ml-2 font-weight-bold">{realCurrency(total / data.filter(m => m.Total > 0).length)}</b>
				</h5>
				<h4 className="text-right font-weight-light">
					TOTAL EM {year}
					<b className="ml-2 font-weight-bold">{realCurrency(total)}</b>
				</h4>
			</div>
		</div>
		<hr />
		</>
	);
}