import React, {useState, useEffect, useRef} from 'react';
import api from "services/api";
import Cookies from "helpers/cookies";
import {Container, Row, Col, Card, Form, InputGroup, Button} from 'react-bootstrap';

export default function StageLogin({setStage, userCtrl}) {

	const UserInput = useRef(null);
	const PasswordInput = useRef(null);
	const [StateLogin, SetStateLogin] = useState({type: 'success', text: 'Acessar', animate: ''});
	const [LoginRequestPending, SetLoginRequestPending] = useState(false);

	async function sendLoginRequest() {
		try {
			const {data} = await api.post('/auth', {
				user: UserInput.current.value,
				password: PasswordInput.current.value
			});

			// Account Verification
			if (!data.user.verified) {
				exitTo('ConfirmAccount', data);
				return;
			}

			Cookies.set(
				process.env.REACT_APP_COOKIE_AUTH,
				data.session.token,
				new Date(data.session.expire),
				'/',
				process.env.REACT_APP_COOKIE_DOMAIN
			);

			// Select Module
			if (!data.modules || !data.modules.active) {
				exitTo('SelectModule', data);
				return;
			}

			if (data.ready.instance && data.ready.companies) {

				setTimeout(() => {
					userCtrl.setUser({
						...data.user,
						session: {
							ready: {instance: true, companies: true},
							instances: data.instances,
							module: data.module
						}
					});
					userCtrl.setLogged(true);
				}, 800);

				window.$('.bg-gradient-pglogin').toggleClass('fadeIn fadeOut');
				window.$('.stage.login .auth.login').toggleClass('fadeInUp fadeOutDown');
				window.$('.stage.login .links').toggleClass('fadeInUpBig fadeOutDown');

			} else {
				if (data.instances.length === 1) {
					exitTo('SelectCompaniesInstance', {InstanceName: data.instances[0].Name, forback: data});
				} else if (data.ready.instance) {
					exitTo('SelectCompaniesInstance', {
						InstanceName: data.instances.filter(s => s.Selected === true)[0].Name,
						forback: data
					});
				} else {
					exitTo('SelectInstance', data);
				}
			}
			return; // prevent update state SetLoginRequestPending
		} catch (error) {
			if (error.response) {
				let {status, message} = error.response.data.error;

				let i = ['FieldsMissing', 'UserInvalid', 'UserNotFound', 'UserPasswordNotMatch', 'UserTypeUnprocessable', 'TryExcept'].indexOf(status);
				let m = ['Preencha os dados', 'Dados inválidos', 'Usuário inválido', 'Senha incorreta', 'Tipo de usuário inválido', 'Erro no servidor!!!'][i];

				PasswordInput.current.value = '';

				SetStateLogin({
					type: 'danger',
					text: m,
					animate: 'shake'
				});
				if (message !== '') {
					console.error(message);
				}
			}
		}
		SetLoginRequestPending(false);
	}

	function formLoginSubmit(e) {
		e.preventDefault();
		if (LoginRequestPending) return;
		if (UserInput.current.value.length < 3) {
			UserInput.current.focus();
			return;
		}
		if (PasswordInput.current.value.length < 4) {
			PasswordInput.current.focus();
			return;
		}
		SetStateLogin({
			type: 'success',
			text: 'Acessar',
			animate: ''
		});
		SetLoginRequestPending(true);
		sendLoginRequest();
	}

	function exitTo(stage, data = {}) {
		window.$('.stage.login .card.login').toggleClass('fadeInDown fadeOutUp');
		window.$('.stage.login .links').toggleClass('fadeInDownBig fadeOutUpBig');
		setTimeout(() => {
			setStage({to: stage, data});
		}, 400);
	}

	useEffect(() => {
		let $stage = window.$('.stage.login');
		let heightTo = (window.innerHeight - $stage.height()) / 2;
		$stage.css('margin-top', heightTo < 0 ? 10 : heightTo + 'px');
	}, [])

	return (
		<Container className="stage login">
			<Row className="justify-content-center">
				<Col md="6" lg="4">
					<Card className="shadow-none auth login fadeInDown faster animated">
						<Card.Body>
							<div className="card-header text-center border-0">
								<h1 className="text-dark">Orul</h1>
							</div>
							<Form className="mb-2" onSubmit={formLoginSubmit}>
								<InputGroup size="sm" className="mb-3">
									<InputGroup.Prepend>
										<InputGroup.Text id="inputGroup-sizing-sm"><i
											className="mdi mdi-account-outline"></i></InputGroup.Text>
									</InputGroup.Prepend>

									<Form.Control
										type="text"
										name="user"
										autoComplete="off"
										spellCheck={false}
										placeholder="Usuário, E-mail ou Celular"
										ref={UserInput}
									/>

								</InputGroup>

								<InputGroup size="sm" className="mb-2">
									<InputGroup.Prepend>
										<InputGroup.Text id="inputGroup-sizing-sm"><i
											className="mdi mdi-lock-outline"></i></InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										type="password"
										name="password"
										defaultValue=""
										placeholder="Senha"
										ref={PasswordInput}
									/>
								</InputGroup>
								<br/>
								<div className="mx-2">
									<Button
										type="submit"
										className={`shadow ${StateLogin.animate !== '' ? ' ' + StateLogin.animate + ' animated' : ''}`}
										block
										style={{borderRadius: '0px 16px'}}
										disabled={LoginRequestPending}
										variant={LoginRequestPending ? 'success' : StateLogin.type}
										onMouseEnter={e => {
											if (!LoginRequestPending && StateLogin.type === 'danger') {
												e.target.innerHTML = 'Tentar novamente?'
											}
										}}
										onMouseLeave={e => {
											if (!LoginRequestPending && StateLogin.type === 'danger') {
												e.target.innerHTML = StateLogin.text
											}
										}}
									>{
										LoginRequestPending
											?
											(
												<span className="spinner-border spinner-border-sm text-white" role="status"
															aria-hidden="true"></span>
											)
											:
											StateLogin.text
									}</Button>
								</div>
							</Form>
						</Card.Body>
					</Card>
					<p className="text-center links fadeInDownBig faster animated">
						<Button className="font-12" variant="link" onClick={() => exitTo('Register')}>Cadastre-se</Button>
						<Button className="font-12" variant="link" onClick={() => exitTo('ResetPassword')}>Esqueceu sua
							senha?</Button>
					</p>
				</Col>
			</Row>
		</Container>
	);
}