import React, { useEffect } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Navigation, CustomRoutes } from './pages';
import Navbar from './components/navbar';

import './style.css';

export default function SettingsModule(ps) {

    
    const { user, setUser, logoutSession, sync } = ps;
    
    useEffect(() => {
        window.$('body').attr('data-layout', 'topnav');

        window.$.App.init();

        return () => window.$('body').removeAttr('data-layout');
    }, [])

    return (
        <div id="settings" className="wrapper fadeIn animated">
            <Router>
            <div>
                <div className="content-page">
                    <div className="content">
                        <Navbar
                            user={user}
                            setUser={setUser}
                            logoutSession={logoutSession}
                            Navigation={Navigation}
                            // synchronize={synchronize}
                        />
                        <Container fluid={true}>
                        <Switch>
                        {/* Routes by Navigation links */}
                        {Navigation.map((item, idx) => 
                            item.items.length > 0
                            ? item.items.map((litem, lidx) =>
                                <Route key={lidx} exact={litem.exact} path={litem.link} render={props => (
                                    <litem.component route={props} sync={sync} />
                                )} />  
                            )
                            : <Route key={idx} exact={item.exact} path={item.link} render={props => (
                                <item.component route={props} sync={sync} />
                            )} />
                        )}
                        {/* Custom Routes */}
                        {CustomRoutes.map((rt, idx) => (
                            <Route key={idx} exact={rt.exact} path={rt.link} render={props => (
                                <rt.component route={props} sync={sync} />
                            )} /> 
                        ))}
                        {/* Page not found */}
                        <Route component={() => (
                            <Row className="justify-content-center mt-5">
                                <Col lg="4">
                                    <div className="text-center">
                                        <img src="assets/images/file-searching.svg" height="90" alt="Página não encontrada" />                                        
                                        <h4 className="text-uppercase text-danger mt-3">Página não encontrada</h4>
                                        <p className="text-muted mt-3">A página que você tentou acessar não foi encontrada ou não está mais disponível. Verifique a URL e tente novamente ou clique no botão abaixo.</p>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        />
                        </Switch>
                        </Container>
                    </div>
                </div>
            </div>
            </Router>
        </div>
    )
}