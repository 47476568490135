import React, { useState, useEffect } from 'react'

import { Route, Switch, Link, Redirect } from 'react-router-dom'

import api from 'services/api'

import { Row, Col, Spinner, Button } from 'react-bootstrap'

import TargetsControl from './TargetsControl'

import undrawVisualData from 'assets/img/undraw_visual_data_b1wx.svg'
import undrawCancel from 'assets/img/undraw_cancel_u1it.svg'

/**
 * Render info if company key does not match in uri
 */
function CompanyKeyDoesNotMatch() {

	return (
		<div>
			<img src={undrawCancel} alt="" className="my-4" width="350" />
			<h3 className="my-3">Tem algo errado! &nbsp;&nbsp; :/</h3>
			<p>Talvez você tenha acessado está página de modo errado ou ocorreu um erro e não sabemos o que aconteceu.</p>
			<p>De qualquer forma, tente novamente, e se este erro persistir, entre em contato com a gente ok?!</p>
			<Link to="/">Ir para página inicial</Link>
		</div>
	)
}

/**
 * Unordered list of companies
 * 
 * @param {stirng} cUrl Current path location
 * @param {object} companies
 */
function ListOfCompanies({ cUrl, companies }) {

	const [inactive, setinactive] = useState(false);

	const { loading, list } = companies;

	if (list.length === 1) {
		return (
			<Redirect to={`${cUrl}/${list[0].Key}`} />
		);
	}

	return (
		<Row>
			<Col>
				{
					loading ? (
						<Spinner animation="border" variant="dark" />
					) : (
						<div>
							<p>
								Selecione uma empresa para analisar as metas de vendas.
							</p>
							<div>
								{list.length > 0 ? (
									<>
									<ul className="font-weight-bold">
									{list.filter(item => item.Inactive === false || inactive).map(item => (
									<li key={item.Id}>
										<Link 
											to={`${cUrl}/${item.Key}`}
											className={`text-${item.Inactive === true ? 'muted':'primary'}`}
										>
											{item.Name}
										</Link>
									</li>
									))}
									</ul>
									{list
										.filter(item => item.Inactive === true)
										.length > 0 && (
											<Button variant="link" size="sm" onClick={() => setinactive(!inactive)} className="font-12 text-muted">{inactive?'Esconder':'Exibir'} empresas inativas</Button>	
										)
									}
									</>
								) : (
									<i>Nenhuma empresa disponível no momento.</i>
								)}
							</div>
						</div>
					)
				}
			</Col>
			<Col md="4">
				<img src={undrawVisualData} className="img-fluid" alt="" />
			</Col>
		</Row>
	);
}

/**
 * Render the component TargetsControl viewer
 * 
 * @param {object} route RouteComponentProps
 * @param {object} companies
 */
function RenderTargetsControlOrNot({ route, companies }) {

	const { loading, list } = companies;
	const companyKey 				= route.match.params.companyKey;
	const companyIdx 				= list.findIndex(com => com.Key === companyKey);

	if (loading) {
		return (
			<Spinner animation="border" variant="dark" />
		);
	}

	if (companyIdx === -1) {
		return (
			<CompanyKeyDoesNotMatch />
		);
	}

	return (
		<TargetsControl company={list[companyIdx]} companies={companies} />
	);
}

export default function SalesTargets({ route }) {

	const [companies, setCompanies] = useState({
		loading: true,
		list: []
	})


	async function getCompanies() {
		try {
			const response = await api.get('/auth/session/instance/companies');

			const list = response.data.map(x => {
				x.Key = x.Name.normalize('NFD').replace(/[\u0300-\u036f-]|[^\w\s]/gi, "").replace(/[\s]/g,'-').toLowerCase();
				x.Inactive = x.Inactive === 'S';
				return x;
			});
			
			setCompanies({
				loading: false,
				list
			});

		} catch (err) {
			// if (err.response) {
			// 	if (err.response.status === 500) {
			// 		alert('Erro interno! Não foi possível obter a lista de empresas.');
			// 	}
			// }
			setCompanies({ loading: false, list: [] });
		}
	}

	useEffect(() => {

		getCompanies();

	}, [])

	return (
		<div id="sales__targets">
			<h3 className="text-dark">Metas de Vendas</h3>
			<Switch>
				
				<Route 
					path={route.match.url} 
					exact 
					component={
						() => (
							<ListOfCompanies
								cUrl={route.match.url} 
								companies={companies} 
							/>
						)
					} 
				/>

				<Route 
					path={route.match.url + '/:companyKey'}
					component={
						(__route) => (
							<RenderTargetsControlOrNot 
								route={__route}
								companies={companies}
							/>
						)
					}
				/>

			</Switch>
		</div>
	);
}