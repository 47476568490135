import React, { useEffect, useState } from 'react'

import { Row, Col, Badge } from 'react-bootstrap'

import api from 'services/api'

import './style.css'

export default function Instances(){

    const [ListInstances, SetListInstances] = useState(null)

    async function getListOfInstances() {

        try {
            const { data } = await api.get('/settings/instances');
            SetListInstances(data);
        } catch (err) {
            console.log(err);
        }

    }

    async function setImageInstance(e, Instance) {
        let image = e.currentTarget.files[0];
        const data = new FormData();

        data.append('instance', Instance.GUID);
        data.append('image', image, image.filename);

        if (image) {
            try {
                const response = await api.instance().post('/settings/instances/image', data,{ headers: { 'Content-Type': 'multipart/form-data' } });
                SetListInstances([...ListInstances.map(g => {
                    if (g.GUID === Instance.GUID) {
                        g.Brand = response.data.filename;
                    }
                    return g;
                })]);
            } catch (err) {
                if (err.response) {
                    
                }
            }
        }
    }

    useEffect(() => {
        getListOfInstances();
        return () => api.cancelAllRequests();
    }, []);

    return  (
        <div id="instances">
            <Row>
                <Col>
                    <div className="page-title-box">
                        <h2 className="my-3 text-center">Intâncias</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                {
                    ListInstances !== null ?
                        <>
                        {ListInstances.map( Instance => (
                            <Col key={Instance.GUID} md="4">
                                <div className="card shadow-none">
                                    <div className="card-body">
                                            <div className="text-center mb-2 picture">
                                                <img 
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        window.$(`input[name="instance-${Instance.GUID}-image"]`).trigger('click');
                                                    }} 
                                                    src={Instance.Brand !== null ? `${process.env.REACT_APP_API_STORAGE_BASEURL}/instances/brands/${Instance.Brand}` : process.env.PUBLIC_URL + '/assets/images/database.png'} 
                                                    height="94" 
                                                    alt="" 
                                                />
                                                <div>
                                                    <input
                                                        className="d-none"
                                                        accept="image/png"
                                                        type="file"
                                                        name={`instance-${Instance.GUID}-image`}
                                                        onChange={e => setImageInstance(e, Instance)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                <h4 className="mt-1 mb-1">{Instance.Name}</h4>
                                                <p className="font-13">
                                                    <Badge className="rounded-pill px-2" variant={Instance.Active ? 'success-lighten' : 'danger-lighten'}>{Instance.Active ? 'Ativo' : 'Inativo'}</Badge>
                                                </p>

                                                <ul className="mb-0 list-inline">
                                                    <li className="list-inline-item">
                                                        {/* <strong className="mb-1">DB</strong> */}
                                                        <p className="mb-0 font-13">{Instance.Db}</p>
                                                    </li>
                                                </ul>
                                                {/* <div className="mt-3">
                                                    <Button variant="info" size="sm" className="rounded-pill font-10"><i className="mdi mdi-settings"></i> Gerenciar</Button>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <Col sm="4">
                                            <div className="text-center mt-sm-0 mt-3 text-sm-right">
                                                <Button variant="link" className="text-muted"><i className="mdi mdi-database-edit mr-1"></i> Editar</Button>
                                            </div>
                                        </Col> */}
                                </div>
                            </Col>
                        ))}
                        </>
                    :
                    <Col>
                        <div className="d-flex justify-content-center mt-5">
                            <div className="spinner-border avatar-md m-2" role="status"></div>
                        </div>
                    </Col>
                }
            </Row>
        </div>
    );
}