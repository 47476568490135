import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import api from 'services/api'

export default function StageRegister({ setStage }) {

    // const [MsgResp, SetMsgResp]                 = useState(null);
    const [requestPending, setRequestPending]   = useState(false);
    const [frmIdx, setfrmIdx]                   = useState(0);

    const [frm, setfrm] = useState([
        {
            name: 'name',
            title: 'Meu nome é',
            icon: 'mdi mdi-account-outline',
            submit: (event, els, index) => {
                event.preventDefault();
                let name = els[0].value;
                if (name.trim().split(' ').length === 1) {
                    displayErrorFrm(index, 0, 'Digite seu nome completo, por favor!', 'danger');
                    return;
                } else if (!/^[a-zA-Z\u00C0-\u017F\s]+$/.test(name)) {
                    displayErrorFrm(index, 0, 'Este é seu nome mesmo? Digite apenas letras...', 'danger');
                    return;
                }
                frmContinue(index+1);
            },
            els: [ { id: 'name',  type: 'text', value: '', placeholder: 'Qual seu nome?' } ]
        },
        {
            name: 'username',
            title: 'Meu usuário',
            icon: 'mdi mdi-account-circle-outline',
            submit: async (event, els, index) => {
                event.preventDefault();
                let username = els[0].value;
                if (username.length < 3) {
                    displayErrorFrm(index, 0, 'Nome de usuário deve conter no mínimo 3 letras!', 'danger');
                    return;
                }
                if (!/^[a-zA-Z0-9]+$/.test(username)) {
                    displayErrorFrm(index, 0, 'Nome de usuário inválido!', 'danger');
                    return;
                }
                if (!isNaN(username)) {
                    displayErrorFrm(index, 0, 'Nome de usuário não pode conter somente números!', 'danger');
                    return;
                }
                if (!isNaN(username[0])) {
                    displayErrorFrm(index, 0, 'Nome de usuário não pode iniciar com número!', 'danger');
                    return;
                }
                if (username.length > 25) {
                    displayErrorFrm(index, 0, 'Nome de usuário deve conter no máximo 25 caracteres!', 'danger');
                    return;
                }
                setRequestPending(true);
                try {
                    await api.get('/users/checkUsername', { username });
                    frmContinue(index+1);
                } catch (error) {
                    displayErrorFrm(index, 0, 'Nome de usuário indisponível!', 'danger');
                }
                setRequestPending(false);
            },
            els: [ { id: 'username',  type: 'text', value: '', attributes: { maxLength: 25 }, placeholder: 'Seu nome de usuário...', description: 'Nome de usuário é único, você usará para acessar o sistema.' } ]
        },
        {
            name: 'email',
            title: 'Meu e-mail',
            icon: 'mdi mdi-email-outline',
            submit: (event, els, index) => {
                event.preventDefault();
                let email = els[0].value;
                if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                    displayErrorFrm(index, 0, 'E-mail inválido!', 'danger');
                    return;
                }
                frmContinue(index+1);
            },
            els: [ { id: 'email',  type: 'email', value: '', placeholder: 'Seu e-mail...', description: 'Podemos enviar informações importantes, mas nada de spam ;)' } ]
        },
        {
            name: 'cellphone',
            title: 'Meu número',
            icon: 'mdi mdi-cellphone',
            afterRender: () => {
                let $el = window.$('#register form input');

                $el.mask('+55 (00) 00000-0000');
                return () => {
                    $el.unmask();
                };
            },
            submit: (event, els, index) => {
                event.preventDefault();
                let phone = els[0].value;
                if (phone.replace(/[^0-9]+/, '').length < 7) {
                    displayErrorFrm(index, 0, 'Digite um número de celular válido!', 'danger');
                    return;
                }
                frmContinue(index+1);
            },
            els: [ 
                { 
                    id: 'cellphone', 
                    type: 'text',
                    value: '',
                    placeholder: '(DDD) 9 + Número',
                    actions: [
                        { 
                            title: 'Tenho um número internacional!',
                            attributes: {
                                size: 'sm',
                                variant: 'warning',
                                className: 'shadow-none font-12 rounded-pill',
                                onClick: () => {
                                    let $el = window.$('#register form input');
                                    $el.unmask().mask("0000000000000000").val('').attr('placeholder', 'Código do país + Número');
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'password',
            title: 'Minha senha',
            icon: 'mdi mdi-lock-outline',
            submit: (event, els, index) => {
                event.preventDefault();
                let inputPassword = els[0].value;
                let inputConfirmPassword = els[1].value;
                if (inputPassword === '') {
                    displayErrorFrm(index, 0, 'Digite sua senha!', 'danger');
                    return;
                } else if (inputConfirmPassword === '') {
                    displayErrorFrm(index, 0, 'Confirme sua senha!', 'danger');
                    return;
                } else if (inputPassword.length < 4) {
                    displayErrorFrm(index, 0, 'Senha muito curta! Mínimo requerido é de 4 letras/números', 'danger');
                    return;
                } else if (inputPassword !== inputConfirmPassword) {
                    displayErrorFrm(index, 0, 'As senhas não conferem!', 'danger');
                    return;
                }

                register();
            },
            els: [ { id: 'password', type: 'password', value: '', placeholder: 'Senha', description: 'Seus dados mais sensíveis seram criptografadas.' }, { id: 'confirmapassword',  type: 'password', value: '', placeholder: 'Confirmar Senha' } ]
        },
        {
            name: 'accountConfirmCode',
            title: 'Código de Confirmação',
            icon: 'mdi mdi-shield-check',
            submit: async (event, els, index) => {
                event.preventDefault();
                if (requestPending) return;
                let code = els[0].value;
                let user = getUserCredentials();
                setRequestPending(true);
                try {
                    await api.post('/users/confirm', { process: 'confirm', credentials: user, master: window.accountConfirmCode, code: code });
                    
                    setStage({
                        to: 'Message',
                        data: {
                            type: 'white',
                            animate: 'pulse',
                            color: 'dark',
                            icon: 'checkmark',
                            title: 'Conta Verificada',
                            description: () => (
                                <>
                                    {user.name.split(' ')[0]}, sua conta foi verificada com sucesso! Obrigado :)
                                    <br />
                                    <p className="mt-3">
                                        <Button variant="success" className="shadow-none" onClick={() => setStage({ to: '', data: {} })}>Acessar</Button>
                                    </p>
                                </>
                            )
                        }
                    });
                    return;
                } catch (err) {
                    if (err.response) {
                        let status = err.response.data.status;
                        let msgbystatus = {
                            CodeMasterNotSet: 'Verificação não pode ser mais continuada, volte a tela de Acesso.',
                            CodeInvalid: 'Código incorreto.',
                            MasterTokenCodeExpired: 'O código desta verificação expirou. Volte a tela de Acesso.',
                            CodeNotMatch: 'Código incorreto.',
                            UserNotFound: 'Usuário não encontrado.',
                            ProcessNotAcceptable: 'Erro interno, não é possível continuar esta verificação.'
                        };
                        displayErrorFrm(5, 0, msgbystatus[status], 'danger');
                    } else {
                        alert('Erro no servidor, página será atualizada.');
                        window.location.reload();
                    }
                }
                setRequestPending(false);
            },
            els: [
                { id: 'accountConfirmCode', type: 'text', value: '', attributes: { maxLength: 6, style: {letterSpacing: 5} }, placeholder: 'Código de Confirmação', description: 'Enviamos um código para seu e-mail.' }
            ]
        }
    ]);

    function getUserCredentials() {
        let user = {};
        frm.slice(0, -1).forEach(element => {
            user[element.name] = element.els[0].value;
        });
        user.phone = user.cellphone.replace(/[^0-9]+/g, '');
        delete user.cellphone;
        return user;
    }

    async function register() {
        let user = getUserCredentials();

        setRequestPending(true);
        try {
            
            const User = await api.post('/users/register', user);
            const Confirm = await api.post('/users/confirm', { process: 'send', method: 'email', credentials: User.data.credentials });

            window.accountConfirmCode = Confirm.data.code;

            frmContinue(5);

        } catch (err) {
            if (err.response) {
                let data = err.response.data;
                if (data.status) {
                    displayErrorFrm(3, 0, data.status, 'danger');
                } else {
                    let message = '';
                    if (data.email && data.email.indexOf("AlreadyTaken") !== -1) {
                        message += 'E-mail já cadastrado. ';
                        displayErrorFrm(2, 0, message, 'danger');
                        frmContinue(2);
                    } else if (data.username && data.username.indexOf("AlreadyTaken") !== -1) {
                        message += 'Usuário já cadastrado. ';
                        displayErrorFrm(1, 0, message, 'danger');
                        frmContinue(1);
                    } else if (data.phone && data.phone.indexOf("AlreadyTaken") !== -1) {
                        message += 'Número já cadastrado. ';
                        displayErrorFrm(3, 0, message, 'danger');
                        frmContinue(3);
                    }
                }
            }
        }
        setRequestPending(false);
    }

    function frmContinue(to) {
        let $el = window.$('#register form input:first-of-type').parents('.box-control');
        $el.animate({
            top: -150,
            opacity: 0
        }, {
            duration: 300,
            easing: 'easeInBack',
            complete: () => {
                $el.css('top', 200);
                setfrmIdx(to);
                $el.animate({
                    top: 0,
                    opacity: 1
                }, {
                    duration: 300,
                    easing: 'easeInOutCubic',
                    complete: () => {
                        $el.removeAttr('style');
                    }
                });
            }
        });
    }

    function displayErrorFrm(frmIndex, targetEl, message, variant) {
        let _frm = frm;
        _frm[frmIndex].error = {
            targetEl: targetEl,
            variant: variant,
            message: message
        };
        setfrm([ ..._frm ]);
    }

    useEffect(() => {
        if (typeof window.tmpfn === "function") {
            window.tmpfn();
        }
        if (typeof frm[frmIdx].afterRender === "function") {
            window.tmpfn = frm[frmIdx].afterRender();
        }
    // eslint-disable-next-line
    }, [frmIdx])

    return (
        <Container id="register" className="animated fadeInDown fast">
            <Row className="justify-content-center">
                <Col md="11">
                    <div className="mt-3 title d-flex justify-content-between">
                        <h1>Olá</h1>
                        <h2>{frmIdx+1}/{frm.length}</h2>
                    </div>
                    <div className="mt-3">
                        <div>
                            <Button 
                                onClick={() => {
                                    window.$('#register').toggleClass('fadeInDown fadeOutUp');
                                    setTimeout(() => {
                                        setStage({ to: 'Login', data: {} });
                                    }, 480);
                                }}
                                variant="link" 
                                className="text-dark py-1 font-12"
                            >
                                <i className="mdi mdi-arrow-left-circle"></i> Acessar
                            </Button>
                        </div>
                        <Form 
                            onSubmit={e => requestPending ? e.preventDefault() : frm[frmIdx].submit(e, frm[frmIdx].els, frmIdx)}
                            className="mb-4"
                        >
                            <Row>
                                <Col md="10" className="pl-md-5 position-relative">
                                    <Row className="position-relative box-control">
                                    {
                                        frm[frmIdx].els.map((el, idy) => (
                                                <Form.Group key={idy} className={`position-relative col` + (frm[frmIdx].els.length > 1 ? '-md-6':'')} controlId={`frm-register-${el.id}`}>
                                                    {(el.value !== '' && idy === 0) && (
                                                        <Form.Label className="position-absolute fadeInLeft fast animated" style={{top:-25}}>{frm[frmIdx].title}</Form.Label>
                                                    )}
                                                    <Form.Control
                                                        type={el.type}
                                                        value={el.value}
                                                        onChange={e => {
                                                            let d = frm;
                                                                d[frmIdx].error = undefined;
                                                                d[frmIdx].els[idy].value = e.currentTarget.value;
                                                            setfrm([...d]);
                                                        }}
                                                        autoComplete="off" 
                                                        placeholder={el.placeholder}
                                                    />
                                                    {el.description && (
                                                        <Form.Text className="position-absolute text-dark">{el.description}</Form.Text>
                                                    )}
                                                    {el.actions && (
                                                        <div className="text-right mt-2">
                                                            {el.actions.map((action, idx) => (
                                                                <Button key={idx} {...action.attributes}>{action.title}</Button>
                                                            ))}
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            ))
                                    }
                                    </Row>
                                    <div className="mt-5">
                                        <div className="float-left">
                                            { (frm[frmIdx].error && frm[frmIdx].error.targetEl === 0) && (
                                            <Form.Text className={`shadow text-white position-absolute animated shake rounded-pill mt-1 bg-${frm[frmIdx].error.variant} py-1 px-2`}>
                                                {frm[frmIdx].error.message}
                                            </Form.Text>
                                            )}
                                        </div>
                                        {requestPending
                                            ?
                                            (
                                                <div className="float-right text-right">
                                                    <div className="spinner-border m-2 text-dark" role="status">
                                                        <span className="sr-only">Carregando...</span>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                            <div className="float-right text-right">
                                                <Button variant="link" type="submit" style={{borderRadius:'0px 16px'}} className="shadow text-white bg-dark font-15">
                                                    CONTINUAR
                                                </Button>
                                                <div className="mt-2 text-dark font-10 d-none d-md-block">ou pressione ENTER</div>
                                            </div>
                                            )
                                        }
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="frm-register-status text-center d-none d-md-block">
                                        <ul>
                                            {frm.map((item, idx) => (
                                                <li 
                                                    key={idx} onClick={() => {
                                                        if (frmIdx !== 4 && idx < frmIdx) {
                                                            frmContinue(idx);
                                                        }
                                                    }}
                                                    className={idx <= frmIdx ? 'active':''}
                                                >
                                                    <i className={item.icon}></i>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}