import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import { Row, Col, Card, ListGroup, Badge, Button, Form, Pagination } from 'react-bootstrap'

import api from 'services/api'

export default function UsersList() {

    const [users, setUsers] = useState(null);
    const [reqPending, setReqPending] = useState(false);
    const [usersFilters, setUsersFilters] = useState({});

    async function getAllUsers(page = 1) {
        setReqPending(true);
        const { data } = await api.get('/settings/users?page='+page, usersFilters);
        setUsers(data);
        animateListUsers();
        setReqPending(false);
    }
    
    function animateListUsers() {
        let $items = window.$('#users .list-users .list-group .list-group-item').addClass('invisible');
        let time = 1;
        window.$.each($items, (idx, $item) => {
            let $cr = window.$($item);
            setTimeout(() => {
                $cr.toggleClass('invisible fadeInUp animated faster');
            }, time);
            time += 65;
        });
        setTimeout(() => {
            $items.removeClass('fadeInUp animated faster');
        }, (time + 500));
    }

    function onSubmitFiltersUsers(e) {
        e.preventDefault();
        
        let data = {};
        window.$(e.currentTarget).serializeArray().forEach(le => {
            if (le.value !== "") {
                data[le.name] = le.value;
            }
        });

        if (Object.values(data).length === 0 && Object.keys(usersFilters).length === 0) return;
        setUsersFilters(data);
    }

    function RenderPagination({ data }) {

        if (data === null)          return <></>;
        if (data.last_page === 1)   return <></>;

        let active = data.current_page;
        let items = [];

        for (let number = 1; number <= data.last_page; number++) {
            items.push(
                <Pagination.Item onClick={() => getAllUsers(number)} key={number} active={number === active}>
                {number}
                </Pagination.Item>,
            );
        }

        return (
            <Pagination className="pagination-rounded">
                {items}
            </Pagination>
        )
    }

    useEffect(() => {
        
        getAllUsers();
    // eslint-disable-next-line
    }, [usersFilters])

    return (
        <Row className="mt-4">
            <Col xs={{ order: 2 }} md={{ order: 1, span: 3 }}>
                <div>
                    <div className="mt-3">
                        <Link to="/users/add" className="btn btn-success rounded-pill btn-block"><i className="mdi mdi-plus mr-1"></i> ADICIONAR</Link>
                    </div>
                    <div className="mt-4">
                        <p>Filtros</p>
                        <Form onSubmit={onSubmitFiltersUsers}>
                            <Form.Group controlId="asnd9827tbcd28c23">
                                <Form.Control type="text" autoComplete="off" name="name" placeholder="Nome" />                                
                            </Form.Group>
                            <Form.Group controlId="ih23897t263782c3">
                                <Form.Control type="text" name="access" autoComplete="off" placeholder="E-mail, usuário ou telefone" />
                            </Form.Group>
                            <div className="my-4">
                                <Form.Group controlId="78bxe7923yr87c2r">
                                    <Form.Check type="radio" name="status" value="verified" className="mb-2" label={<Badge variant="success-lighten" className="px-2 py-1 rounded-pill" size="sm"><i className="mdi mdi-shield-outline mr-1"></i>Verificado</Badge>} />
                                </Form.Group>
                                <Form.Group controlId="jan8d237yrb72893">
                                    <Form.Check type="radio" name="status" value="unverified" label={<Badge variant="danger-lighten" className="px-2 py-1 rounded-pill" size="sm"><i className="mdi mdi-shield-off-outline mr-1"></i>Não Verificado</Badge>} />
                                </Form.Group>
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button size="sm" variant="link" disabled={reqPending} className="text-dark border border-dark rounded-pill" type="submit">
                                    <i className={reqPending ? 'mdi mdi-spin mdi-loading mr-1': 'mdi mdi-account-search-outline mr-1'}></i>
                                    <span>Filtrar</span>
                                </Button>
                                <Button type="reset" disabled={Object.values(usersFilters).length === 0} variant="link" onClick={() => setUsersFilters({})} className="text-danger" size="sm">
                                    <i className="mdi mdi-backspace mr-1"></i>
                                    <span>Redefinir</span>
                                </Button>
                            </div>
                            {users !== null && (
                                <p className="mt-3 text-muted text-center">
                                    {users.total} usuário{users.total > 1 && 's'}.
                                </p>
                            )}
                        </Form>
                    </div>
                </div>
            </Col>
            <Col xs={{ order: 1 }} md={{ order: 2, span: 9 }}>
                <Card className="shadow-none list-users">
                    <Card.Body>
                        {
                            users === null
                            ?
                            (
                                <div className="text-center my-5">
                                    <div className="spinner-border avatar-lg text-dark m-2" role="status"></div>
                                </div>
                            ) : (
                                <div>
                                    {
                                        users.data.length > 0
                                        ?
                                        (
                                            <>
                                            <ListGroup>
                                                {users.data.map($User => (
                                                    <ListGroup.Item key={$User.Id} className="d-flex justify-content-between border-0 mb-3">
                                                        <div className="d-flex align-items-center">
                                                            {$User.ProfilePicture !== null ?
                                                                <img src={process.env.REACT_APP_API_STORAGE_BASEURL + '/users/images/pp/' + $User.ProfilePicture} width="64" height="64" className="rounded-circle shadow-sm mr-3 border border-white" alt="quixote" />
                                                            :
                                                                <div style={{width:64,height:64,lineHeight:'64px',fontSize:18,fontWeight:'bold'}} className="rounded-circle text-center text-dark d-none mr-3 d-sm-block shadow-sm">{$User.Name.substr(0, 2).toUpperCase()}</div>
                                                            }
                                                            <div className="flex-column">
                                                                <h4>{$User.Name}</h4>
                                                                <div style={{display:'grid'}}>
                                                                    <small>{$User.Username || <i className="text-muted">Sem usuário</i>}</small>
                                                                    <small>{$User.Phone || <i className="text-muted">Sem telefone</i>}</small>
                                                                    <small>{$User.Email || <i className="text-muted">Sem e-mail</i>}</small>
                                                                </div>
                                                                <Badge 
                                                                    variant={(parseInt($User.Verified) ? 'success': 'danger') + '-lighten'} 
                                                                    className="mt-2 px-2 py-1 rounded-pill" 
                                                                >
                                                                    <i className={`mdi mdi-shield${(parseInt($User.Verified) ? '': '-off')}-outline mr-1`}></i>
                                                                    {parseInt($User.Verified) ? 'Verificado': 'Não Verificado'}
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                        <div className="text-right mt-2 align-items-center d-flex">
                                                            <div>
                                                                <Link className="font-11 text-dark text-center" to={`/users/${$User.Id}/look`}>
                                                                    <i className="mdi font-13 mdi-square-edit-outline"></i>
                                                                    <span className="d-none d-sm-block">Perfil</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                            <div className="d-flex justify-content-center mt-4">
                                                <RenderPagination data={users} />
                                            </div>
                                            </>
                                        )
                                        :
                                        (
                                            <h1 className="text-center mt-5 text-muted font-weight-light">Nenhum usuário encontrado!</h1>
                                        )
                                    }
                                </div>
                            )
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}