import React, { useState, useEffect } from 'react'

import { Container, Row, Col, Card, ListGroup, Form, Button, Badge } from 'react-bootstrap'

import { removeAccentuation as rmac } from 'helpers/functions';

import Cookies from "helpers/cookies";

import api from 'services/api'


export default function StageSelectCompaniesInstance({ setStage, userCtrl, data: { InstanceName, forback } }) {

    const [Companies, SetCompanies] = useState(null);

    async function getCompanies() {
        try {
            const { data } = await api.get('/auth/session/instance/companies');
            if (data.length === 1) {
                data[0].selected = true;
            }
            SetCompanies(data);
            if (data.length === 1) {
                return;
            }
            setTimeout(() => {
                adjustStageMarginTop();
            }, 5);
        } catch (err) {
            if (err.response) {
                setStage({ 
                    to: 'Message', 
                    data: { 
                        type: 'danger', 
                        color: 'white', 
                        icon: 'wrong', 
                        title: 'Ops!', 
                        description: () => <>O servidor falhou ao tenta buscar as empresas da instância <b>{InstanceName}</b> com a seguinte mensagem: <b>{err.response.data.message}.</b></> 
                    }
                })
            }
        }
    }

    function logout() {
        api.delete('/auth').then(() => {
            Cookies.remove(
                process.env.REACT_APP_COOKIE_AUTH,
                '/',
                process.env.REACT_APP_COOKIE_DOMAIN
            );
        }).catch(() => {});
        setStage({ to: 'Login', data: {} });
    }

    // function toggleSelectionCompanies(d) {
    //     let tmp = Companies.map(item => {
    //         item.selected = d;
    //         return item;
    //     });
    //     SetCompanies([ ...tmp ]);
    // }
    function toggleSelectCompany(Company) {
        let tmp = Companies;
        let idx = tmp.findIndex(g => g.Id === Company.Id);

        tmp[idx].selected = typeof tmp[idx].selected === "undefined" ? true : !tmp[idx].selected;
        SetCompanies([ ...tmp ]);
    }

    useEffect(() => {
        if (Companies !== null && Companies.length === 1) {
            finalize();
        }
    // eslint-disable-next-line
    }, [Companies])

    async function finalize() {
        let companies = Companies.filter(cp => cp.selected === true).map(cp => cp.Id).join(',');
        try {
            await api.put('/auth/session/instance/companies', { companies: companies });
            
            // setTimeout(() => {
                userCtrl.setUser({
                    ...forback.user,
                    // name: forback.user.name,
                    // email: forback.user.email,
                    // phone: forback.user.phone,
                    // user: forback.user.user,
                    // picture: forback.user.picture,
                    // type: forback.user.type,
                    session: {
                        ready: { instance: true, companies: true },
                        instances: forback.instances,
                        modules: forback.modules
                    }
                });
                userCtrl.setLogged(true);
            // }, 500);

            // window.$('.bg-pglogin').toggleClass('fadeIn faster fadeOut');
            // window.$('.stage.select-companies-instance .card.select-companies-instance').toggleClass('fadeIn faster fadeOutDown');
        } catch (err) {
            console.error(err.response);
        }
    }

    function searchCompanies(e) {
        let text = e.target.value;
        let tmp = Companies.map(Cp => {
            if (text) {
                Cp.MatchWithSeatch = rmac(Cp.Name.toLowerCase()).indexOf(rmac(text.toLowerCase())) > -1;
            } else {
                Cp.MatchWithSeatch = undefined;
            }
            return Cp;
        });
        SetCompanies([ ...tmp ]);
    }

    function adjustStageMarginTop() {
        let $stage = window.$('.stage.select-companies-instance');
        let heightTo = (window.innerHeight-$stage.height())/2;
        $stage.css('margin-top',  heightTo < 0 ? 0 : heightTo + 'px');
    }

    useEffect(() => {
        adjustStageMarginTop();
        if (userCtrl.user.name) {
            if (userCtrl.user.session.ready.companies) {
                return;
            }
        }
        setTimeout(() => {
            getCompanies();
        }, 1000);
    // eslint-disable-next-line
    }, [])

    return (
        <Container className="stage select-companies-instance">
            <Row className="justify-content-center">
                <Col md="8" lg="6">
                    <Card className="shadow-none select-companies-instance fadeIn animated">
                        <Card.Body className="pt-0">
                            <Card.Header className="text-center border-0">
                                <div>
                                    
                                </div>
                                <h1 className="mb-2 mt-3 text-dark">{InstanceName}</h1>
                                {(Companies !== null && Companies.length > 6) && (
                                <div className="d-flex justify-content-center search">
                                    <Form.Control
                                        type="text"
                                        name="search"
                                        spellCheck="false"
                                        autoComplete="false"
                                        placeholder="Pesquisar"
                                        onChange={searchCompanies}
                                    />
                                </div>
                                )}
                            </Card.Header>
                            {
                                Companies === null
                                ?
                                    <div className="text-center">
                                        <div className="spinner-border text-dark m-2" role="status"></div>
                                    </div>
                                :
                                Companies.length === 0
                                ?
                                    <div className="my-3 text-center">
                                        Nenhuma Empresa Encontrada
                                        <br />
                                        <Button className="mt-3" onClick={logout} variant="outline-danger">Sair</Button>
                                    </div>
                                :
                                    <>
                                        <div className="companies">
                                            <ListGroup>
                                            {Companies.filter(f => typeof f.MatchWithSeatch === "undefined" || f.MatchWithSeatch).map((Company) => (
                                                <ListGroup.Item className="py-1 mb-1 align-items-center d-flex animated fadeInUp fast" key={Company.Id}>
                                                    <Col className="justify-content-between align-items-center col d-flex" onClick={() => toggleSelectCompany(Company)}>
                                                        <b><div style={{width:23}} className="d-inline-block font-weight-lighter text-muted">{Company.Id}</div>{Company.Name}</b>
                                                        {Company.selected ? (
                                                            <Form.Check 
                                                                custom
                                                                checked={true}
                                                                label={``}
                                                                readOnly
                                                            />
                                                        ) : (
                                                            <Form.Check 
                                                                custom
                                                                checked={false}
                                                                label={``}
                                                                readOnly
                                                            />
                                                        )}
                                                    </Col>
                                                    {/* <Col>
                                                    </Col>
                                                    <Col xs="2" className="text-right favorite">
                                                        <a href="#/" onClick={e => favoriteCompany(e, Company, idx)} className={`${Company.Favorited ? ' active':''}`}>
                                                            <i className="mdi mdi-star"></i>
                                                        </a>
                                                    </Col> */}
                                                    {Company.Inactive === 'S' && (<Badge className="float-right" variant="danger-lighten">Inativa</Badge>)}
                                                </ListGroup.Item>
                                            ))}
                                            </ListGroup>
                                        </div>
                                        <div className="footer">
                                            <Row>
                                                {forback.instances.length > 1 && (
                                                    <Col xs="3" className="px-0">
                                                        <Button variant="link" className="p-0" onClick={() => setStage({ to: 'SelectInstance', data: forback })}>Intâncias</Button>
                                                    </Col>
                                                )}
                                                {Companies.length > 1 && (
                                                <Col className={`px-0 text-${forback.instances.length > 1 ? 'center':'left'}`}>
                                                    <Form.Check 
                                                        custom
                                                        id="hnd2879cbudadsogdi"
                                                        checked={!(Companies.filter(cp => cp.selected).length === 0)}
                                                        onChange={(e) => {
                                                            let tmp = Companies;
                                                            for (let x = 0; x < tmp.length; x++) {
                                                                tmp[x].selected = e.currentTarget.checked;
                                                            }
                                                            SetCompanies([ ...tmp ]);
                                                        }}
                                                        label={(!(Companies.filter(cp => cp.selected).length === 0) ? 'D' : 'M') + '/Todos'}
                                                    />
                                                </Col>
                                                )}
                                                <Col xs={Companies.length > 1 ? '3':forback.instances.length > 1 ? '9':'12'} className={`text-${Companies.length > 1 ? 'right':forback.instances.length > 1 ? 'right': 'center'} px-0`}>
                                                    <Button variant={Companies.filter(cp => cp.selected).length === 0 ? 'light' : 'success'} disabled={Companies.filter(cp => cp.selected).length === 0} className="py-1 px-2 font-12 shadow-none" onClick={() => finalize()}>Continuar</Button>
                                                </Col>
                                            </Row>
                                            {Companies.filter(cp => cp.selected).length > 3 && (
                                                <div className="text-center text-danger mt-2">Atenção, selecionar várias empresas poderá deixar o sistema lento!</div>
                                            )}
                                        </div>
                                    </>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}