import React, { useState, useEffect } from 'react'

import ReactApexChart from "react-apexcharts";

import api from 'services/api'
import { realCurrency, decimalAdjust } from 'helpers/functions'
import { Row, Col, Card, Table, Button } from 'react-bootstrap'

export default function StatisticsChartsSalesOfDayPerTime({ sync }) {

    const [ChartSalesOfDayPerTime, SetChartSalesOfDayPerTime]                   = useState([{data:[]}])
    const [ChartSalesOfDayPerTimePending, SetChartSalesOfDayPerTimePending]     = useState(false)
    const [DateChartSalesOfDay, SetDateChartSalesOfDay]                         = useState(null)
    const [AmountOfSalesPerHour, SetAmountOfSalesPerHour]                       = useState([])
    const [ChartPerTimeSelectionHour, SetChartPerTimeSelectionHour]             = useState()
    // const [ListSalesProducts, SetListSalesProducts]                             = useState([])
    // const [ListSalesPerUserName, SetListSalesPerUserName]                       = useState([])

    const [ChartSalesOfHours, SetChartSalesOfHours] = useState({
        options: {
            chart: { toolbar: { show: false },
                events: {
                    dataPointSelection: (e,c,f) => handleSelectionChartPerTime(c, f)
                }
            },
            dataLabels: { enabled: false },
            stroke: { show: true, width: 2, colors: ['transparent'] },
            xaxis: { categories: [], },
            yaxis: { show: false },
            tooltip: { y: { formatter: y => realCurrency(y) } },
            fill: { opacity: 1 }
        },
        series: [ { data: [] } ]
    })

    const [ChartSalesOfDayPerTimeOptions, SetChartSalesOfDayPerTimeOptions] = useState({
        chart: { stacked: false, zoom: { type: 'x', enabled: true }, toolbar: { autoSelected: 'zoom' } },
        plotOptions: { line: { curve: 'smooth', } },
        dataLabels: { enabled: false },
        markers: { size: 0, style: 'hollow', },
        stroke: { curve: 'smooth', width: 1.8 },
        fill: { type: 'gradient', gradient: { shadeIntensity: 1, opacityFrom: 0.7, opacityTo: 0.9, stops: [0, 100] } },
        colors: ['#00e396'],
        xaxis: { type: 'datetime', },
        tooltip: { x: { format: 'HH:mm' }, y: { formatter: y => realCurrency(y) } }
    })

    const [ChartSalesPerPMs, SetChartSalesPerPMs] = useState({
        options: {
            colors: ['rgba(0,143,251,0.8)','rgba(0,227,150,0.8)','rgba(254,176,25,0.8)','rgba(255,69,96,0.8)'],
            labels: ['A'],
            legend: {
                position: 'bottom',
                formatter: function (val, opts) {
                  return val + " - " + realCurrency(opts.w.globals.series[opts.seriesIndex])
                }
            },
        },
        series: [1]
    })


    function handleSelectionChartPerTime(chartContext, config) {
        let hour = chartContext.w.config.xaxis.categories[config.selectedDataPoints[0][0]];
        if (hour) {
            SetChartPerTimeSelectionHour(hour);
        }
    }

    /**
     * Set selection on ChartSalesOfDayPerTime based of ChartSalesOfHours
     */
    useEffect(() => {
        
        if (ChartPerTimeSelectionHour && DateChartSalesOfDay !== null) {
            let timeHourInitial = Number(window.moment(DateChartSalesOfDay).set({
                hour: Number(ChartPerTimeSelectionHour),
                minute: 0,
                second: 0
            }).format('x'));
            let timeHourFinal = Number(window.moment(DateChartSalesOfDay).set({
                hour: Number(ChartPerTimeSelectionHour),
                minute: 59,
                second: 59
            }).format('x'));

            SetChartSalesOfDayPerTimeOptions({
                ...ChartSalesOfDayPerTimeOptions,
                xaxis: { 
                    type: 'datetime',
                    min: timeHourInitial,
                    max: timeHourFinal,
                }
            });
        }
    
    // eslint-disable-next-line
    }, [ChartPerTimeSelectionHour]);

    function compileAndRenderData(data) {

        let mm          = window.moment;
        let series      = [];
        // let listByUser  = [];

        data.forEach(el => {
            // let f = listByUser.findIndex(i => i.name === el.UserName);
            // if (f !== -1) {
            //     listByUser[f].data.push(el);
            // } else {
            //     listByUser.push({
            //         name: el.UserName,
            //         data: [el]
            //     });
            // }
            // console.log(el);
            let Hour = el.Hour.split(':');
            series.push([
                mm().set({
                    'hour': Hour[0],
                    'minute': Hour[1],
                    'second': Hour[2],
                }).valueOf(),
                parseFloat(el.Total)
            ]);
        });

        // listByUser.sort((a,b) => (a.data.length < b.data.length) ? 1 : (a.data.length > b.data.length) ? -1 : 0);

        // SetListSalesPerUserName(listByUser);

        comStatisticsOfSerie(series);

        SetChartSalesOfDayPerTime([{
            name: window.moment(DateChartSalesOfDay).format('DD MMMM YYYY'),
            data: series
        }]);
    }

    function timeAdjust(time) {
        let minutes = Math.trunc(time);
        let seconds = (minutes === 0) ? Math.trunc(time * 60) : Math.trunc((time % 1) * 60);
        return (minutes > 0 ? minutes + 'm' : '') + (seconds > 0 ? seconds + 's' : '');
    }

    function comStatisticsOfSerie(data) {
        let mm = window.moment;

        /**
         * Join sales per Hour
         * 
         * perHour = Array of Object { hour: String, data: Array of Numbers }
         */
        let perHour = [];
        for (let x = 0; x < data.length; x++) {
            let time        = mm(data[x][0]);
            let hour        = time.format('HH');
            let timefull    = time.format('HH:mm:ss');
            let unix        = Number(time.format('X'));
            
            let perHourIndex = perHour.findIndex(y => y.hour === hour);
            if (perHourIndex === -1) {
                perHour.push({
                    hour: hour,
                    data: [{ time: timefull, unix: unix, value: data[x][1]}]
                });
            } else {
                perHour[perHourIndex].data.push({ time: timefull, unix: unix, value: data[x][1]});
            }
        }

        // Sort by length of property data
        perHour.sort((a,b) => {
            let h1 = a.data.length;
            let h2 = b.data.length;
            return (h1 < h2) ? 1 : (h1 > h2) ? -1 : 0;
        });

        for (let z = 0; z < perHour.length; z++) {
            if (z > 0) {
                let f = perHour[0].data.length;
                let r = perHour[z].data.length;
                let c = ((f-r)/r)*100;
                perHour[z].peakPercentage = c;
                perHour[z].peak = (c >= 0 && c <= 10) ? 'danger' : (c > 10 && c <= 25) ? 'warning' : (c > 25 && c <= 50) ? 'muted' : false;
            } else {
                perHour[z].peakPercentage = null;
                perHour[z].peak = 'danger';    
            }
        }

        // Sort by property hour
        perHour.sort((a,b) => {
            let h1 = Number(a.hour);
            let h2 = Number(b.hour)
            return (h1 > h2) ? 1 : (h1 < h2) ? -1 : 0;
        });

        let categories = [];
        let series = [];

        let SalesPerHours = [];
        for (let y = 0; y < perHour.length; y++) {
            let TotalReduced = perHour[y].data.reduce((a,b) => a + b.value, 0);

            for (let k = 0; k < perHour[y].data.length; k++) {
                if (k > 0) {
                    perHour[y].data[k].minutesFromPrevious = decimalAdjust('round', (perHour[y].data[k].unix - perHour[y].data[k-1].unix) / 60, -1);
                } else {
                    perHour[y].data[k].minutesFromPrevious = 0;
                }
            }

            // x are previous index of added SalesPerHours
            let x = SalesPerHours.push({
                hour: perHour[y].hour,
                peak:  perHour[y].peak,
                peakPercentage:  perHour[y].peakPercentage,
                averageMinutes: perHour[y].data.reduce((a,b) => a + b.minutesFromPrevious, 0) / (perHour[y].data.length - 1),
                amount: perHour[y].data.length, 
                total: TotalReduced
            }) - 1;

            categories.push(perHour[y].hour);

            // w are previous index of added series
            let w = series.push(TotalReduced) -1;

            SalesPerHours[x].media = (series[w] / SalesPerHours[x].amount);
            if (y > 0) {
                SalesPerHours[x].variant = ((SalesPerHours[x].total - SalesPerHours[x-1].total ) / SalesPerHours[x-1].total) * 100;
            }
        }

        SetAmountOfSalesPerHour(SalesPerHours);

        SetChartSalesOfHours({
            options: {
                ...ChartSalesOfHours.options,
                xaxis: { categories }
            },
            series: [{
                name: 'Total',
                data: series
            }]
        });
        
    }

    useEffect(() => {
        if (DateChartSalesOfDay !== null) {

            // let date = window.moment(DateChartSalesOfDay).format('DD MM YYYY');
            // let datepart = date.split(' ');

            api.get('/reports/sales/salesOfPaymentMethods', {
                filters: {
                    date: {
                        start: DateChartSalesOfDay.start,
                        end: DateChartSalesOfDay.end
                    }
                }
            })
            .then( response => {
                const { data } = response;
                if (data.length > 0) {
                    let labels = [];
                    let series = [];
                    for (let x = 0; x < data.length; x++) {
                        labels.push(data[x].Description);
                        series.push(parseFloat(data[x].Total));
                    }
                    SetChartSalesPerPMs({
                        options: { ...ChartSalesPerPMs.options, labels },
                        series
                    });
                }
            });

            // api.get('/reports/sales/salesOfProducts', {
            //     filters: {
            //         date: {
            //             start: `${datepart[2]}-${datepart[1]}-${datepart[0]}`,
            //             exact: 'true'
            //         }
            //     }
            // })
            // .then( response => {
            //     SetListSalesProducts(response.data)
            // });

            getSalesOfDay(DateChartSalesOfDay.start, DateChartSalesOfDay.end);
        } else {

        }
    // eslint-disable-next-line
    }, [DateChartSalesOfDay])

    async function getSalesOfDay(startDate, endDate) {
        SetChartSalesOfDayPerTimePending(true);
        const response = await api.get('/reports/sales/salesOfDay',{
            filters: {
                date: {
                    start: startDate,
                    end: endDate
                }
            }
        });
        compileAndRenderData(response.data.data);
        SetChartSalesOfDayPerTimePending(false);
    }

    useEffect(() => {

        let mm = window.moment;
        window.$('button[data-toggle-custom="daterangepicker"]').daterangepicker({
            showDropdowns: true,
            ranges: {
                'Hoje': [mm(), mm()],
                'Ontem': [mm().subtract(1, 'days'), mm().subtract(1, 'days')],
                'Últimos 7 dias': [mm().subtract(6, 'days'), mm()],
                // 'Últimos 30 dias': [mm().subtract(29, 'days'), mm()],
                // 'Este mês': [mm().startOf('month'), mm().endOf('month')],
                // 'Mês anterior': [mm().subtract(1, 'month').startOf('month'), mm().subtract(1, 'month').endOf('month')],
                // 'Últimos 6 meses': [mm().subtract(6, 'month').startOf('month'), mm()],
                // 'Últimos 12 meses': [mm().subtract(1, 'year'), mm()]
            },
            opens: 'left',
            locale: {
                applyLabel: "Aplicar",
                cancelLabel: "Cancelar",
                customRangeLabel: "Intervalo personalizado",
            }
        }).on('apply.daterangepicker', (e, picker) => {
            let start       = picker.startDate.format('YYYY-MM-DD'); 
            let end         = picker.endDate.format('YYYY-MM-DD');
            let toDisplay   = picker.startDate.format('DD MMMM YYYY');

            if (start !== end) {
                toDisplay = toDisplay + ' - ' + picker.endDate.format('DD MMMM YYYY');
            }

            SetDateChartSalesOfDay({
                start: start,
                end: end,
                toDisplay: toDisplay
            });
        });

        // window
        //     .$('#btnSelectDateSales[data-provide="datepicker"]')
        //     .on("changeDate", e => {
        //         SetDateChartSalesOfDay(e.date);
        //     });

        window
            .$('[data-toggle="tooltip"]').tooltip();

    }, []);

    useEffect(() => {
        if (sync > 0 && DateChartSalesOfDay !== null) {
            SetChartSalesOfDayPerTime([{data:[]}])
            SetDateChartSalesOfDay(null)
            SetAmountOfSalesPerHour([])
            // SetListSalesPerUserName([])
            SetChartSalesOfHours({
                ...ChartSalesOfHours,
                series: [ { data:[] } ]
            })
            SetChartSalesPerPMs({
                ...ChartSalesPerPMs,
                series: [1]
            })
            // SetListSalesProducts([])
        }
    // eslint-disable-next-line
    }, [sync])

    return (
        <Card>
            <Card.Body>
                <Card.Title className="mb-4 mt-0">
                    <div className="d-inline-block mt-2">Horários</div>
                    {/* <Button
                        size="sm"
                        variant="link"
                        className="float-right text-dark py-1"
                        id="btnSelectDateSales"
                        data-provide="datepicker"
                        data-date-autoclose="true"
                        data-date-format="dd MM yyyy"
                        data-date-orientation="right"
                        data-date-end-date="0d"
                    >{
                        DateChartSalesOfDay !== null ?
                            window.moment(DateChartSalesOfDay).format('DD MMMM YYYY')
                        :
                            'Selecionar Data'
                    }</Button> */}

                    <Button
                        size="sm"
                        variant="outline-primary"
                        className="float-right py-1"
                        data-toggle-custom="daterangepicker"
                    >{
                        DateChartSalesOfDay !== null ?
                            DateChartSalesOfDay.toDisplay
                        :
                            'Selecionar data'
                    }</Button>
                    
                </Card.Title>


                {ChartSalesOfDayPerTime[0].data.length === 0 && (
                <div
                    className="align-items-center d-flex justify-content-center text-center"
                    style={{
                        position: 'absolute',
                        top: '8%',
                        left: 0,
                        width: '100%',
                        height: '92%',
                        backgroundColor: '#FFFFFF',
                        zIndex: 1,
                    }}
                >
                    <div>
                        <div>
                            <img  alt="reports" src={process.env.PUBLIC_URL + "/assets/images/uptrend_growth_profit_bar_chart_4272301.png"} width="64" />
                        </div>
                        <strong>Vendas do dia por horários.</strong>
                        {(DateChartSalesOfDay !== null && !ChartSalesOfDayPerTimePending) && (
                        <div className="text-danger">Não há dados para a data selecionada!</div>
                        )}
                    </div>
                </div>
                )}


                <Row>
                    <Col md="12">
                        <ReactApexChart options={ChartSalesOfHours.options} series={ChartSalesOfHours.series} type="bar" height="350" />
                    </Col>
                    <Col md="12">
                        <ReactApexChart options={ChartSalesOfDayPerTimeOptions} series={ChartSalesOfDayPerTime} type="area" height="350" />
                    </Col>
                    <Col md="5">
                        <div>
                            <ReactApexChart options={ChartSalesPerPMs.options} series={ChartSalesPerPMs.series} type="donut" width="320" className="d-flex justify-content-around" />
                        </div>
                    </Col>
                    <Col md="7">
                        <Table style={{fontSize:12}} className="mt-3 mb-1" size="sm">
                            <thead>
                                <tr>
                                    <th className="border-bottom-0">Horário</th>
                                    <th
                                        className="text-center border-bottom-0"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title=""
                                        data-original-title="Lançamentos de vendas não brindes e com situação faturada."
                                    >Lançamentos</th>
                                    <th
                                        className="text-center border-bottom-0"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title=""
                                        data-original-title="Média do intervalo de tempo entre lançamentos."
                                    >M <i className="mdi mdi-av-timer"></i></th>
                                    <th className="text-center border-bottom-0">Média</th>
                                    <th
                                        className="text-center border-bottom-0 w-15"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title=""
                                        data-original-title="Variação percentual do valor total em relação ao horário anterior."
                                    >%</th>
                                    <th className="text-right border-bottom-0 w-15">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AmountOfSalesPerHour.map((item, idx) => (
                                <tr key={idx}>
                                    <td className="py-1 position-relative">
                                        {item.hour}
                                        {item.peak && (
                                            <i
                                                style={{fontSize: 17, marginTop: -4}}
                                                title={`${item.peakPercentage}%`}
                                                className={`mdi mdi-fire position-absolute text-${item.peak}`}
                                            ></i>
                                        )}
                                    </td>
                                    <td className="py-1 text-center">{item.amount}</td>
                                    <td className="py-1 text-center">
                                        {
                                            item.amount > 1 ? 
                                                timeAdjust(item.averageMinutes)
                                            : 
                                                <>-</>
                                        }
                                    </td>
                                    <td className="py-1 text-center">{realCurrency(item.media)}</td>
                                    <td className="py-1 text-center">
                                        {
                                            item.variant
                                            &&
                                            (
                                                <small className={`text-${item.variant > 0 ? 'success':'danger'}`}><i className={`mdi mdi-arrow-${item.variant > 0 ? 'up':'down'}-bold`}></i>&nbsp;{decimalAdjust('round', item.variant, -1)}%</small>
                                            )
                                        }
                                    </td>
                                    <td className="py-1 text-right">
                                        {realCurrency(item.total)}
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><strong>Total <b style={{fontSize:10}}>({AmountOfSalesPerHour.length} horas)</b></strong></td>

                                    {/* Amount Sum */}
                                    <td className="text-center"><strong>{
                                        AmountOfSalesPerHour
                                        .reduce((d,c) => d + c.amount, 0)
                                    }</strong></td>

                                    <td></td>

                                    {/* Media Sum */}
                                    <td className="text-center"><strong>{realCurrency(
                                        AmountOfSalesPerHour
                                        .reduce((d,c) => d + c.media, 0)
                                        / AmountOfSalesPerHour.length
                                    )}</strong></td>

                                    <td></td>

                                    {/* Total Sum */}
                                    <td className="text-right"><strong>{realCurrency(
                                        AmountOfSalesPerHour
                                        .reduce((d,c) => d + c.total, 0)
                                    )}</strong></td>
                                </tr>
                            </tfoot>
                        </Table>
                        <div className="text-muted text-right">
                            <small
                                data-toggle="tooltip"
                                data-placement="left"
                                title=""
                                data-original-title="Ápices de lançamentos, leva em consideração o horário com maior quantidade de lançamentos e calcula o restante por variação percentual. Vermelho: 0% a 10%. Amarelo: 11% a 25%. Cinza: 26% a 50%."
                            >
                                <i style={{ fontSize: 14 }} className="mdi mdi-fire text-danger"></i>
                                <i style={{ fontSize: 14 }} className="mdi mdi-fire text-warning"></i>
                                <i style={{ fontSize: 14 }} className="mdi mdi-fire text-muted"></i>
                                Ápices de lançamentos.
                            </small>
                        </div>
                        {/* <Table style={{fontSize:12}} className="mt-3" size="sm">
                            <thead>
                                <tr>
                                    <th className="border-bottom-0">Produto</th>
                                    <th className="text-center border-bottom-0">Quantidade</th>
                                    <th className="text-right border-bottom-0">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ListSalesProducts.map((Product, idx) => (
                                <tr key={idx}>
                                    <td>{Product.Description}</td>
                                    <td className="text-center">{Product.Quantity}</td>
                                    <td className="text-right">{realCurrency(Product.Total)}</td>
                                </tr>
                                ))}
                            </tbody>
                        </Table> 
                        <Table style={{fontSize:12}} className="mt-3" size="sm">
                            <thead>
                                <tr>
                                    <td><strong>Usuário</strong></td>
                                    <td className="text-center"><strong>Quantidade</strong></td>
                                    <td className="text-right"><strong>Total</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {ListSalesPerUserName.map((item, idx) => (
                                <tr key={idx}>
                                    <td className="text-uppercase">{item.name}</td>
                                    <td className="text-center">{item.data.length}</td>
                                    <td className="text-right">{
                                        realCurrency(
                                            item.data.reduce((x,c) => x + c.Total, 0)
                                        )
                                    }</td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                        */}
                    </Col>
                </Row>
            </Card.Body>
            { ChartSalesOfDayPerTimePending && <div style={{zIndex:2}} className="card-disabled"><div className="card-portlets-loader"></div></div> }
        </Card>
    );
}