import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'

import api from 'services/api'

export default function UserAdd() {

    const [userName, setUserName]   = useState('');
    const [userUser, setUserUser]   = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userADM, setUserADM]     = useState(false);
    const [reqPending, setReqPending] = useState(false);
    const [resp, setResp] = useState(null);
    const [redirectTo, setRedirectTo] = useState(null);
    // const [useInstance, setUseInstance] = useState(false);
    // const [listInstances, setListInstances] = useState(false);

    async function onSubmitRegister(e) {
        e.preventDefault();
        setResp(null);
        let user = {
            name: userName,
            user: userUser,
            email: userEmail,
            phone: userPhone,
            password: userPassword,
            isAdm: userADM ? 1 : 0
        };

        if (Object.values(user).indexOf('') !== -1) {
            setResp({ message: 'Preencha todos os campos.', variant: 'warning'  });
            return;
        }

        setReqPending(true);

        
        try {
            const { data } = await api.post('/settings/users/add', user);

            setRedirectTo('/users/'+data.id+'/look');
            
            return;
        } catch (err) {
            if (err.response) {
                console.error(err.response);
                let sttmsg = {
                    UserAlreadyRegistered: 'Usuário já cadastrado. Pode ser o usuário, e-mail e/ou telefone.',
                    UserUnregistered: 'Não foi possível cadastrar usuário. Creio que seja algo interno no sistema'
                }
                setResp({ message: sttmsg[err.response.data.status] || 'Erro desconhecido, verifique o console.', variant: 'danger' });
            }
        }

        setReqPending(false);
    }

    return (
        <Row className="justify-content-center mt-4">
            <Col md="9">
                <Card className="shadow-none">
                    <Card.Body>
                        <Form onSubmit={onSubmitRegister}>
                            <Row className="justify-content-center">
                                <Col md="6">
                                    <div className="d-flex justify-content-center mb-4">
                                        <div style={{width:64,height:64,lineHeight:'64px',fontSize:18,fontWeight:'bold'}} className="rounded-circle text-center text-dark shadow-sm">{userName.substr(0, 2).toUpperCase()}</div>
                                    </div>
                                    <Form.Group controlId="frmUserNameField">
                                        <Form.Control type="text" name="name" value={userName} onChange={e => setUserName(e.currentTarget.value)} placeholder="Nome Completo" />
                                    </Form.Group>
                                    <Form.Group controlId="frmUserUserField">
                                        <Form.Control type="text" name="user" value={userUser} onChange={e => setUserUser(e.currentTarget.value)} placeholder="Usuário" />
                                    </Form.Group>
                                    <Form.Group controlId="frmUserEmailField">
                                        <Form.Control type="email" name="email" value={userEmail} onChange={e => setUserEmail(e.currentTarget.value)} placeholder="E-mail" />
                                    </Form.Group>
                                    <Form.Group controlId="frmUserPasswordField">
                                        <Form.Control type="password" name="password" value={userPassword} onChange={e => setUserPassword(e.currentTarget.value)} placeholder="Senha" />
                                    </Form.Group>
                                    <Form.Group controlId="frmUserPhoneField">
                                        <Form.Control type="text" name="phone" value={userPhone} onChange={e => setUserPhone(e.currentTarget.value)} placeholder="Telefone" />
                                    </Form.Group>
                                    <div className="custom-control custom-switch my-3">
                                        <input type="checkbox" name="adm" value="1" checked={userADM} onChange={e => setUserADM(e.currentTarget.checked)} className="custom-control-input" id="dhnx023y2bc874" />
                                        <label className="custom-control-label" htmlFor="dhnx023y2bc874">Administrador</label>
                                    </div>
                                    {resp !== null && (
                                        <div className={`my-3 text-center text-${resp.variant}`}>
                                            {resp.message}
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between">
                                        <Link className="p-1" to="/users">Cancelar</Link>
                                        <Button size="sm" disabled={reqPending} type="submit" variant="success">{reqPending ? 'Aguarde...':'Cadastrar'}</Button>
                                    </div>
                                </Col>
                                {/* <Col md="7">
                                    <h4 className="mb-4">Opções</h4>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>Administrador</span>
                                        <div>
                                            <input type="checkbox" id="sdh2ydb8279" checked={userADM} onChange={e => setUserADM(e.currentTarget.checked)} data-switch="success" />
                                            <label htmlFor="sdh2ydb8279" data-on-label="Sim" data-off-label="Não"></label>
                                        </div>
                                    </div>
                                    <p className="font-11 text-muted">Administradores tem acesso a todo sistema, incluindo este painel de configurações :)</p>
                                    {!userADM && (
                                    <>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>Acessos à Instâncias</span>
                                            <div>
                                                <input type="checkbox" id="hbd872g8b9" checked={useInstance} onChange={e => setUseInstance(e.currentTarget.checked)} data-switch="success" />
                                                <label htmlFor="hbd872g8b9" data-on-label="Sim" data-off-label="Não"></label>
                                            </div>
                                        </div>
                                        <p className="font-11 text-muted">Definir quais instâncias {userName !== '' ? userName.split(' ')[0] : 'este usuário'} poderá acessar, e vincular ou cadastrar com um ID interno.</p>
                                        {useInstance && (
                                            <div>
                                                asdasd
                                            </div>
                                        )}
                                    </>
                                    )}

                                </Col> */}
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            {redirectTo !== null && (
                <Redirect to={redirectTo} />
            )}
        </Row>
    )
}