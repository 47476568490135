import React, { useEffect, useState, useCallback, forwardRef } from 'react'

import api from 'services/api'

import { Dropdown, Spinner, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import TargetsView from './TargetsView'
import TargetsUndefinedYear from './TargetsUndefinedYear'

const _defTargets = {
  loading: true,
  unavailable: false,
  $: null
}

function fnStabilizeTargets(data) {
  return data.map(x => {
    return {
      year: Number(x.year),
      data: JSON.parse(x.data),
      created_at: x.created_at
    };
  });
}

function TargetsTitle({ data }) {

  const [isOpen, setIsOpen] = useState(false)
  const [fltTxt, setFltTxt] = useState('')

  return (
    <Dropdown onToggle={(isOpen) => setIsOpen(isOpen)}>
      <Dropdown.Toggle 
        as={forwardRef(({ children, onClick }, ref) => (
          isOpen ?
            <Form onSubmit={e => e.preventDefault()}>
              <Form.Control 
                type="text" 
                placeholder="Pesquisar..." 
                style={{fontSize:'2.25rem', margin:'13px 0'}} 
                className="border-0 p-0 font-weight-light text-uppercase" 
                onChange={e => setFltTxt(e.currentTarget.value)}
                value={fltTxt}
                autoFocus 
                />
            </Form>
          :
          <h1>
            <a
              href="#/"
              ref={ref}
              className="font-weight-light"
              onClick={e => {
                e.preventDefault();
                onClick(e);
              }}
            >
            <span>{children}</span>
            <i className="mdi mdi-chevron-down ml-1"></i>
          </a>
          </h1>
      ))}>
        {data.company.Name}
      </Dropdown.Toggle>

      <Dropdown.Menu className="shadow" style={{width:400}}>
        {
          data
          .companies
          .list
          .filter(item => fltTxt === '' || item.Name.toLowerCase().indexOf(fltTxt.toLowerCase()) !== -1)
          .slice(0, 10)
          .map(item => (
          <Dropdown.Item key={item.Id} as={Link} to={`/sales/targets/${item.Key}`}>{item.Name}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default function TargetsControl({ company, companies }) {

  const [targets, setTargets] = useState(_defTargets);

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());

  const getTargetsCompany = useCallback(
    async (optionalFilters) => {
      const data = {..._defTargets};

      setTargets({...data});

      const filters = { companyId: company.Id };

      if (optionalFilters) {
        Object.assign(filters, optionalFilters);
      }

      try {
        const response = await api.get('/reports/sales/targets', filters);
        
        data.$     = fnStabilizeTargets(response.data)[0] || null;
        data.loading  = false;

      } catch (err) {
        if (err.response) {
          // if (err.response.data.code === "42S02") {
            data.unavailable = true;
          // }
        }
        data.loading = false;
      }
      setTargets(data);
    },
    [company]
  );

  useEffect(() => {
    
    getTargetsCompany({ year });

  }, [year, getTargetsCompany])

  return (
    <div className="targets__company">

      <TargetsTitle data={{company, companies}} />

    {
      !targets.loading ? (

        targets.$ !== null ? (

          <TargetsView
            $={targets.$}
            year={year}
            setYear={setYear}
            zmonth={month}
            setMonth={setMonth}
            company={company}
            companies={companies}
            forceReloadSalesTarget={() => getTargetsCompany({ year })}
          />

        ) : (

          <TargetsUndefinedYear 
            year={year}
            setYear={setYear}
            zmonth={month}
            company={company}
            isUnavailable={targets.unavailable}
            forceReloadSalesTarget={() => getTargetsCompany({ year })}
          />

        )

      ) : (

        <Spinner animation="border" variant="dark" />

      )
    }
    </div>
  )
}