import React from 'react'

import { Switch, Route } from 'react-router-dom'

import UsersList from './UsersList'
import UserProfile from './UserProfile'

export default function Users() {

    return (
        <div className="mgmt--users">
            <Switch>
                <Route exact path="/management/users" component={UsersList} />
                <Route path="/management/users/register" component={() => <>Register user</>} />
                <Route path="/management/users/:id([0-9]+)" component={UserProfile} />
            </Switch>
        </div>
    )
}