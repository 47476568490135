import React from 'react'

export default function Initial() {

    return (
        <>
        <h1 className="mt-4 text-muted">
            Gerenciamento
        </h1>
        <p className="text-muted mt-3">
            Página de configurações em desenvolvimento, novos recursos serão disponibilizados em breve.
        </p>
        </>
    )
}