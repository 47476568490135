/**
 * localStorage helper
 */
class ls {
    get(key){
        return window.localStorage.getItem(key);
    }
    has(key){
        return this.get(key) !== null;
    }
    set(key, value){
        window.localStorage.setItem(key, (typeof value === "object") ? JSON.stringify(value) : value);
    }
    remove(key){
        window.localStorage.removeItem(key);
    }
    clear() {
        window.localStorage.clear();
    }
}
export default new ls();