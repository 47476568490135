import React from 'react'

import Implantation   from './implantation'
import Instances      from './instances'
import Users          from './users'

export const CustomRoutes = [

];

export const Navigation = [
    {
        icon: 'mdi mdi-speedometer',
        text: 'Início',
        link: '/',
        exact: true,
        component: () => <></>,
        items: [] // {link, text, component}
    },
    {
        icon: 'mdi mdi-plus-box-outline',
        text: 'Implantação',
        link: '/implantation',
        exact: false,
        component: Implantation,
        items: []
    },
    {
        icon: 'mdi mdi-account-circle',
        text: 'Usuários',
        link: '/users',
        exact: false,
        component: Users,
        items: []
    },
    {
        icon: 'mdi mdi-server',
        text: 'Instâncias',
        link: '/instances',
        exact: false,
        component: Instances,
        items: []
    }
];