/**
 * Send Notification
 * @param {*} heading heading text
 * @param {*} body body text
 * @param {*} position position e.g top-right, top-left, bottom-left, etc
 * @param {*} loaderBgColor loader background color
 * @param {*} icon icon which needs to be displayed
 * @param {*} hideAfter automatically hide after seconds
 * @param {*} stack 
 */
export const Toast = window.$.NotificationApp.send;