import React from 'react'

import { Row, Col } from 'react-bootstrap'

import ChartSalesOfMonthPerDays from './ChartSalesOfMonthPerDays'
import StatisticsChartsSalesOfDayPerTime from './StatisticsChartsSalesOfDayPerTime'
import ChartSalesOfYear from './ChartSalesOfYear'
// import StatisticsSalesProducts from './StatisticsSalesProducts'

export default function Sales({ sync }) {

    return (
        <>
            <Row>
                <Col>
                    <div className="page-title-box">
                        <h4 className="page-title">Vendas</h4>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <ChartSalesOfMonthPerDays sync={sync} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <StatisticsChartsSalesOfDayPerTime sync={sync} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <ChartSalesOfYear sync={sync} />
                </Col>
            </Row>
        </>
    )
}