import React, { useState, useEffect } from 'react'

import ReactApexChart from "react-apexcharts";

import api from 'services/api'
import { Toast } from 'helpers/Hyper'
import { realCurrency } from 'helpers/functions'
import { Row, Col, Card, Button } from 'react-bootstrap'

const ww = window.innerWidth >= 768;

export default function ChartSalesOfYear({ sync }) {

    const [DynamicDate, SetDynamicDate] = useState(null)

    const [ChartSeries, SetChartSeries] = useState([])
    const [ChartRequestPending, SetChartRequestPending] = useState(false)

    const $ChartOptions = {
        chart: { animations: { enabled: false, }, toolbar: { show: true, }, zoom: { enabled: false, } },
        theme: { palette: 'palette7' },
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth', width: 1.5 },
        legend: { showForSingleSeries: true, height: ww ? 40 : 20, offsetY: ww >= 768 ? -20 : 0, itemMargin: { vertical: 10 }, },
        tooltip: { y: { formatter: y => realCurrency(y) } },
        xaxis: {
            categories: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        }
    }

    async function getSalesOfYear(year) {

        if (ChartSeries.filter(r => r.name === year).length > 0) {
            Toast(
                'Vendas por Ano',
                'Ano ' + year + ' já foi adicionado!',
                'bottom-right',
                undefined,
                'info',
                1e4
            );
            return;
        }

        SetChartRequestPending(true);
        const { data } = await api.get('/reports/sales/salesOfYear', {
            filters: {
                date: {
                    start: year + '-01-01', 
                    end: year + '-12-31'
                }
            }
        });

        if (data.length > 0) {
            let salesOfYear = data.map(x => {
                x.Month = parseInt(x.Month);
                x.Total = parseFloat(x.Total || 0);
                return x;
            });
            salesOfYear.sort((A, B) => A.Month < B.Month ? -1 : A.Month > B.Month ? 1 : 0);
            SetChartSeries([ ...ChartSeries, { name: `${year}`, data: salesOfYear.map(w => w.Total) } ]);
        } else {
            Toast(
                'Vendas por Ano',
                'Não há dados para o ano ' + year,
                'bottom-right',
                undefined,
                'warning',
                1e4
            );
        }
        SetChartRequestPending(false);
    }

    useEffect(() => {
        if (DynamicDate !== null) {
            getSalesOfYear(DynamicDate.getFullYear());
        }
    // eslint-disable-next-line
    }, [DynamicDate])

    useEffect(() => {
        if (sync > 0 && DynamicDate !== null) {
            SetDynamicDate(null)
            SetChartSeries([])
        }
    // eslint-disable-next-line
    }, [sync])

    useEffect(() => {
        window
            .$('#btnSelectYearSales[data-provide="datepicker"]')
            .on("changeDate", e => {
                SetDynamicDate(e.date)
            });
    }, [])

    return (
        <Card>
            <Card.Body>
                <Card.Title className="mb-4 mt-0">
                    <div className="d-inline-block mt-1">Ano</div>

                    { ChartSeries.length > 0 && (
                    <Button
                        size="sm"
                        variant="link"
                        className="float-right py-0 text-danger"
                        onClick={() => {
                            SetDynamicDate(null)
                            SetChartSeries([])
                        }}
                    ><i className="mdi mdi-filter-remove-outline"></i></Button>
                    ) }

                    <Button
                        size="sm"
                        variant="outline-success"
                        className="float-right py-0 border-0"
                        id="btnSelectYearSales"
                        data-provide="datepicker"
                        data-date-autoclose="true"
                        data-date-orientation="right"
                        data-date-min-view-mode="2"
                        data-date-end-date="0d"
                        disabled={ChartSeries.length >= 5}
                    ><i className="mdi mdi-plus mr-1"></i>Ano</Button>
                    
                </Card.Title>
                
                <div
                    className="align-items-center justify-content-center text-center position-absolute"
                    style={{
                        minHeight: 365,
                        width: '95%',
                        zIndex: 1,
                        backgroundColor: '#fff',
                        display: ChartSeries.length > 0 ? 'none' : 'flex'
                    }}>
                    <span>
                        <div>
                            <img alt="reports" src={process.env.PUBLIC_URL + "/assets/images/report_sales_comparison_4272310.png"} width="64" />
                        </div>
                        <strong>Vendas por ano.</strong>
                        <br/>
                        Adicione até 5 anos para comparação.
                    </span>
                </div>

                <div>
                    <ReactApexChart options={$ChartOptions} series={ChartSeries.length === 0 ? [{name:'.',data:[]}] : ChartSeries} type="line" height="350" />
                </div>
                
                {ChartSeries.length > 0 && (
                <Row className="d-flex justify-content-center mt-2">
                    {ChartSeries.map((item, ix) => (
                    <Col sm="2" key={ix}>
                        <Card className="shadow-none text-center">
                            <Card.Body className="p-0">
                                <Card.Title className="mt-0" style={{color:['#D7263D', '#1B998B', '#2E294E', '#F46036', '#E2C044'][ix]}}>
                                    {item.name}
                                </Card.Title>
                                <span>{realCurrency(item.data.reduce((v,x) => v + x, 0))}</span>
                            </Card.Body>
                        </Card>
                    </Col>
                    ))}
                </Row>
                )}

            </Card.Body>
            { ChartRequestPending && <div className="card-disabled" style={{zIndex:2}}><div className="card-portlets-loader"></div></div> }
        </Card>
    )

}