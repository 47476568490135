import React, { useState, useEffect } from 'react'

import moment from 'moment'
import 'moment/locale/pt-br'

import Datetime from 'react-datetime'

import { Row, Col, Card, Button, Image } from 'react-bootstrap'

import YieldCardReport from './YieldCardReport'

import api from 'services/api'

import './style.css'

export default function FinancialYield({ sync }) {


	/**
	 * Yields control dates requests
	 */
	const [yDates, setYDates] = useState({
		startDate: null,
		endDate: null,
	})

	const defaultYields = {
		paymentMethods: { 
			// Vendas à vista
			instant: {
				total: 0, percent: 0
			}, 
			// Vendas à prazo
			term: {
				total: 0, percent: 0
			}, 
			total: 0
		},
		salesReturnAndTaxes: {
			returns: [],
			taxes: [],
		},
		cashflow: {
			debits: {
				costOfSales: [],
				others: []
			},
			debitsTotal: 0,
			credits: [],
			creditsTotal: 0,
		}
	};

	/**
	 * Data Yields
	 */
	const [yields, setYields] = useState(defaultYields)

	// Request pending yields
	const [gettingYields, setGettingYields] = useState(false)

	const [showYields, setShowYields] = useState(false)

	function setPresetYDates(type) {
		const dates = {};
		switch (type) {
			case 'currentMonth':
				dates.startDate = moment().startOf('month');
				dates.endDate   = moment().endOf('month');
			break;
			case 'previousMonth' :
					dates.startDate = moment().subtract(1, 'month').startOf('month');
					dates.endDate   = moment().subtract(1, 'month').endOf('month');
			break;
			case 'lastSixMonths': 
				dates.startDate = moment().subtract(6, 'month');
				dates.endDate   = moment();
			break;
			case 'currentYear': 
				dates.startDate = moment().set('month', 0).set('date', 1);
				dates.endDate   = moment();
			break;
			case 'previousYear':
					dates.startDate = moment().set({'month': 0, 'date': 1}).subtract(1, 'year');
					dates.endDate   = moment().set({'month': 11}).subtract(1, 'year').endOf('month');
			break;
			default:
				return false;
		}
		getYields(dates);
		setYDates({ ...dates });
	}

	// eslint-disable-next-line
	async function getYields({startDate, endDate}) {

		if (gettingYields) return;

		let dates = {
			startDate: 	startDate.format('YYYY-MM-DD'),
			endDate: 		endDate.format('YYYY-MM-DD')
		}

		setYields({...defaultYields});

		api.cancelAllRequests();
		setGettingYields(true);
		try {
			const slrtx = await api.get('/reports/financial/yield/salesReturnsAndTaxes', dates);
			const payMthds = await api.get('/reports/financial/yield/paymentMethods', dates);
			const cfDebits = await api.get('/reports/financial/yield/cashflow/debits', dates);
			const cfCredits = await api.get('/reports/financial/yield/cashflow/credits', dates);

			let salesReturnAndTaxes = {
				returns: slrtx.data.returns.reduce((y,x) => Number(x.Value) + y, 0),
				taxes: slrtx.data.taxes.reduce((y,x) => Number(x.Value) + y, 0)
			}

			let cashflow = {
				debits: cfDebits.data.debits,
				credits: cfCredits.data.credits,
			}


			let instantIdx = payMthds.data.findIndex(item => Number(item.PaymentMethod) === 1);
			let termIdx = payMthds.data.findIndex(item => Number(item.PaymentMethod) === 2);
			let instantTotal = Number(payMthds.data[instantIdx].Total);
			let termTotal = Number(payMthds.data[termIdx].Total);
			let payMtdsTotal = (instantTotal + termTotal) + (salesReturnAndTaxes.returns + salesReturnAndTaxes.taxes);
			let paymentMethods = {
				instant: {total: instantTotal, percent: (instantTotal / payMtdsTotal) * 100 }, 
				term: {total: termTotal, percent: (termTotal / payMtdsTotal) * 100},
				total: payMtdsTotal
			}

			setYields({
				...yields,

				paymentMethods,

				salesReturnAndTaxes,

				cashflow
			});
			
		} catch (err) {
			if (err.response) {
				console.error(err.response);
			}
		}

		setShowYields(true);
		setGettingYields(false);
	}

	useEffect(() => {
		if (!!sync && yDates.endDate !== null && yDates.startDate !== null) {
			getYields(yDates);
		}
	}, [sync, yDates, getYields])

	useEffect(() => {

		return () => {
			api.cancelAllRequests();
		}
	}, [])


	return (
		<div id="financial-yield">
			<Row>
					<Col>
							<div className="page-title-box">
									<h4 className="page-title">Yield</h4>
							</div>
					</Col>
			</Row>

			<Row className="d-print-none">
				<Col>
					<Card className="shadow-none">
						<Card.Body>
						<Row>
							<Col md="4">
								<Image src={process.env.PUBLIC_URL + '/assets/images/undraw_finance_0bdk.svg'} alt="finance" fluid />
							</Col>
							<Col md="8">
								<h3 className="text-dark">Relatório Yield</h3>
								<p>Relatório para analizar o desempenho financeiro da empresa. Para começar, clique em um dos pré-filtros abaixo ou busque por um período personalizado.</p>
								
								<div className="mb-2">
									<Button variant="primary" size="sm" onClick={() => setPresetYDates('currentMonth')} className="font-12 mr-1 mb-1">Mês Atual</Button>
									<Button variant="primary" size="sm" onClick={() => setPresetYDates('previousMonth')} className="font-12 mr-1 mb-1">Mês Anterior</Button>
									<Button variant="primary" size="sm" onClick={() => setPresetYDates('lastSixMonths')} className="font-12 mr-1 mb-1">Últimos 6 Meses</Button>
									<Button variant="warning" size="sm" onClick={() => setPresetYDates('currentYear')} className="font-12 mr-1 mb-1">Ano Atual</Button>
									<Button variant="warning" size="sm" onClick={() => setPresetYDates('previousYear')} className="font-12 mr-1 mb-1">Ano Anterior</Button>
								</div>
								<div>
									<Row>
										<Col xs="12"><label>Período Personalizado</label></Col>
										<Col md="4" className="mb-2">
											<Datetime 
												inputProps={{placeholder:'Data Inicial', className: 'form-control text-center', disabled: gettingYields}} 
												value={yDates.startDate}
												onChange={_moment => {
													if (showYields) {
														setShowYields(false);
													}
													setYDates({ ...yDates, startDate: _moment})
												}} 
												closeOnSelect={true} 
												dateFormat="LL" 
												timeFormat={false} 
											/>
										</Col>
										<Col md="4" className="mb-2">
											<Datetime 
												inputProps={{placeholder:'Data Final', className: 'form-control text-center', disabled: gettingYields || yDates.startDate === null}} 
												onChange={_moment => {
													if (showYields) {
														setShowYields(false);
													}
													setYDates({ ...yDates, endDate: _moment})
												}} 
												value={yDates.endDate}
												closeOnSelect={true}
												isValidDate={_current => yDates.startDate === null || _current.isAfter(yDates.startDate)}
												dateFormat="LL" 
												timeFormat={false} 
											/>
										</Col>
										<Col md="4" className="mb-2">
											<Button 
												onClick={() => getYields(yDates)}
												disabled={gettingYields || yDates.startDate === null || yDates.endDate === null}
												variant="light"
											>Buscar</Button>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{gettingYields ? (
				<div className="d-flex justify-content-center animated fadeIn faster">
						<div className="spinner-border" role="status"></div>
				</div>
			) : showYields ? (
			<Row className="animated fadeInUp faster">
				<Col>
					<YieldCardReport yDates={yDates} yields={yields} />
				</Col>
			</Row>
			) : (
				<div style={{minHeight:200}}>&nbsp;</div>
			)}
		</div>
	)
}