import React, {useEffect} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';

import Cookies from "helpers/cookies";

export default function StageSelectModule({data, userCtrl, setStage}) {

	function onSelectModule(Mod) {

		Cookies.set(
			process.env.REACT_APP_COOKIE_MODLOCK,
			Mod.Lock,
			new Date(data.session.expire),
			'/',
			process.env.REACT_APP_COOKIE_DOMAIN
		);

		data.modules.active = Mod.Name;

		if (Mod.Name === 'Settings' || (data.ready.instance && data.ready.companies)) {


			setTimeout(() => {
				userCtrl.setUser({
					...data.user,
					// name: data.user.name,
					// email: data.user.email,
					// phone: data.user.phone,
					// user: data.user.user,
					// picture: data.user.picture,
					// type: data.user.type,
					session: {
						instances: data.instances,
						modules: data.modules
					}
				});
				userCtrl.setLogged(true);
			}, 800);

			window.$('.bg-pglogin').toggleClass('fadeIn fadeOut');
			window.$('.stage.select-module .select-module').toggleClass('fadeInUp fadeOutDown');
			window.$('.stage.select-module .sm-title').toggleClass('fadeOutUp fadeInDown');

		} else {
			if (data.instances.length === 1) {
				setStage({to: 'SelectCompaniesInstance', data: {InstanceName: data.instances[0].Name, forback: data}});
			} else if (data.ready.instance) {
				setStage({
					to: 'SelectCompaniesInstance',
					data: {InstanceName: data.instances.filter(s => s.Selected === true)[0].Name, forback: data}
				});
			} else {
				setStage({to: 'SelectInstance', data});
			}
		}

	}

	useEffect(() => {
		let $stage = window.$('.stage.select-module');
		let heightTo = (window.innerHeight - $stage.height()) / 2;
		$stage.css('margin-top', heightTo < 0 ? 10 : heightTo + 'px');
	}, [])

	return (
		<Container className="stage select-module fadeIn animated">
			<h1 className="sm-title text-center fadeInDown animated">Módulos</h1>
			<Row className="justify-content-center">
				{
					!data.modules
						?
						<>Nenhum módulo disponível</>
						:
						data.modules.available.map((Mod, idx) => (
							<Col key={idx} sm="12" md="6" lg="4">
								<Card onClick={() => onSelectModule(Mod)} style={{borderRadius: '0px 64px'}}
											className="shadow select-module py-3 fadeInUp animated bg-white">
									<Card.Body className="text-center pt-1 text-dark">
										<h1>
											<i className={Mod.Icon}></i>
										</h1>
										<h3>{Mod.Title}</h3>
									</Card.Body>
								</Card>
							</Col>
						))
				}
			</Row>
		</Container>
	);
}