import React, {useEffect} from 'react';

import {Container, Row, Col} from 'react-bootstrap';
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';

import {Navigation, CustomRoutes} from './pages';
import Navbar from './components/navbar';

import './style.css';

export default function MainModule({logoutSession, setUser, user, sync, synchronize}) {

	const instance = user.session.instances.filter(Pr => Pr.Selected === true)[0];

	user.master = (user.type === 'ADM') ? true : !!instance.Master;

	useEffect(() => {

		window.$.App.init();

		window.$('.button-menu-mobile').off().on('click', function (event) {
			event.preventDefault();
			window.$.App.$body.toggleClass('sidebar-enable');
			if (window.innerWidth >= 768) {
				window.$.App.$body.toggleClass('enlarged');
			} else {
				window.$('.left-side-menu').removeClass('slideInLeft animated faster').toggleClass('slideInLeft animated faster');
				window.$.App.$body.removeClass('enlarged');
			}
			window.$.App._resetSidebarScroll()
		});

		window.$(".side-nav a").on('click', function (event) {
			let link = event.delegateTarget;
			if (window.$(link).parent().find('.side-nav-second-level').length === 0) {
				window.$.App.$body.removeClass('sidebar-enable');
			}
		});

		window.$('body').addClass('enlarged');

		window.$('.metismenu').find('.active, .in').removeClass('active in');

		setTimeout(() => {
			window.$('.fadeIn').removeClass('fadeIn animated delay-1s');
		}, 2000 /* 1s delay + 1s animation */);
	}, [])

	function navLinksCheck(item) {

		if (item.onlyMaster) {
			return user.master;
		}

		if (item.onlyInstances) {
			return item.onlyInstances.indexOf(instance.Id) !== -1;
		}

		return true;
	}


	return (
		<div className="wrapper fadeIn animated delay-1s">
			<Router>
				<div>
					<div className="left-side-menu">
						<div id="left-side-menu-container" className="ml-1 sticky-top">
							<div className="logo text-center pb-2">
                            <span className="logo-lg">
                                Orul
                            </span>
								<span className="logo-sm">
                                O
                            </span>
							</div>
							<ul className="metismenu side-nav">
								{Navigation.map((item, idx) =>
									(navLinksCheck(item)) &&
									<li key={idx} className="side-nav-item">
										<Link to={item.link} className="side-nav-link notranslate">
											<i className={item.icon}></i>
											{item.badge !== null && <span
												className={`badge badge-${item.badge.color}${item.badge.lighten ? '-lighten' : ''} float-right fadeInLeft animated`}>{item.badge.text}</span>}
											<span>{item.text}</span>
											{(item.badge == null && item.items.length > 0) && <span className="menu-arrow"></span>}
										</Link>
										{(typeof item.items === "object" && item.items.length > 0) && (
											<ul className="side-nav-second-level collapse" aria-expanded="false">
												{item.items.map((litem, lidx) => (
													(navLinksCheck(litem)) && (
														<li key={lidx}>
															<Link className="notranslate" to={litem.link}>{litem.text}</Link>
														</li>
													)
												))}
											</ul>
										)}
									</li>
								)}
								<li className="side-nav-item mt-3 d-block d-md-none">
									<a
										href="./"
										className="side-nav-link"
										onClick={(e) => {
											logoutSession();
											e.preventDefault();
										}}
									>
										<i className="dripicons-exit"></i>
										<span>Sair</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="content-page">
						<div className="content">
							<Navbar
								user={user}
								setUser={setUser}
								logoutSession={logoutSession}
								synchronize={synchronize}
							/>
							<Container fluid={true}>
								<Switch>
									{/* Navigation Routes */}
									{Navigation.map((item, idx) =>
										item.items.length > 0
											? item.items.map((litem, lidx) =>
												(navLinksCheck(litem)) &&
												<Route
													key={lidx}
													exact={litem.exact}
													path={litem.link}
													render={props => <litem.component route={props} sync={sync}/>}
												/>
											)
											:
											(navLinksCheck(item)) &&
											<Route
												key={idx}
												exact={item.exact}
												path={item.link}
												render={props => <item.component route={props} sync={sync}/>}
											/>
									)}
									{/* Custom Routes */}
									{CustomRoutes.map((Cr, idx) =>
										(navLinksCheck(Cr)) &&
										<Route
											key={idx}
											exact={Cr.exact}
											path={Cr.link}
											render={props => <Cr.component route={props} sync={sync}/>}
										/>
									)}
									{/* Page not found */}
									<Route component={() => (
										<Row className="justify-content-center mt-5">
											<Col lg="4">
												<div className="text-center">
													<img src="assets/images/file-searching.svg" height="90" alt="Página não encontrada"/>
													<h4 className="text-uppercase text-danger mt-3">Página não encontrada</h4>
													<p className="text-muted mt-3">A página que você tentou acessar não foi encontrada ou não está
														mais disponível. Verifique a URL e tente novamente ou clique no botão abaixo.</p>
													<Link to="/" className="btn btn-primary mt-3"><i className="mdi mdi-reply"></i> Página Inicial</Link>
												</div>
											</Col>
										</Row>
									)}
									/>
								</Switch>
							</Container>
						</div>
					</div>
				</div>
			</Router>
		</div>
	);
}