import React, { useState, useEffect, useRef } from "react";

import { Row, Col, Button } from "react-bootstrap";

import api from  "services/api";

import ChartSalesLast12Months       from './ChartSalesLast12Months';
import ChartSalesPaymentMethods     from './ChartSalesPaymentMethods';
import ChartSalesOfMonthPerWeeks    from './ChartSalesOfMonthPerWeek';
import TopSalesProducts             from './TopSalesProducts';
import Cards                        from './Cards';

import './style.css';

export default function Home({ sync }) {

    const [totalSalesOfMonth, setTotalSalesOfMonth] = useState('...')
    const [ufmc, setufmc] = useState(0)
    const btnFilterMonthCards = useRef(null)

    function onChangeFilterByMonthCards(e) {
        btnFilterMonthCards.current.innerHTML = `${window.moment(e.date).format("MMMM YYYY")} <i class="dripicons-calendar ml-2"></i>`;
        setufmc(e.date);
    }

    useEffect(() => {        
        window
        .$('#btnFilterByMonthCards[data-provide="datepicker"]')
        .on("changeDate", onChangeFilterByMonthCards);

        return () => {
            // eslint-disable-next-line
            btnFilterMonthCards.current.innerHTML = `${window.moment().format("MMMM YYYY")} <i class="dripicons-calendar ml-2"></i>`;
            api.cancelAllRequests();
        }
    }, []);

    return (
        <>
            {/* form filter by month to update Cards */}
            <Row>
                <Col>
                    <div className="page-title-box">
                        <div className="float-right" style={{marginTop: window.innerWidth >= 768 ? '28px' :'20px'}}>
                            <Button
                                variant="link"
                                size="sm"
                                className="text-muted"
                                id="btnFilterByMonthCards"
                                data-provide="datepicker"
                                data-date-autoclose="true"
                                data-date-format="MM yyyy"
                                data-date-min-view-mode="1"
                                data-date-end-date="0d"
                                ref={btnFilterMonthCards}
                            >{
                                window.moment().format("MMMM YYYY")
                            } <i className="dripicons-calendar ml-2"></i></Button>
                        </div>
                        <h4 className="page-title">Início</h4>
                    </div>
                </Col>
            </Row>
            
            {/* Cards (with Row) */}
            <Cards sync={sync} ufmc={ufmc} />

            <Row>
                <Col>
                    <ChartSalesLast12Months sync={sync} totalSalesOfMonth={totalSalesOfMonth} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ChartSalesOfMonthPerWeeks sync={sync} setTotalSalesOfMonth={setTotalSalesOfMonth} />
                </Col>
            </Row>
            <Row>
                <Col xl="7">
                    <TopSalesProducts sync={sync} />
                </Col>
                <Col xl="5">
                    <ChartSalesPaymentMethods sync={sync}  />
                </Col>
            </Row>
        </>
    )
}
