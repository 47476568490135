import React, { useState } from 'react'

import { Row, Col, Button } from 'react-bootstrap'

import StepInstance   from './StepInstance'
import StepLogo       from './StepLogo'
import StepUser       from './StepUser'

import './style.css'

export default function Implantation() {

    // const [Step, setStep] = useState({ current: 'Logo', data: { instance: { name: 'Anjos da Guarda', existing: false, id: '569d49ab-7f4c-4f2e-99dd-dd85ccb39f9f' } } });
    const [Step, setStep] = useState({ current: 'Instance', data: {} });
    
    const Steps = [
        { name: 'Instance', title: 'Banco de Dados' },
        { name: 'Logo', title: 'Logo' },
        { name: 'User', title: 'Usuário' },
        { name: 'Finalized', title: 'Finalizado' }
    ];

    return (
        <div id="implementation">
            <Row className="fadeIn animated">
                <Col>
                    <div className="page-title-box">
                        <h2 className="my-3 text-center">Implantação</h2>
                    </div>
                </Col>
            </Row>
            <Row className="d-none d-md-block fadeIn animated">
                <div className="d-flex justify-content-center">
                    <Col sm="11" md="10" lg="7">
                        <div className="horizontal-steps mb-4 pb-4">
                            <div className="horizontal-steps-content">
                                {Steps.map((item, idx) => (
                                    <div key={idx} className={`step-item${Step.current === item.name ? ' current':''}`}>
                                        <span>{item.title}</span>
                                    </div>
                                ))}
                            </div>
                            <div className={`process-line${Step.current === 'Finalized' ? ' bg-success':''}`} style={{width: ((Steps.findIndex(e => e.name === Step.current)/(Steps.length-1))*100) + '%'}}></div>
                        </div>
                    </Col>
                </div>
            </Row>
            <div className="fadeIn animated">
                {
                    Step.current === 'Instance'
                    ?
                        <StepInstance setStep={setStep} data={Step.data} />
                    :
                    Step.current === 'Logo'
                    ?
                        <StepLogo setStep={setStep} data={Step.data} />
                    :
                    Step.current === 'User'
                    ?
                        <StepUser setStep={setStep} data={Step.data} />
                    :
                    Step.current === 'Finalized'
                    ?
                        <Row className="justify-content-center mt-4">
                            <Col md="6">
                                <h1 className="text-center text-dark">Pronto!</h1>
                                <p className="mt-3 text-center text-muted">
                                    Implantação finalizada.
                                    <br />
                                    <Button className="mt-3" size="sm" onClick={() => setStep({ current: 'Instance', data: {} })}>Início</Button>
                                </p>
                            </Col>
                        </Row>
                    :
                        <>StepUnprocessable</>
                    
                }
            </div>
        </div>
    )
}