import React, {useEffect, useState} from 'react'
import ReactApexChart from "react-apexcharts";

import api from 'services/api'
import {realCurrency, decimalAdjust} from 'helpers/functions'
import {Card, ButtonGroup, Button, Row, Col, Dropdown, Table} from 'react-bootstrap'

const ww = window.innerWidth >= 768;

export default function ChartSalesOfMonthPerDays({sync}) {

	const [DataPrimary, SetDataPrimary] = useState([])
	const [DynamicDate, SetDynamicDate] = useState(null)
	const [ViewerType, SetViewerType] = useState('Chart')
	const [ChartRequestPending, SetChartRequestPending] = useState(false)
	const [ChartViewMode, SetChartViewMode] = useState('days')
	const [ChartType, SetChartType] = useState('line')
	const [ChartSeries, SetChartSeries] = useState([])
	const [ChartOptions, SetChartOptions] = useState({
		chart: {animations: {enabled: false,}, toolbar: {show: true,}, zoom: {enabled: false,}},
		dataLabels: {enabled: false},
		stroke: {curve: 'smooth', width: 1.5},
		legend: {
			showForSingleSeries: true,
			height: ww ? 40 : 20,
			offsetY: ww >= 768 ? -20 : 0,
			itemMargin: {vertical: 10},
		},
		tooltip: {y: {formatter: y => realCurrency(y)}},
		xaxis: {
			categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
		},
		yaxis: {show: false}
	})

	const [TableCategories, SetTableCategories] = useState(ChartOptions.xaxis.categories);


	useEffect(() => {

		let qs = new URLSearchParams(window.location.search);
		if (qs.has('dt')) {
			let date = qs.get('dt').split(';');
			if (date.length === 2 && !isNaN(date[0]) && !isNaN(date[1])) {
				let year = Number(date[0]);
				let month = Number(date[1]);
				if ((month >= 0 && month <= 11) && (year >= 2010 && year <= 2050)) {
					SetDynamicDate(new Date(Number(date[0]), Number(date[1])));
				}
			}
		}


		window
			.$('#btnAddMonthSales[data-provide="datepicker"]')
			.on("changeDate", e => {
				SetDynamicDate(e.date)
			});
	}, [])

	useEffect(() => {
		if (DynamicDate !== null) {
			getSalesOfMonthPerDays(DynamicDate);
		}
		// eslint-disable-next-line
	}, [DynamicDate])


	useEffect(() => {

		const mm = window.moment;
		let _ = [];
		let cat = [];

		switch (ChartViewMode) {

			case 'days':

				cat = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
				SetChartSeries([]);
				SetChartOptions({
					...ChartOptions,
					xaxis: {
						categories: cat
					}
				});
				SetTableCategories(cat);

				for (let x = 0; x < DataPrimary.length; x++) {
					let time = mm(DataPrimary[x].time);

					let _d = [];
					for (let y = 1; y <= 31; y++) {
						let _c = DataPrimary[x].data.filter(z => z.Day === y)[0];
						_d[y - 1] = typeof _c !== "undefined" ? _c.Total : 0;
					}

					_.push({name: time.format('MMMM YYYY'), data: _d});
				}

				break;

			case 'weekdays':

				const weekdays = mm.weekdays();

				SetChartSeries([]);
				SetChartOptions({
					...ChartOptions,
					xaxis: {
						categories: weekdays
					}
				});
				SetTableCategories(weekdays);

				for (let x = 0; x < DataPrimary.length; x++) {
					let time = mm(DataPrimary[x].time);

					let week = weekdays.map(i => ({weekName: i, total: 0}));

					let dd = DataPrimary[x].data.map(item => ({
						day: parseInt(item.Day),
						weekday: time.set('date', parseInt(item.Day)).weekday(),
						total: parseFloat(item.Total),
						quantity: parseFloat(item.Quantity),
					}));

					for (let k = 0; k < week.length; k++) {
						let ff = dd.filter(obj => k === obj.weekday);
						week[k].total = ff.reduce((v, obj) => obj.total + v, 0);
					}

					_.push({name: time.format('MMMM YYYY'), data: week.map(i => i.total)});
				}


				break;

			default:
				break;
		}

		SetChartSeries(_);
		// eslint-disable-next-line
	}, [ChartViewMode])

	function pushChartSerie(data, legend, moment) {

		let _v = ChartSeries;

		switch (ChartViewMode) {

			case 'days':

				let _d = [];
				for (let x = 1; x <= 31; x++) {
					let _c = data.filter(y => y.Day === x)[0];
					_d[x - 1] = typeof _c !== "undefined" ? _c.Total : 0;
				}

				_v.push({name: legend, data: _d});

				SetChartSeries([..._v]);

				break;

			case 'weekdays':

				let week = window.moment.weekdays().map(i => ({weekName: i, total: 0}));

				let dd = data.map(item => ({
					day: parseInt(item.Day),
					weekday: moment.set('date', parseInt(item.Day)).weekday(),
					total: parseFloat(item.Total)
				}));

				for (let k = 0; k < week.length; k++) {
					let ff = dd.filter(obj => k === obj.weekday);
					week[k].total = ff.reduce((v, obj) => obj.total + v, 0);
				}

				_v.push({name: legend, data: week.map(i => i.total)});

				SetChartSeries([..._v]);

				break;

			default:
				break;
		}

	}

	// useEffect(() => {
	//     console.log(DataPrimary);
	// }, [DataPrimary])

	async function getSalesOfMonthPerDays(e) {
		let moment = window.moment(e);
		let time = moment.format('MM MMMM YYYY').split(' ');
		let legend = time[1] + ' ' + time[2];

		if (ChartSeries.filter(x => x.name === legend).length > 0) {
			return;
		}

		SetChartRequestPending(true);

		const {data: {data}} = await api.get('/reports/sales/salesOfMonth', {
			filters: {date: {month: time[0], year: time[2]}}
		});

		let dts = data.map(x => {
			x.Day = parseInt(x.Day);
			x.Total = parseFloat(x.Total);
			x.Quantity = parseFloat(x.Quantity);
			return x;
		});

		SetDataPrimary([...DataPrimary, {time: DynamicDate, data: dts}]);

		pushChartSerie(dts, legend, moment);

		SetChartRequestPending(false);
	}

	function RenderChart({options, series, type}) {
		return <ReactApexChart options={options} series={series} type={type} height="350"/>;
	}

	function RenderTable({data}) {
		// style={{color:['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0'][x1]}}
		let Td = ({data, index}) => data.map((dat, x1) => (
			<td className="text-center" key={x1}>{realCurrency(dat.data[index])}</td>
		));

		return (
			<Table hover size="sm">
				<thead>
				<tr>
					<th scope="col">#</th>
					{data.map((dat, x1) => (
						<th key={x1} style={{width: 130, textAlign: 'center'}} scope="col">{dat.name}</th>
					))}
				</tr>
				</thead>
				<tbody>
				{TableCategories.map((cat, x1) => (
					<tr key={x1}>
						<th scope="row">{cat}</th>
						<Td data={data} index={x1}/>
					</tr>
				))}
				</tbody>
			</Table>
		);
	}

	function reset() {
		SetDataPrimary([]);
		SetDynamicDate(null);
		SetChartType('line');
		SetChartSeries([]);
	}

	useEffect(() => {
		if (sync > 0 && DynamicDate !== null) {
			reset();
		}
		// eslint-disable-next-line
	}, [sync])

	return (
		<Card>
			<Card.Body>
				<Card.Title>
					Dias <span className="text-muted">/</span> Semana
					{ChartSeries.length > 0 && (
						<Button
							size="sm"
							variant="link"
							className="float-right py-0 text-danger"
							onClick={reset}
						><i className="mdi mdi-filter-remove-outline"></i></Button>
					)}
					<Button
						size="sm"
						variant="outline-success"
						className="float-right py-0 border-0"
						id="btnAddMonthSales"
						data-provide="datepicker"
						data-date-autoclose="true"
						data-date-format="MM yyyy"
						data-date-orientation="right"
						data-date-min-view-mode="1"
						data-date-end-date="0d"
						disabled={ChartSeries.length >= 5}
					><i className="mdi mdi-plus mr-1"></i>Mês</Button>

					<ButtonGroup
						className={`float-right mr-2${ChartSeries.length === 0 ? ' d-none' : ViewerType === 'Table' ? ' d-none' : ''}`}
						size="sm" aria-label="ChartType">
						<Button
							disabled={ChartType === 'line'}
							onClick={() => SetChartType('line')}
							variant="outline-secondary"
							className="py-0"
						><i className="mdi mdi-chart-line-variant mr-1"></i>Linha</Button>
						<Button
							disabled={ChartType === 'bar'}
							onClick={() => SetChartType('bar')}
							variant="outline-secondary"
							className="py-0"
						><i className="mdi mdi-chart-bar mr-1"></i>Coluna</Button>
					</ButtonGroup>

					<Button
						size="sm"
						variant="outline-info"
						onClick={() => SetViewerType(ViewerType === 'Chart' ? 'Table' : 'Chart')}
						className={`float-right btn-icon py-0 mr-2${ChartSeries.length === 0 ? ' d-none' : ''}`}
					><i className={`mdi mdi-${ViewerType === 'Chart' ? 'table' : 'chart-line'}`}></i></Button>

					<Dropdown className={`float-right ml-2${ChartSeries.length === 0 ? ' d-none' : ''}`}>
						<Dropdown.Toggle variant="outline-primary" className="py-0 mr-2 btn-icon border-0" id="dropdown-basic">
							<i className="mdi mdi-eye-outline mr-1"></i>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Header>Visualização</Dropdown.Header>
							<Dropdown.Item disabled={ChartViewMode === 'days'} onClick={e => SetChartViewMode('days')}>Por
								dias</Dropdown.Item>
							<Dropdown.Item disabled={ChartViewMode === 'weekdays'} onClick={e => SetChartViewMode('weekdays')}>Por dia
								da semana</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>

				</Card.Title>
				<div>
					{ChartSeries.length === 0 ?
						(
							<div className="align-items-center d-flex justify-content-center text-center" style={{minHeight: 365}}>
                            <span>
                                <div>
                                    <img alt="reports"
																				 src={process.env.PUBLIC_URL + "/assets/images/report_sales_comparison_4272310.png"}
																				 width="64"/>
                                </div>
                                <strong>Vendas por dias dentro do mês.</strong>
                                <br/>
                                Adicione até 5 meses para comparação.
                            </span>
							</div>
						)
						:
						(
							<div className="mt-4">

								{
									ViewerType === "Chart"
										?
										<RenderChart options={ChartOptions} series={ChartSeries} type={ChartType}/>
										:
										ViewerType === "Table"
											?
											<RenderTable data={ChartSeries}/>
											:
											<>error</>
								}

								<Row className="d-flex justify-content-center mt-2">
									{DataPrimary.map((item, ix) => {
										const time = window.moment(item.time).format('MMMM YYYY')
										const total = item.data.reduce((v, x) => v + parseFloat(x.Total), 0)
										const quantity = item.data.reduce((v, x) => v + parseFloat(x.Quantity), 0)
										const dailyTicket = decimalAdjust('round', total / item.data.length, 1)
										const averageTicket = +(total / quantity).toFixed(2)

										return (
											<Col sm="2" key={ix}>
												<Card className="shadow-none text-center mb-2">
													<Card.Body className="p-0">
														<Card.Title className="mt-0"
																				style={{color: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0'][ix]}}>
															{time}
														</Card.Title>

														<strong>{realCurrency(total)}</strong>

														<p className="mt-2 mb-0" style={{fontSize: 13}}>
															<span>Média Diária</span>
															<br/>
															<strong>{realCurrency(dailyTicket)}</strong>
															<br/>
															<span>Ticket Médio</span>
															<br/>
															<strong>{realCurrency(averageTicket)}</strong>
														</p>
													</Card.Body>
												</Card>
											</Col>
										)
									})}
								</Row>

							</div>
						)
					}
				</div>
			</Card.Body>
			{ChartRequestPending && <div className="card-disabled">
				<div className="card-portlets-loader"></div>
			</div>}
		</Card>
	)
}