import Home                 from './home';
import FinancialCashFlow    from './financial/cashflow';
import FinancialYield       from './financial/yield';
import SalesTimes           from './sales/times';
import SalesTargets         from './sales/targets';
import Management           from './management';

export const CustomRoutes = [
    {
        link: '/management',
        exact: false,
        component: Management,
        onlyMaster: true
    }
];
export const Navigation = [
    {
        icon: 'dripicons-meter',
        text: 'Início',
        link: '/',
        exact: true,
        badge: null,
        component: Home,
        items: []
    },
    {
        icon: 'mdi mdi-bank',
        text: 'Financeiro',
        link: '',
        exact: false,
        badge: null,
        items: [
            { text: 'Fluxo de Caixa', link: '/financial/cashflow', component: FinancialCashFlow },
            { text: 'Yield', link: '/financial/yield', component: FinancialYield, onlyInstances: ['33f85d41-6ed6-49db-b385-839a4466a256'] },
        ]
    },
    {
        icon: 'mdi mdi-cart',
        text: 'Vendas',
        link: '',
        exact: false,
        badge: null,
        items: [
            { text: 'Períodos', exact: true, link: '/sales/time', component: SalesTimes },
            { text: 'Metas', link: '/sales/targets', component: SalesTargets }
        ],
    },
];