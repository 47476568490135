import React, {useState, useEffect} from "react";
import "./style.css";
import {Row, Col} from "react-bootstrap";

import StageLogin from './StageLogin';
import StageRegister from './StageRegister';
import StageSelectModule from './StageSelectModule';
import StageConfirmAccount from './StageConfirmAccount';
import StageResetPassword from './StageResetPassword';
import StageMessage from './StageMessage';
import StageSelectInstance from './StageSelectInstance'
import StageSelectCompaniesInstance from './StageSelectCompaniesInstance';

export default function Login({user, setUser, setLogged}) {

	const [Stage, SetStage] = useState({to: '', data: {}});

	function RenderStage() {
		let Current = null;
		let to = Stage.to;
		let data = Stage.data;

		if (user.session && to === '') {
			if ('ready' in user.session) {
				const {session, ...rest} = user;

				let todata = {
					user: rest,
					session: {
						token: session.token,
						expire: session.expire
					},
					ready: {instance: false, companies: false},
					instances: session.instances,
					modules: session.modules
				};
				if (!session.modules || !session.modules.active) {
					to = 'SelectModule';
					data = todata;
				} else if (!session.ready.instance) {
					to = 'SelectInstance';
					data = todata;
				} else if (!session.ready.companies) {
					to = 'SelectCompaniesInstance';
					data = {
						InstanceName: todata.instances.filter(i => i.Selected === true)[0].Name,
						forback: todata
					}
				}
			}
		}

		switch ((to === '') ? 'Login' : to) {
			case 'Login':
				Current = StageLogin;
				break;
			case 'SelectInstance':
				Current = StageSelectInstance;
				break;
			case 'SelectModule':
				Current = StageSelectModule;
				break;
			case 'SelectCompaniesInstance':
				Current = StageSelectCompaniesInstance;
				break;
			case 'Register':
				Current = StageRegister;
				break;
			case 'ConfirmAccount':
				Current = StageConfirmAccount;
				break;
			case 'ResetPassword':
				Current = StageResetPassword;
				break;
			case 'Message':
				Current = StageMessage;
				break;
			default:
				break;
		}

		return <Current data={data} userCtrl={{user, setUser, setLogged}} setStage={SetStage}/>
	}

	useEffect(() => {
		setTimeout(() => {
			window.$('body').removeClass('enlarged');
			window.localStorage.clear();
		}, 100);
	}, [])

	return (
		<>
			<div className="bg-pglogin fadeIn animated"></div>
			<RenderStage/>
			<footer className="d-none d-sm-block">
				<Row>
					<Col>
						<span>Orul Tech &copy; Todos os direitos reservados.</span>
					</Col>
					<Col className="text-right">
						<a className="text-dark mr-2" href="mailto:suporte@rhaimes.com.br">Ajuda</a>
						<span>Versão {process.env.REACT_APP_VERSION}</span>
					</Col>
				</Row>
			</footer>
		</>
	);
}
