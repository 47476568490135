import React, { useState, useEffect } from 'react';

import { Row, Col, Card, Tab, Tabs, ListGroup, FormControl, InputGroup, Button, Form, Dropdown, ButtonGroup } from 'react-bootstrap'

import api from 'services/api'

import { removeAccentuation } from 'helpers/functions'


export default function UserProfile({ match, history }) {

    const [user, dispatchUser] = useState(null)
    const [ldr, setldr] = useState(true)
    const [groups, setGroups] = useState(null)
    const [groupToUpdate, setGroupToUpdate] = useState(null);
    const [updatingGroup, setUpdatingGroup] = useState(false);

    const [confirmUnlinkUser, setConfirmUnlinkUser] = useState(false);

    const [commpercentage, setCommpercentage] = useState(null);
    const [awaitingper, setawaitingper] = useState(false);

    async function getUser(id) {
        try {
            const { data } = await api.get(`/management/users/${id}`);
            dispatchUser(data);
        } catch (err) {
            if (err.response) {
                let stt = err.response.data.status;
                if (stt === "UserNotFound") {
                    history.push('/management/users')
                }
            }
        }
    }

    async function getUserAccesses() {
        let idUser = user.Id;

        const info                      = api.get(`/management/users/${idUser}/info`);
        const accesses                  = api.get(`/management/users/${idUser}/accesses`);
        const companies_restrictions    = api.get(`/management/users/${idUser}/companies/restrictions`);
        setldr(true);
        const all = await Promise.all([ accesses, companies_restrictions, info ]);
        
        dispatchUser({ 
            ...user, 
            accesses: all[0].data, 
            companiesRestrictions: all[1].data, 
            info: all[2].data
        });
        setldr(false);
    }

    async function updateUserAccess(event, access) {
        let checked     = event.currentTarget.checked;
        let accesses    = user.accesses;
        let idxAccess   = accesses.findIndex(x => x.Id === access.Id);

        accesses[idxAccess].Allow = checked ? 'S' : 'N';

        dispatchUser({ ...user, accesses });
        document.body.classList.add('wait');
        
        try {
            await api.put(`/management/users/${user.Id}/accesses`, {
                access: access.Id,
                allow: checked ? 1 : 0
            });
        } catch (err) {
            if (err.response) {
                console.error(err.response);
            }
        }
        document.body.classList.remove('wait');

    }

    async function updateAllUserAccesses(event) {

        let checked   = event.currentTarget.checked;
        let data = user.accesses;
        let ids = [];
        data.forEach((item, idx) => {
            if ((item.filtered === undefined || item.filtered === true) && (item.Allow === 'S') !== checked) {
                ids.push(item.Id);
                data[idx].Allow = checked ? 'S' : 'N';
            }
        });

        dispatchUser({ ...user, accesses: data });

        document.body.classList.add('wait');

        try {
            await api.put(`/management/users/${user.Id}/accesses`, {
                access: ids.join(','),
                allow: checked ? 1 : 0
            });
        } catch (err) {
            if (err.response) {
                console.error(err.response);
            }
        }
        document.body.classList.remove('wait');

    }

    async function updateUserGroup(group) {

        setUpdatingGroup(true)
        try {
            await api.put(`/management/users/${user.Id}/group`, { group });
            dispatchUser({
                ...user,
                accesses: undefined,
                companiesRestrictions: undefined,
                info: undefined
            })
            setldr(true);
            getUserAccesses();
        } catch (err) {
            if (err.response) {
                alert('Não foi possível atualizar o grupo do usuário, erro interno no servidor :( desculpe...')
            }
        }
        setUpdatingGroup(false);
    }
    
    async function updateUserCompanyRestriction(event, company) {
        let checked     = event.currentTarget.checked;
        let companiesRestrictions    = user.companiesRestrictions;
        let idx   = companiesRestrictions.findIndex(x => x.Id === company.Id);
        
        companiesRestrictions[idx].Restrict = checked ? 'N' : 'S';

        dispatchUser({ ...user, companiesRestrictions });
        document.body.classList.add('wait');
        try {
            await api.put(`/management/users/${user.Id}/companies/restrictions`, {
                company: company.Id,
                restrict: ['N', null].indexOf(company.Restrict) !== -1 ? 0 : 1
            });
        } catch (err) {
            if (err.response) {
                console.error(err.response);
            }
        }
        document.body.classList.remove('wait');
    }

    async function updateUserInfo(e, info) {
        let usr = user;
        usr.info[info] = info === 'Inativo' ? e.currentTarget.checked ? 'N' : 'S' : e.currentTarget.checked ? 'S' : 'N';

        dispatchUser({ ...usr })
        document.body.classList.add('wait');

        try {
            await api.put(`/management/users/${user.Id}/info`, {
                info: info,
                control: usr.info[info] === 'S' ? 1 : 0
            });
        } catch (err) {}
        document.body.classList.remove('wait');

    }

    async function updateUserPercentageInfo(vl) {
        if (isNaN(vl) || parseFloat(vl) > 100) {
            setCommpercentage(null)
            return;
        }
        setawaitingper(true);
        try {
            await api.put(`/management/users/${user.Id}/info`, {
                info: 'Percentual',
                control: vl
            });
            dispatchUser({ ...user, info: { ...user.info, Percentual_Comissao_Atendente: vl === "0" ? null : vl } });
            if (vl === "0") {
                setCommpercentage(null)
            }
        } catch (err) {}
        setawaitingper(false);
    }

    async function unlinkUser() {
        try {
            await api.put(`/management/users/${user.Id}/unlink`);
            history.push('/management/users');
        } catch (err) {}
    }

    function isAllChecked(type, arrIdx) {
        let all = user[type].filter(x => typeof x.filtered === "undefined" || x.filtered === true);
        let allChecked = all.filter(x => (!arrIdx ? ['S','SIM'] : arrIdx).indexOf(x.Allow || x.Restrict) !== -1);
        
        return all.length !== 0 && allChecked.length === all.length;
    }

    useEffect(() => {
        if (user !== null) {
            if (!user.accesses && !user.companiesRestrictions && !user.info) {
                getUserAccesses();
            }
            // setTimeout(() => {
            //     window.$("#hn9r2y8934hi").mask('##,99%', { reverse: !0 });
            // }, 1)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // useEffect(() => {
    //     console.log(commpercentage);
    //     if (user && user.info) {
    //         console.log(user.info.Percentual_Comissao_Atendente);
    //     }
    // }, [commpercentage])

    function getGroups() {

        if (groups !== null) return;

        api.get('/management/usersGroups')
        .then(({data}) => {
            setGroups(data);
        })
        .catch(() => {});
    }

    useEffect(() => {
        getUser(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchUser])

    return (
        user !== null ? (

        <Row className="mt-4">
            <Col md="4">
                <Card className="shadow-none">
                    <Card.Body>
                        {   user.ProfilePicture !== null
                        ?
                            <img src={process.env.REACT_APP_API_STORAGE_BASEURL + '/users/images/pp/' + user.ProfilePicture} className="d-block mx-auto rounded-circle shadow-sm text-dark" alt="profile_picture" width="128" />
                        :
                            <div style={{width:128,height:128,lineHeight:'128px'}} className="rounded-circle shadow-sm text-dark text-center mx-auto">{user.Name.substr(0,2).toUpperCase()}</div>
                        }
                        <h3 className="text-center mt-3">{user.Name}</h3>
                        <div className="mt-3">
                            <ListGroup className="text-center">
                                <ListGroup.Item>{user.Username ? user.Username : <i className="text-muted">Sem Usuário</i>}</ListGroup.Item>
                                <ListGroup.Item>{user.Email ? user.Email : <i className="text-muted">Sem E-mail</i>}</ListGroup.Item>
                                <ListGroup.Item>{user.Phone ? user.Phone : <i className="text-muted">Sem Telefone</i>}</ListGroup.Item>
                                <ListGroup.Item><span className="text-muted">cadastrado</span>{" " + window.moment().to(user.Since).replace('em', 'há')}</ListGroup.Item>
                            </ListGroup>
                        </div>
                        <div className="my-4">
                        {confirmUnlinkUser ? (
                            <ButtonGroup className="d-flex animated fadeInUp faster">
                                <Button className="font-12 shadow-none text-dark pl-0" variant="link">Confirmar desvinculação:</Button>
                                <Button className="font-12 shadow-none" variant="success" onClick={unlinkUser}>Sim</Button>
                                <Button className="font-12 shadow-none" variant="danger" onClick={() => setConfirmUnlinkUser(false)}>Não</Button>
                            </ButtonGroup>
                        ):(
                            <Button className="font-12 shadow-none" variant="dark" onClick={() => setConfirmUnlinkUser(true)} block><i className="mdi mdi-account-remove-outline mr-1"></i> Desvincular Usuário</Button>
                        )}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md="8">
                {!ldr ? (
                <Card className="shadow-none">
                    <Card.Body>
                        <Tabs defaultActiveKey="home" className="justify-content-center" id="uncontrolled-tab-example">
                            <Tab eventKey="home" tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border" title="Geral">
                                {!!user.info && (
                                <Row className="justify-content-center">
                                    <Col lg="8">
                                    <ListGroup className="mt-5">
                                        <ListGroup.Item className="border-0 d-flex justify-content-between align-items-center">
                                            <span>Grupo</span>
                                            <Dropdown
                                                alignRight
                                                onToggle={(isOpen) => {
                                                    if (isOpen) {
                                                        getGroups()
                                                        setTimeout(() => {
                                                            window.$('select#isd89y84y5ojn').select2().on('select2:select', function(e){
                                                                let id = e.params.data.id;
                                                                setGroupToUpdate(id === "0" ? null : id);
                                                            });
                                                        }, 1);
                                                    }
                                                }}
                                            >
                                                <Dropdown.Toggle 
                                                    variant="link"
                                                    size="sm" 
                                                    className="shadow-none text-dark arrow-none" 
                                                    id="n9ht4ui34897bg"
                                                >
                                                    {user.info.Grupo}<i className="mdi mdi-square-edit-outline ml-1"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-animated dropdown-menu-right py-2 p-3" style={{width:314}}>
                                                    <h5 className="text-center">Alterar Grupo</h5>
                                                    <p className="text-muted text-center font-11">
                                                        {groups === null ? <span className="spinner-border spinner-border-sm" role="status"></span> : <>Selecione & Defina</>}
                                                    </p>
                                                    <div>
                                                        <Form.Group className="my-3" controlId="isd89y84y5ojn">
                                                            <Form.Control disabled={groups === null} as="select">
                                                                <option value="0">Selecionar</option>
                                                                {groups !== null && groups.map(($Item) => (
                                                                <option key={$Item.Id} value={$Item.Id}>{$Item.Group}</option>
                                                                ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Button type="button" variant="primary" onClick={() => updateUserGroup(groupToUpdate)} disabled={groupToUpdate === null || updatingGroup} className="mb-2" block>
                                                            {updatingGroup ? 'Definindo grupo...' : 'Definir'}
                                                        </Button>
                                                    </div>
                                                    <p className="text-muted text-center font-11">
                                                        Ao definir o mesmo grupo, os acessos serão redefinidos.
                                                    </p>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0 d-flex justify-content-between align-items-center">
                                            <span>Atendente</span>
                                            <input type="checkbox" id="9hshnbv8b734" onChange={e => updateUserInfo(e, 'Atendente')} checked={user.info.Atendente === 'S'} data-switch="success" />
                                            <label htmlFor="9hshnbv8b734" data-on-label="Sim" data-off-label="Não"></label>
                                        </ListGroup.Item>
                                        {user.info.Atendente === 'S' && (
                                            <ListGroup.Item className="border-0 d-flex justify-content-between align-items-center">
                                                <span>Percentual Comissão:</span>
                                                <InputGroup size="sm" style={{width:160}}>
                                                    <FormControl
                                                        placeholder="%"
                                                        id="hn9r2y8934hi"
                                                        autoComplete="off"
                                                        value={commpercentage || user.info.Percentual_Comissao_Atendente || ""}
                                                        onChange={e => setCommpercentage(e.currentTarget.value.replace(/[^0-9.]+/,'') || null)}
                                                        className="p-1 text-center"
                                                    />
                                                    {(commpercentage !== null && commpercentage !== user.info.Percentual_Comissao_Atendente) && (
                                                    <InputGroup.Append>
                                                        <Button onClick={() => updateUserPercentageInfo(commpercentage)} disabled={awaitingper} className="shadow-none" variant="success">
                                                            <i className={`mdi mdi-${awaitingper ? 'spin mdi-loading':'check'}`}></i>
                                                        </Button>
                                                        <Button onClick={() => setCommpercentage(null)} disabled={awaitingper} className="shadow-none" variant="danger"><i className="mdi mdi-close"></i></Button>
                                                    </InputGroup.Append>
                                                    )}
                                                </InputGroup>
                                                {/* <Form.Control type="text" id="hn9r2y8934hi" placeholder="%" style={{width:75}} size="sm" className="p-1 text-center" defaultValue={user.info.Percentual_Comissao_Atendente} /> */}
                                            </ListGroup.Item>    
                                        )}
                                        <ListGroup.Item className="border-0 d-flex justify-content-between align-items-center">
                                            <span>Caixa</span>
                                            <input type="checkbox" id="9hn38fnasd" onChange={e => updateUserInfo(e, 'Caixa')} checked={user.info.Caixa === 'S'} data-switch="success" />
                                            <label htmlFor="9hn38fnasd" data-on-label="Sim" data-off-label="Não"></label>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0 d-flex justify-content-between align-items-center">
                                            <span>Entregador</span>
                                            <input type="checkbox" id="t444ifhntii4" onChange={e => updateUserInfo(e, 'Entregador')} checked={user.info.Entregador === 'S'} data-switch="success" />
                                            <label htmlFor="t444ifhntii4" data-on-label="Sim" data-off-label="Não"></label>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0 d-flex justify-content-between align-items-center">
                                            <span>Ativo</span>
                                            <input type="checkbox" id="t444nh3490etyer" onChange={e => updateUserInfo(e, 'Inativo')} checked={!(user.info.Inativo === 'S')} data-switch="success" />
                                            <label htmlFor="t444nh3490etyer" data-on-label="Sim" data-off-label="Não"></label>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    </Col>
                                </Row>
                                )}
                            </Tab>
                            <Tab eventKey="accesses"  tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border" title="Acessos">
                                {!!user.accesses && (
                                    <ListGroup className="my-4">
                                            <ListGroup.Item className="p-0 border-0 mb-2 d-flex justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <span className="ml-2 pl-1 mr-2 text-muted">ID</span>
                                                    <Form.Control
                                                        type="text"
                                                        size="sm"
                                                        className="font-12 p-1 border-0"
                                                        placeholder="Pesquisar Acessos"
                                                        onChange={e => {
                                                            let txt = e.currentTarget.value;
                                                            dispatchUser({ 
                                                                ...user,
                                                                accesses: user.accesses.map(x => {
                                                                    x.filtered = txt === "" ? 
                                                                    undefined : (x.Id).toString().indexOf(txt) !== -1 
                                                                    ||
                                                                    removeAccentuation(x.Access.toLowerCase()).indexOf(removeAccentuation(txt.toLowerCase())) !== -1;
                                                                    return x;
                                                                })
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="custom-control custom-checkbox mr-1 mt-1">
                                                    <input type="checkbox" checked={isAllChecked('accesses')} onChange={updateAllUserAccesses} className="custom-control-input" id="asbd87293ghj" />
                                                    <label className="custom-control-label" htmlFor="asbd87293ghj">{" "}</label>
                                                </div>
                                            </ListGroup.Item>
                                        {user.accesses.filter(
                                            x => x.filtered === undefined || x.filtered === true
                                        ).map($Access => (
                                            <ListGroup.Item className="p-1 d-flex justify-content-between align-items-center" key={$Access.Id}>
                                                <div><span style={{width:40}} className="text-center text-muted d-inline-block">{$Access.Id}</span>{$Access.Access}</div>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" id={btoa($Access.Id)} checked={$Access.Allow === "S"} onChange={e => updateUserAccess(e, $Access)} className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor={btoa($Access.Id)}></label>
                                                </div>
                                            </ListGroup.Item>
                                            )
                                        )}
                                    </ListGroup>
                                )}
                            </Tab>
                            <Tab eventKey="companies" tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border" title="Controle Acesso Empresas">
                                {!!user.companiesRestrictions && (
                                    <ListGroup className="my-4">
                                        <ListGroup.Item className="p-0 border-0 mb-2 d-flex justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <span className="ml-2 pl-1 mr-2 text-muted">ID</span>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    className="font-12 p-1 border-0"
                                                    placeholder="Pesquisar Empresas"
                                                    onChange={e => {
                                                        let txt = e.currentTarget.value;
                                                        dispatchUser({ 
                                                            ...user,
                                                            companiesRestrictions: user.companiesRestrictions.map(x => {
                                                                x.filtered = txt === "" ? 
                                                                undefined : (x.Id).toString().indexOf(txt) !== -1 
                                                                ||
                                                                removeAccentuation(x.Name.toLowerCase()).indexOf(removeAccentuation(txt.toLowerCase())) !== -1;
                                                                return x;
                                                            })
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </ListGroup.Item>
                                    {user.companiesRestrictions.filter(
                                        x => x.filtered === undefined || x.filtered === true
                                    ).map($Company => (
                                    <ListGroup.Item className="p-1 d-flex justify-content-between align-items-center" key={$Company.Id}>
                                        <div><span className="mx-2 text-muted">{$Company.Id}</span>{$Company.Name}</div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" checked={['N',null].indexOf($Company.Restrict) !== -1} id={'restrict-company-'+$Company.Id} onChange={e => updateUserCompanyRestriction(e, $Company)} className="custom-control-input" />
                                            <label className="custom-control-label" htmlFor={'restrict-company-'+$Company.Id}></label>
                                        </div>
                                    </ListGroup.Item>
                                        )
                                    )}
                                </ListGroup>
                                )}
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
                ) : (
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border avatar-md" role="status"></div>
                    </div>
                )}
            </Col>
        </Row>
        ) : (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border avatar-md" role="status"></div>
            </div>
        )
    )
}