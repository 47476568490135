export function animateCSS(element, animationName, callback) {
    const node = document.querySelector(element)
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
        node.classList.remove('animated', animationName)
        node.removeEventListener('animationend', handleAnimationEnd)

        if (typeof callback === 'function') callback()
    }

    node.addEventListener('animationend', handleAnimationEnd)
}

export function getLast12Months() {
    let mm              = window.moment;
    let months          = mm.months();
    let monthsShort     = mm.monthsShort();
    let currentMonth    = mm().get('month');

    let monthsThisYear = [];
    let monthsLastYear = [];

    let monthsNumberThisYear = [];
    let monthsNumberLastYear = [];

    for( let i = 0; currentMonth > i; i++){
        monthsNumberThisYear.push(i);
        monthsThisYear.push(months[i]);
    }
    if (currentMonth < 11) {
        let lastYear = mm().subtract(1, 'year');
        for (let i = 11; currentMonth-1 < i; i--) {
            monthsNumberLastYear.push(i);
            monthsLastYear.push(monthsShort[i] + ' ' + lastYear.format('YY'));
        }
    }
    monthsLastYear = (monthsLastYear.length > 0) ? monthsLastYear.reverse().join(',') + ',' : '';
    return {
        arrayOfString: (monthsLastYear+monthsThisYear.join(',')).split(','),
        arrayOfNumber: monthsNumberLastYear.reverse().concat(monthsNumberThisYear)
    }
}

export function realCurrency(n) {
    return (typeof n !== "undefined" && n !== null) ? n.toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' }) : n;
}

export function getDaysOfMonth() {
    let mm      = window.moment;
    let lastDay = mm().daysInMonth();
    let days = [];

    for (let i = 1; i <= lastDay; i++) days.push(i);

    return {
        arrayOfNumber: days,
        arrayOfDates: days.map((day) => mm().format('YYYY-MM') + '-' + day.toString().padStart(2, "0") ),
        arrayOfTimestamp: days.map((day) => parseInt(mm().set({'D': day,'h':0,'m':0,'s':0,'ms': 0}).format('X')) )
    }
}

export function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

export function removeAccentuation(s){ 
    var map={"â":"a","Â":"A","à":"a","À":"A","á":"a","Á":"A","ã":"a","Ã":"A","ê":"e","Ê":"E","è":"e","È":"E","é":"e","É":"E","î":"i","Î":"I","ì":"i","Ì":"I","í":"i","Í":"I","õ":"o","Õ":"O","ô":"o","Ô":"O","ò":"o","Ò":"O","ó":"o","Ó":"O","ü":"u","Ü":"U","û":"u","Û":"U","ú":"u","Ú":"U","ù":"u","Ù":"U","ç":"c","Ç":"C"};
    return s.replace(/[\W ]/g,function(a){return map[a]||a}) 
};

export function getFormData(e, allowEmpty = false) {
    let data = {};
    window.$(e.currentTarget).serializeArray().forEach(le => {
        if (allowEmpty || le.value !== "") {
            data[le.name] = le.value;
        }
    });
    return data;
}