import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Card, Form, InputGroup, Button } from 'react-bootstrap'

import api from 'services/api'

export default function StageResetPassword({ setStage }) {

    const [user, setUser]               = useState('')
    const [signature, setSignature]     = useState(null)
    const [mode, setMode]               = useState('identify')
    const [reqPending, setReqPending]   = useState(false)
    const [resperr, setResperr]         = useState('')

    const [itCode, setCode]                   = useState('')
    const [itNewPasswd, setNewPasswd]           = useState('')
    const [itConfirmPasswd, setConfirmPasswd]   = useState('')

    async function onSubtmitReqResetPassword(e) {
        e.preventDefault();

        if (resperr !== '') {
            setResperr('');
        }

        setReqPending(true)
        switch (mode) {
            case 'identify':
                try {
                    if (user.length === 0) {
                        setReqPending(false)
                        return;
                    }
                    const { data } = await api.post('/users/resetpassword', { user, behavior: 'create' });
                    
                    setUser(data.user);
                    setSignature(data.signature);
                    setMode('code');
                } catch (err) {
                    if (err.response) {
                        let sttmsg = {
                            UserInvalid: 'Usuário inválido',
                            UserNotFound: 'Usuário não encontrado'
                        }
                        setResperr(sttmsg[err.response.data.status]);
                    }
                }
            break;
            case 'code':

                try {

                    await api.post('/users/resetpassword', { signature, code: itCode, behavior: 'validate' });

                    setMode('passwords');
                    
                } catch (err) {
                    if (err.response) {
                        let sttmsg = {
                            SignatureExpired: 'Tempo de validação expirou',
                            CodeInvalid: 'Código inválido',
                            SignatureDecryptionFailed: 'Erro interno.'
                        }
                        setResperr(sttmsg[err.response.data.status]);
                    }
                }

            break;
            case 'passwords':
                if (itNewPasswd.length === 0) {
                    setReqPending(false);
                    return;
                }

                if (itNewPasswd.length < 3) {
                    setResperr('Senha muito curta');
                    setReqPending(false);
                    return;
                }

                if (itConfirmPasswd.length === 0) {
                    setResperr('Confirme a Senha');
                    setReqPending(false);
                    return;
                }
                
                if (itNewPasswd !== itConfirmPasswd) {
                    setResperr('Senhas incorretas');
                    setReqPending(false);
                    return;
                }

                try {

                    await api.post('/users/resetpassword', { signature, passwd: itNewPasswd, behavior: 'passwd' });
                    
                    setTimeout(() => {
                        setStage({ to: 'Login', data: {} });
                    }, 2800);
                    setStage({ 
                        to: 'Message', 
                        data: {
                            type: 'success',
                            color: 'white',
                            icon: 'checkmark',
                            title: 'Senha Alterada',
                            description: () => (
                                <></>
                            )
                        } 
                    });

                } catch (err) {
                    
                }
                
            break;   
        default: break;
        }
        setReqPending(false);
    }

    
    useEffect(() => {
        let $stage = window.$('.stage.reset-password');
        let heightTo = (window.innerHeight-$stage.height())/2;
        $stage.css('margin-top',  heightTo < 0 ? 10 : heightTo + 'px');
    }, [])

    return (
        <Container className="stage reset-password fadeInDown faster animated">
            <Row className="justify-content-center">
                <Col md="6" lg="4">
                    <Card className="shadow-none reset-password bg-transparent">
                        <Card.Body>
                            <Card.Title as="h1" className="text-center">Redefinir Senha</Card.Title>
                            <Form onSubmit={onSubtmitReqResetPassword}>
                                {
                                    mode === 'identify'
                                    ?
                                        (
                                        <InputGroup size="sm" className="my-4">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroup-sizing-sm"><i className="mdi mdi-account-outline"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={user}
                                                onChange={e => setUser(e.currentTarget.value)}
                                                autoComplete="off"
                                                spellCheck={false}
                                                placeholder="Usuário, E-mail ou Telefone"
                                            />
                                        </InputGroup>
                                        )
                                    :
                                    mode === 'code'
                                    ?
                                        (
                                            <>
                                        <InputGroup size="sm" className="my-4">
                                            <Form.Control
                                                type="text"
                                                name="code"
                                                value={itCode}
                                                className="text-uppercase text-center"
                                                style={{letterSpacing:'15px', fontSize:'30px'}}
                                                maxLength={6}
                                                onChange={e => {
                                                    setCode(e.currentTarget.value);
                                                    setResperr('');
                                                }}
                                                autoComplete="off"
                                                spellCheck={false}
                                                placeholder="Código"
                                            />
                                        </InputGroup>
                                        <div className="mb-3 text-center">
                                            <small>{user.name.split(' ')[0]}, enviamos um código de confirmação<br />para seu e-mail.</small>
                                        </div>
                                        </>
                                        )
                                    :
                                    mode === 'passwords'
                                    ?
                                    (
                                        <>
                                        <InputGroup size="sm" className="my-2">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroup-sizing-sm"><i className="mdi mdi-lock-outline"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="password"
                                                name="newpasswd"
                                                value={itNewPasswd}
                                                onChange={e => setNewPasswd(e.currentTarget.value)}
                                                autoComplete="off"
                                                spellCheck={false}
                                                placeholder="Nova Senha"
                                            />
                                        </InputGroup>
                                        <InputGroup size="sm" className="mt-2 mb-4">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroup-sizing-sm"><i className="mdi mdi-lock-outline"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="password"
                                                name="confirmpasswd"
                                                value={itConfirmPasswd}
                                                onChange={e => setConfirmPasswd(e.currentTarget.value)}
                                                autoComplete="off"
                                                spellCheck={false}
                                                placeholder="Confirmar Senha"
                                            />
                                        </InputGroup>
                                        </>
                                    )
                                    :
                                    <></>
                                }
                                <div>
                                    <Button 
                                        block
                                        type="submit"
                                        size="sm"
                                        style={{borderRadius: '0px 16px'}}
                                        disabled={reqPending}
                                        variant={resperr !== '' ? 'danger':'warning'}
                                        className={'shadow py-1' + (resperr !== '' ? ' animated shake':'')}
                                    >{
                                        reqPending ? 
                                            <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
                                        : 
                                            resperr ? resperr : 'Enviar'
                                    }</Button>
                                </div>
                                {!reqPending && (
                                <div className="mt-3 text-center">
                                    <Button 
                                        size="sm"
                                        variant="link"
                                        className="text-dark font-12"
                                        onClick={() => setStage({ to: 'Login', data: {} })}
                                    >Cancelar</Button>
                                </div>
                                )}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}