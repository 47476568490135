import React, { useEffect, useState, useRef } from 'react';
import api from 'services/api';
import { Container, Row, Col, Card, Form, Button, Badge } from 'react-bootstrap';

export default function StageConfirmAccount({ data, setStage }) {

    const [MethodToSend, SetMethodToSend]       = useState(null);
    const [CodeSent, SetCodeSent]               = useState(false);
    const [RequestPending, SetRequestPending]   = useState(false);
    const [RequestResponse, SetRequestResponse] = useState(null);
    const [CodeOwner, SetCodeOwner]             = useState(null);
    const CodeInput = useRef(null);

    function sendCode(e) {
        e.preventDefault();
        SetRequestPending(true);

        api.post('/users/confirm', {
            process: 'send',
            method: MethodToSend,
            credentials: data.user
        }).then( ({data}) => {
            SetRequestPending(false);
            SetCodeOwner(data.code);
            SetCodeSent(true);
        }).catch( err => {
            console.log(err);
            SetRequestPending(false);
        });
    }

    function sendConfirmation(code) {

        SetRequestPending(true);
        SetRequestResponse(null);

        api.post('/users/confirm', {
            process: 'confirm',
            credentials: data.user,
            code: code,
            master: CodeOwner
        })
        .then( () => {

            setStage({
                to: 'Message',
                data: {
                    type: 'white',
                    animate: 'pulse',
                    color: 'dark',
                    icon: 'checkmark',
                    title: 'Conta Verificada',
                    description: () => (<div>
                        Sua conta foi verificada com sucesso! Obrigado :)
                        <br />
                        <p className="mt-3">
                            <Button variant="success" onClick={() => setStage({ to: '', data: {} })}>Acessar</Button>
                        </p>
                    </div>)
                }
            });

        }).catch( err => {
            if (err.response) {
                const { status } = err.response.data;
                let ms = {
                    CodeMasterNotSet: 'Erro na requisição.',
                    CodeInvalid: 'Código inválido.',
                    MasterTokenCodeExpired: 'Erro na requisição.',
                    CodeNotMatch: 'Código inválido.',
                    UserNotFound: 'Usuário inexistente.',
                    MasterCodeUnproccesable: 'Erro na requisição.'
                }
                SetRequestResponse(ms[status]);
            }
            SetRequestPending(false);
        });

    }
    useEffect(() => {
        SetRequestResponse(null);
        if (!CodeSent) {
            SetMethodToSend(null);
        }
    }, [CodeSent])

    useEffect(() => {
        let $stage = window.$('.stage.method-confirm-acc');
        let heightTo = (window.innerHeight-$stage.height())/2;
        $stage.css('margin-top',  heightTo < 0 ? 10 : heightTo + 'px');
    }, [])

    return (
        <Container className="stage method-confirm-acc fadeIn animated">
            <Row className="justify-content-center">
                <Col md="7" lg="5">
                    <Card className="shadow method-confirm-acc text-center bg-white pulse animated">
                        <Card.Header className="border-0">
                            <h1 className="mt-3 mb-0">Confirmar Conta</h1>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <Form name="confirm" onSubmit={CodeSent ? e => e.preventDefault() : sendCode}>
                                {
                                    CodeSent 
                                    ?
                                        <>
                                            <Form.Control
                                                type="text"
                                                className="text-center mb-2"
                                                placeholder="000000"
                                                maxLength="6"
                                                ref={CodeInput}
                                                onKeyPress={e => {
                                                    if(!/^[0-9]+$/.test(String.fromCharCode(e.keyCode || e.which))) e.preventDefault();
                                                }}
                                                onChange={e => {
                                                    SetRequestResponse(null);
                                                    if (e.currentTarget.value.length === 6) {
                                                        sendConfirmation(e.currentTarget.value);
                                                    }
                                                }} />
                                            {RequestResponse !== null && (
                                                <Badge variant="danger" className="mt-2">
                                                    {RequestResponse}
                                                </Badge>
                                            )}
                                            <div className="mt-4">
                                                <a 
                                                    href="./"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        SetCodeSent(false) 
                                                    }}
                                                >Não recebi o código!</a>
                                            </div>
                                        </>
                                    :
                                        <>
                                            <p className="mb-3">
                                                Selecione como quer receber o código de confirmação.
                                            </p>
                                            <Form.Group controlId="selected-method-confirm" onChange={e => {
                                                SetMethodToSend(e.target.value);
                                            }}>
                                                <Form.Check 
                                                    type="radio"
                                                    className="mb-2"
                                                    value="email"
                                                    id="ibu2gy342"
                                                    name="method-confirm-acc"
                                                    label={`E-mail ${data.user.email}`}
                                                />
                                                <Form.Check 
                                                    type="radio"
                                                    className="mb-2"
                                                    value="phone"
                                                    id="bxh2897364"
                                                    name="method-confirm-acc"
                                                    disabled
                                                    label={`SMS ${data.user.phone}`}
                                                />
                                            </Form.Group>
                                            <Button variant="link" disabled={RequestPending || MethodToSend === null} className="mt-1" type="submit">{
                                                RequestPending ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Enviando...
                                                    </>
                                                :
                                                    'Enviar Código'
                                            }</Button>
                                        </>
                                    }
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}