import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import { Row, Col, Card, Badge, ListGroup, Tab, Tabs, Button, Form } from 'react-bootstrap'

import api from 'services/api'

export default function UserLook(props) {

    const userId = props.match.params.id;

    const [User, setUser]               = useState(null);
    const [reqPending, setReqPending]   = useState(true);

    const [linking, setLinking] = useState({
        active: false,
        instances: null,
        useUserExists: false,
        rqp: false,
        selected: {
            instance: null,
            user: null
        }
    })

    async function getUser(id) {
        setReqPending(true);
        try {
            const { data } = await api.get(`/settings/users/${id}`);
            if (Object.keys(data).length > 0) {
                setUser(data);
            }
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            } else {
                alert('Erro Interno');
            }
        }
        setReqPending(false);
    }

    // async function getUserInstanceData(Instance) {

    //     let idxInstance = User.Instances.findIndex(i => i.Id === Instance.Id);
    //     let ins = User.Instances;

    //     if (ins[idxInstance].UserData === null) {
    //         return;
    //     }

    //     if (ins[idxInstance].UserData) {
    //         ins[idxInstance].UserData = undefined;
    //         setUser({ ...User, Instances: [...ins] });    
    //         return;
    //     }

    //     ins[idxInstance].UserData = null;
    //     setUser({ ...User, Instances: [...ins] });

    //     try {
    //         const { data } = await api.get(`/settings/instances/look/user/${Instance.IdRef}/data`, {
    //             instance: Instance.Id
    //         });
    //         ins[idxInstance].UserData = data;
    //         setUser({ ...User, Instances: [...ins] });
    //         console.log(data);
    //     } catch (err) {
    //         if (err.response) {
    //             console.log(err.response);
    //         }
    //     }

    // }
    // function getUserInstanceAccess(Instance) {

    // }

    function toggleConfirmRemoveInstance(Instance) {
        let ins = User.Instances;
        let idx = ins.findIndex(I => I.Id === Instance.Id);
        ins[idx].ConfirmRemove = !ins[idx].ConfirmRemove;
        setUser({ ...User, Instances: ins });
    }

    async function removeUserLinkingInstance(Instance) {

        let ins = User.Instances.filter(i => i.Id !== Instance.Id);
        
        setUser({ ...User, Instances: [...ins] });

        try {
            await api.post('/settings/instances/unlink/user', {
                instance: Instance.Id,
                user: userId
            });
        } catch (err) {
            if (err.response) {
                console.error(err.response);
            }
        }
        
    }

    async function getAllInstances() {
        try {
            const { data } = await api.get('/settings/instances');
            setLinking({ ...linking, instances: data });
        } catch (err) {
            if (err.response) {
                console.log(err.response);
                
            }
        }
    }
    async function getUsersOfInstance(instance) {
        try {
            const { data } = await api.get('/settings/instances/look/users', {
                instance: instance
            });
            let v   = linking.instances;
            let inx = linking.instances.findIndex(uu => uu.GUID === instance);
            v[inx].users = data;
            setLinking({ ...linking, instances: [...v] });
            console.log(data);
        } catch (err) {
            if (err.response) {
                console.error(err.response);
            }
        }
    }

    async function onFrmSubmitAddLinkInstance(e) {
        e.preventDefault();
        setLinking({ ...linking, rqp: true });
        let data = {
            userId: User.Id,
            userName: User.Name,
            instance: linking.selected.instance
        };

        if (linking.useUserExists) {
            data.userInstanceExists = 1;
            data.userInstanceId     = linking.selected.user;
        }

        try {
            const resp = await api.post('/settings/instances/link/user', data);
            let indxs = linking.instances.findIndex(I => I.GUID === linking.selected.instance);
            let iins = linking.instances[indxs];
            let vs = User.Instances;
            iins.IdRef = resp.data.id_ref;
            vs.push(iins);
            setUser({ ...User, Instance: [...vs]  });
            setLinking({ ...linking, selected: { instance: "" }, active: false, error: false, rqp: false });
            return;
        } catch (err) {
            if (err.response) {
                let sttmsg = {
                    InstanceInvalid: 'Instância inválida.',
                    UserInstanceNotRegistered: 'Não foi possível cadastrar usuário.',
                    UserInstanceAlreadyLinked: 'Já existe um vinculo para o usuário selecionado.'
                };
                setLinking({ ...linking, error: sttmsg[err.response.data.status], rqp: false });
                return;
            }
        }
        setLinking({ ...linking, rqp: false });
    }

    useEffect(() => {
        window.$('select.select2').off().select2().on('change', function(event) {
            let $select = window.$(event.currentTarget);
            let name    = $select.attr('name');
            let val     = $select.val();
            switch (name) {
                case 'instance':
                    setLinking({ ...linking, selected: { instance: val } });
                    break;
                case 'user':
                    setLinking({ ...linking, selected: { ...linking.selected, user: val } })
                    break;
                default: break;
            }
        });

        if (linking.active) {
            if (linking.instances === null) {
                getAllInstances();
            } else {
                if (!!linking.selected.instance && linking.useUserExists) {
                    let idx = linking.instances.findIndex(uu => uu.GUID === linking.selected.instance)
                    if (!linking.instances[idx].users) {
                        getUsersOfInstance(linking.selected.instance);
                    }
                }
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linking])

    useEffect(() => {
        if (parseInt(userId)) {
            getUser(userId);
        }
        return () => api.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Row className="justify-content-center mt-4">
            <Col md="12">
                {!reqPending ? (
                <Card className="shadow-none">
                    <Card.Body>
                        {User !== null ? (
                            <div>
                                <Row>
                                    <Col md="4" className="mb-4">
                                        {User.ProfilePicture !== null ?
                                            <img src={process.env.REACT_APP_API_STORAGE_BASEURL + '/users/images/pp/' + User.ProfilePicture} height="128" className="rounded-circle mx-auto d-block shadow-sm border border-white" alt="" />
                                        :
                                            <div style={{width:128,height:128,lineHeight:'128px',fontSize:18,fontWeight:'bold'}} className="rounded-circle text-center text-dark mx-auto shadow-sm">{User.Name.substr(0, 2).toUpperCase()}</div>
                                        }
                                        <h4 className="text-center text-dark mt-3">{User.Name}</h4>
                                        <div className="text-center">
                                            <Badge 
                                                variant={(parseInt(User.Verified) ? 'success': 'danger') + '-lighten'} 
                                                className="mt-2 px-2 py-1 rounded-pill" 
                                            >
                                                <i className={`mdi mdi-shield${(parseInt(User.Verified) ? '': '-off')}-outline mr-1`}></i>
                                                {parseInt(User.Verified) ? 'Verificado': 'Não Verificado'}
                                            </Badge>
                                            {User.Type === 'ADM' && (
                                                <Badge variant="warning" className="mt-2 ml-2 px-2 py-1 rounded-pill"><i className="mdi mdi-settings mr-1"></i>Administrador</Badge>
                                            )}
                                        </div>
                                        <div className="mt-3">
                                            <ListGroup className="text-center">
                                                <ListGroup.Item>{User.Username ? User.Username : <i className="text-muted">Sem Usuário</i>}</ListGroup.Item>
                                                <ListGroup.Item>{User.Email ? User.Email : <i className="text-muted">Sem E-mail</i>}</ListGroup.Item>
                                                <ListGroup.Item>{User.Phone ? User.Phone : <i className="text-muted">Sem Telefone</i>}</ListGroup.Item>
                                                <ListGroup.Item><span className="text-muted">cadastrado</span>{" " + window.moment().to(User.Since).replace('em', 'há')}</ListGroup.Item>
                                            </ListGroup>
                                        </div>
                                    </Col>
                                    <Col md="8">
                                        <Tabs
                                            className="justify-content-center border-0"
                                            defaultActiveKey="Instances"
                                            id="userLook-tab"
                                        >
                                            {/* <Tab eventKey="Home" title={<><i className="mdi mdi-account-circle-outline"></i> Informações</>} tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border border-dark">
                                                home
                                            </Tab> */}
                                            <Tab eventKey="Instances" title={<><i className="mdi mdi-server"></i> Instâncias</>} tabClassName="rounded-pill text-dark shadow-sm mr-2 mb-2 border border-dark">
                                                <div className="mt-5">
                                                    {User.Instances !== null ?
                                                    User.Instances.length > 0 ?
                                                    User.Instances.map((Instance, idx) => (
                                                    <div key={idx}>
                                                        <Card style={{overflow:'hidden'}}>
                                                            <Card.Body>
                                                                <div>
                                                                    <div className="text-center">
                                                                        {Instance.Brand !== null ? (
                                                                            <img src={process.env.REACT_APP_API_STORAGE_BASEURL + `/instances/brands/${Instance.Brand}`} alt={Instance.Name} width="128" />
                                                                        ) : (
                                                                            <div className="text-center text-dark text-uppercase mx-auto shadow-sm" style={{width:128,height:128,fontSize:22,fontWeight:'bold',lineHeight:'128px'}}>{Instance.Name.substr(0,2)}</div>
                                                                        )}
                                                                        <h3 className="mt-3">{Instance.Name}</h3>
                                                                        <div className="mt-3">
                                                                            <Badge variant="warning" className="py-1 px-2 rounded-pill"><i className="mdi mdi-link-variant mr-2"></i> <b>{Instance.IdRef}</b></Badge>
                                                                            {parseInt(Instance.Master) === 1 && (
                                                                                <Badge variant="primary" className="py-1 px-2 ml-2 rounded-pill">Master</Badge>
                                                                            )}
                                                                        </div>
                                                                        <div className="mt-4">        
                                                                            {/* <Button className="font-11 mr-2 btn-rounded" onClick={() => getUserInstanceData(Instance)} variant="outline-info" size="sm">Usuário<i className="ml-1 mdi mdi-shield-account"></i></Button> */}
                                                                            {/* <Button className="font-11 mr-2 btn-rounded" onClick={() => getUserInstanceAccess(Instance)} variant="outline-dark" size="sm">Acessos<i className="ml-1 mdi mdi-shape-outline"></i></Button> */}
                                                                            <Button className="font-11 btn-rounded" onClick={() => toggleConfirmRemoveInstance(Instance)} variant="outline-danger" size="sm">{Instance.ConfirmRemove ? 'Cancelar':'Remover'}<i className="ml-1 mdi mdi-close-circle-outline"></i></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* {typeof Instance.UserData !== "undefined" && (
                                                                <Card className="bg-info text-white mt-4 mb-2">
                                                                    <Card.Body>
                                                                        {Instance.UserData !== null ?
                                                                        <ul>
                                                                            {Object.keys(Instance.UserData).map((v,i) => (
                                                                                <li key={i}>{v} <span className="float-right">{Instance.UserData[v]}</span></li>
                                                                            ))}
                                                                        </ul>
                                                                        :
                                                                        <div className="text-center"><div className="spinner-border text-white" role="status"></div></div>
                                                                        }
                                                                    </Card.Body>
                                                                </Card>
                                                                )}
                                                                {typeof Instance.UserAccess !== "undefined" && (
                                                                <Card className="bg-dark text-white mt-4 mb-2">
                                                                    <Card.Body>
                                                                        {Instance.UserAccess !== null ?
                                                                        <>user access</>
                                                                        :
                                                                        <div className="text-center"><div className="spinner-border text-white" role="status"></div></div>
                                                                        }
                                                                    </Card.Body>
                                                                </Card>
                                                                )} */}
                                                                {Instance.ConfirmRemove && (
                                                                <div className="d-flex justify-content-between rounded align-items-center bg-danger fadeInUp faster animated text-white mt-3 p-2">
                                                                    <span>Tem certeza que deseja remover o vínculo desta instância?</span>
                                                                    <div className="float-right">
                                                                        <Button className="shadow-none rounded-pill bg-white border-0" variant="light" onClick={() => removeUserLinkingInstance(Instance)} size="sm">Sim</Button>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                    ))
                                                    :
                                                        <h4 className="text-center">Nenhuma instância vinculada!</h4>
                                                    :
                                                        <h4 className="text-center">Nenhuma instância vinculada!</h4>
                                                    }
                                                    {linking.active && (
                                                        <Card className="my-4">
                                                            <Card.Body>
                                                                {linking.instances !== null ?
                                                                <Form onSubmit={onFrmSubmitAddLinkInstance}>
                                                                    <h3 className="text-dark text-center mb-3">Vincular Instância</h3>
                                                                    <Form.Group controlId="frmLinkInstance.instance">
                                                                        <Form.Label>Instância</Form.Label>
                                                                        <Form.Control className="select2" name="instance" as="select">
                                                                            <option value="">Selecionar</option>
                                                                            {linking.instances.map((it, idx) => (
                                                                                <option value={it.GUID} disabled={User.Instances !== null && User.Instances.findIndex(I => I.Id === it.GUID) !== -1} key={idx}>{it.Name}</option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                    {(linking.useUserExists && !!linking.selected.instance) && (
                                                                    <>
                                                                    {   
                                                                        linking
                                                                        .instances[linking.instances.findIndex(e => e.GUID === linking.selected.instance)]
                                                                        .users ? (
                                                                            <Form.Group controlId="frmLinkInstance.instance">
                                                                                <Form.Label>Usuários</Form.Label>
                                                                                <Form.Control className="select2" name="user" as="select">
                                                                                    <option value="">Selecionar</option>
                                                                                    {linking.instances[linking.instances.findIndex(e => e.GUID === linking.selected.instance)].users.map((usr, idx) => (
                                                                                        <option key={idx} value={usr.ID_Usuario}>{usr.ID_Usuario} - {usr.Nome || usr.NomeCompleto}</option>
                                                                                    ))}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        )
                                                                        :
                                                                        (
                                                                            <div className="spinner-border text-dark m-2" role="status"></div>
                                                                        )
                                                                    }
                                                                    </>
                                                                    )}
                                                                    <div className="d-flex mt-3 justify-content-between align-items-center">
                                                                        <span>Vincular Usuário Existente</span>
                                                                        <div>
                                                                            <input type="checkbox" id="cmkkdonkn42341" checked={linking.useUserExists} onChange={e => setLinking({ ...linking, useUserExists: e.currentTarget.checked })} data-switch="success" />
                                                                            <label htmlFor="cmkkdonkn42341" data-on-label="Sim" data-off-label="Não"></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-center mt-4">
                                                                        {linking.error && (
                                                                            <p className="mb-3 text-danger">{linking.error}</p>
                                                                        )}
                                                                        <Button type="submit" disabled={linking.rqp || !((!!linking.selected.instance && !linking.useUserExists) || (!!linking.selected.instance && !!linking.selected.user))} variant="success">{linking.rqp ? 'Vinculando...':'Vincular'}</Button>
                                                                    </div>
                                                                </Form>
                                                                :
                                                                <div className="text-center"><div className="spinner-border text-dark" role="status"></div></div>}
                                                            </Card.Body>
                                                        </Card>
                                                    )}
                                                    <div className="mt-3 text-center">
                                                        <Button variant="link" onClick={() => setLinking({ ...linking, active: !linking.active })} className={`text-${linking.active ? 'danger': 'success'}`}>{linking.active ? 'Cancelar':'Vincular Instância'}</Button>
                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>

                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <div className="mt-4 text-center">
                                <h1>Usuário não encontrado</h1>
                                <br />
                                <br />
                                <Link to="/users">Voltar</Link>
                            </div>
                        )}
                    </Card.Body>
                </Card>
                ) : (
                    <div className="mt-4 text-center">
                        <div className="spinner-border avatar-md text-dark m-2" role="status"></div>
                    </div>
                )}
            </Col>
        </Row>
    );
}