import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Row, Col, Card } from 'react-bootstrap';

import CountUp from "react-countup";
import api from "services/api";
// import ls from "helpers/ls";
import { decimalAdjust } from "helpers/functions";
import Axios from 'axios';

const cardsStateDefault = () => ({
    Billed: {
        name: 'Faturado',
        icon: 'currency-usd',
        variant: 'success',
        value: '...',
        uri: '/reports/billed/total',
        linkable: '/sales/time',
        error: false,
    },
    Pay: {
        name: 'Pagar',
        icon: 'barcode',
        variant: 'danger',
        value: '...',
        uri: '/reports/pay/total',
        error: false,
    },
    Receive: {
        name: 'Receber',
        icon: 'receipt',
        variant: 'warning',
        value: '...',
        uri: '/reports/receive/total',
        error: false,
    },
    AccountBalances: {
        name: 'Saldo',
        icon: 'wallet-outline',
        variant: 'primary',
        value: '...',
        uri: '/reports/accountbalances/total',
        error: false,
    },
})

export default function Cards(props) {
    
    const { sync, ufmc } = props;

    const [RedirectTo, SetRedirectTo] = useState('')

    const [Cards, setCards] = useState(cardsStateDefault())

    // const lsKeyCards = 'reports@initials:cards'

    function getReportsCards(opt) {

        // eslint-disable-next-line
        for (const key in Cards) {
            if (Cards.hasOwnProperty(key)) {
                api
                .get(Cards[key].uri)
                .then(response => comReportsCards(key, null, response))
                .catch((error) => {
                    if (Axios.isCancel(error)) {
                        return
                    }
                    comReportsCards(key, true)
                })
            }
        }
    }

    function comReportsCards(type, error, response) {
        var cardsData = Cards;     
        var cardData = cardsData[type];

        if (error) {
            cardData.error = true
            setCards({ ...cardsData, [type]: cardData });
        } else {
            const { total, totalLastMonth } = response.data;
            cardData.value = total === null ? 0 : parseFloat(total);    
            cardData.valueLastMonth = totalLastMonth === null ? 0 : parseFloat(totalLastMonth);
            cardData.percentLastMonth = decimalAdjust('round', ((cardData.value-cardData.valueLastMonth)/cardData.valueLastMonth)*100, -1);
            setCards({ ...cardsData, [type]: cardData });
        }
        // if (type !== 'AccountBalances') {
        // } else {
        //     const { total } = axios.data;
        //     var _c = Cards;     
        //     var _cc = _c[type];
        //     _cc.value = total === null ? 0 : parseFloat(total);        
        //     setCards({ ..._c, [type]: _cc });
        // }
        // if (typeof axios.config.params.filter === "undefined") {
        //     let _v = {};
        //     Object.keys(_c).map(key => {
        //         return _v[key] = [_c[key].value,_c[key].value];
        //     });
        //     ls.set(lsKeyCards, _v);
        // }
    }

    function linkableCardRedirect(To) {
        let linkable = Cards[To].linkable;
        switch (To) {
            case 'Billed':
                let date = new Date(ufmc ? ufmc : new Date());
                SetRedirectTo(
                    linkable +
                    `/?dt=${date.getFullYear()};${date.getMonth()}`
                );
            break;
            default: break;
        }
    }

    function CountUpRender(end) {
        return (
            <CountUp
                end={end}
                separator="."
                decimals={2}
                decimal=","
                prefix="R$ "
            />
        );
    };

    // function setCardsValues(data) {
    //     let _c = Cards;
    //     Object.keys(typeof data === "object" ? data : Cards).map((CardIndex) => {
    //         let _cc = _c[CardIndex];
    //         _cc.value = typeof data === "object" ? data[CardIndex] : data;
    //         setCards({ ..._c, [CardIndex]: _cc });
    //         return null;
    //     });
    // }

    useEffect(() => {
        // if (ls.has(lsKeyCards)) { // not used
        //     try {
        //         let data = JSON.parse(ls.get(lsKeyCards));
        //         if (Object.values(data).indexOf('...') !== -1) {
        //             ls.remove(lsKeyCards);   
        //             getReportsCardsWithFilters();
        //         } else {
        //             setCardsValues(data);
        //         }
        //     } catch (error) {
        //         ls.remove(lsKeyCards);
        //         getReportsCardsWithFilters();
        //     }
        // } else {
            getReportsCardsWithFilters();
        // }
    // eslint-disable-next-line
    }, [])

    function getReportsCardsWithFilters() {
        if (ufmc) {
            api.setAlwaysSent("filter", { date: ufmc });
        }

        setCards(cardsStateDefault())

        getReportsCards();
        if (ufmc) {
            api.removeAlwaysSent("filter");
        }
    }

    useEffect(() => {
        if (sync > 0) {
            getReportsCardsWithFilters();
        }
    // eslint-disable-next-line
    }, [sync])

    useEffect(() => {
        if (ufmc !== 0) {
            getReportsCardsWithFilters();
        }
    // eslint-disable-next-line
    }, [ufmc])

    return (
        <>
            {RedirectTo !== '' && <Redirect to={RedirectTo} />}
            <Row>
                {Object.keys(Cards).map((Index, idx) => (
                    <Col xl="3" lg="6" key={idx} className={'p'+['r','x','x','l'][idx]+'-1'}>
                        <Card 
                            style={{
                                cursor: typeof Cards[Index].linkable !== "undefined" ? 'pointer' : 'default'
                            }} 
                            onClick={() => linkableCardRedirect(Index)} 
                            className={`border-top border-${Cards[Index].variant} card-fr`}
                        >
                            <Card.Body>
                                <div className="float-right">
                                    <i className={`mdi mdi-${Cards[Index].icon} widget-icon bg-${Cards[Index].variant} rounded-circle text-white`} />
                                </div>
                                <h5 className="text-muted font-weight-normal mt-0" title="Revenue">{Cards[Index].name}</h5>
                                <h3 className={`mt-4 mb-2${Cards[Index].value < 0 ? ' text-danger':''}`}>
                                    {   
                                        Cards[Index].error ?
                                            <span style={{ color: 'red' }}>Indisponível</span>
                                        :
                                        isNaN(Cards[Index].value) ?
                                            <div className={`spinner-grow spinner-grow-sm m-2 d-block text-${Cards[Index].variant}`} role="status"></div>
                                        :
                                            CountUpRender(Cards[Index].value)
                                    }
                                </h3>
                                {(Cards[Index].valueLastMonth && !isNaN(Cards[Index].value)) && (
                                    <p className="mb-0 text-muted">
                                        <span className={`text-${(Cards[Index].percentLastMonth > 0 && Index !== 'Pay') ? 'success':'danger'} mr-1`}><i className={`mdi mdi-arrow-${Cards[Index].percentLastMonth > 0 ? 'up':'down'}-bold`}></i>{Cards[Index].percentLastMonth}%</span>
                                        <span className="text-nowrap">Desde o mês anterior</span>  
                                    </p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}