import React, { useState, useEffect } from "react";

import ReactApexChart from "react-apexcharts";

import { Card, Button } from "react-bootstrap";
import { realCurrency, decimalAdjust } from "helpers/functions";
import api from "services/api";

export default function ChartSalesOfMonthPerWeek(props) {

    const { sync, setTotalSalesOfMonth } = props;

    const [loading, setLoading] = useState(true);

    const [serieA, setSerieA] = useState({data:[]})
    const [serieB, setSerieB] = useState({data:[]})
    const [serieC, setSerieC] = useState({data:[]})
    const [serieD, setSerieD] = useState({data:[]})

    const [nameSerieA, updateNameSerieA] = useState('Mês Atual')
    const [nameSerieB, updateNameSerieB] = useState(window.moment().subtract(1, 'month').format('MMMM'))

    const options = {
        chart: {
            width: '50%',
            animations: {
                enabled: false
            },
            toolbar: {
                tools: {
                    download: '<i className="mdi mdi-18px mdi-download"></i>'
                }
            },
        },
        colors: ['#47e685', '#47e685', '#ffa71a', '#ffa71a'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            lineCap: 'round',
            width: 4
        },
        // markers: {
        //     size: 3,
        // },
        tooltip: {
            y: [
                {
                    formatter: function (y, opt) {
                        if(typeof y === "number") {
                            if (typeof opt.series[2] !== "undefined" && y > 0) {
                                let ly = opt.series[2][opt.dataPointIndex];                                
                                if (typeof ly === "number" && ly > 0) {
                                    let calc = decimalAdjust('round', (((y-ly)/ly)*100), -1);
                                    ly = calc === 0 ? '' : ` (${(calc > 0) ? '+' : ''}${calc}%)`
                                } else {
                                    ly = '';
                                }
                                return realCurrency(y) + ly;
                            } else {
                                return realCurrency(y);
                            }
                        }
                        return y;
                        
                    }
                },
                { formatter: y => realCurrency(y) },
                { formatter: y => realCurrency(y) },
                { formatter: y => realCurrency(y) },
            ]
        },
        legend: {
            height: window.innerWidth >= 768 ? 40 : 20,
            offsetY: window.innerWidth >= 768 ? -20 : 0,
            itemMargin: {
                vertical: 10
            },
        },
        yaxis: [
            { show: false },
            { show: false },
            { show: false, opposite: true },
            { show: false, opposite: true },
        ],
        xaxis: {
            categories: window.moment.weekdays()
        }
    }

    function makeWeekSalesOfMonth(data, month, year) {
        let mm = window.moment;
        let week = mm.weekdays().map( i => ({ weekName: i, total: 0, averageTicket: 0 }));

        let dd = data.map(item => ({ 
            day: +item.Day, 
            weekday: mm().set({'year': +year, 'month': +month - 1, 'date': +item.Day}).weekday(), 
            total: parseFloat(item.Total),
            quantity: +item.Quantity,
        }));

        for (let k = 0; k < week.length; k++) {
            let ff = dd.filter( obj => k === obj.weekday);
            week[k].total = ff.reduce((v, obj) => obj.total + v ,0);
            week[k].averageTicket = +(week[k].total / ff.reduce((v, obj) => obj.quantity + v ,0)).toFixed(2)
        }
        return week;
    }

    function comSalesSerieA(name, data, month, year){
        const result = makeWeekSalesOfMonth(data, month, year)
        setSerieA({ name: name, type: 'line', data: result.map(i => i.total) });
        setSerieC({ name: name + ' (Ticket Médio)', type: 'column', data: result.map(i => i.averageTicket) })
    }
    function comSalesSerieB(name, data, month, year) {        
        const result = makeWeekSalesOfMonth(data, month, year)
        setSerieB({ name: name, type: 'line', data: result.map(i => i.total) })
        setSerieD({ name: name + ' (Ticket Médio)', type: 'column', data: result.map(i => i.averageTicket) })
    }
    function getSalesOfCustomMonth(month, year) {
        return api.get('/reports/sales/salesOfMonth', { filters: {date: { month: month, year: year }} });
    }
    function getSalesOfMonth() {
        setLoading(true);
        let mm = window.moment;
        let lastMonth = mm().subtract(1, 'month');
        let lastMonthName = lastMonth.format('MMMM');
        
        getSalesOfCustomMonth(mm().format('MM'), mm().format('YYYY'))
        .then(axios => {
            updateNameSerieA('Mês Atual');
            comSalesSerieA('Mês Atual', axios.data.data, mm().format('MM'), mm().format('YYYY'));
            let total = axios.data.data.reduce((v, item) => (item.Total !== null) ? parseFloat(item.Total) + v : v ,0).toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' });
            
            setTotalSalesOfMonth(total);
        }).catch(() => {
            setTotalSalesOfMonth('Indisponível')
        });
        
        getSalesOfCustomMonth(lastMonth.format('MM'), mm().year())
        .then(axios => {
            updateNameSerieB(lastMonthName);
            comSalesSerieB(lastMonthName, axios.data.data, lastMonth.format('MM'), mm().year());
        }).catch(() => {});
            
        setLoading(false);
    }

    async function onChangeFilterMonth(e) {
        let serie   = e.target.dataset.serie;
        let date    = e.date;
        let mm      = window.moment;
        let mdt     = mm(date); // moment date

        let name = mdt.format(mm().year() !== mdt.year() ? 'MMMM YYYY' : 'MMMM');
        let data = { month: mdt.format('MM'), year: mdt.format('YYYY') }

        if (mm().format('MM-YY') === mdt.format('MM-YY')) {
            name = 'Mês Atual';
        }

        switch (serie) {
            case 'A':
            
                setLoading(true);
                updateNameSerieA(name);
                setSerieA({data:[]});
                try {
                    const salesOfMonthA    = await getSalesOfCustomMonth(data.month, data.year);
                    comSalesSerieA(name, salesOfMonthA.data.data, data.month, data.year);
                } catch (error) {}
                setLoading(false);
            
            break;
            case 'B':
                setLoading(true);
                updateNameSerieB(name);
                setSerieB({data:[]});
                try {
                    const salesOfMonthB    = await getSalesOfCustomMonth(data.month, data.year);
                    comSalesSerieB(name, salesOfMonthB.data.data, data.month, data.year);
                } catch (error) {}
                setLoading(false);

            break;
            default: window.location.reload();
        }

    }

    function cardWidgetsFilters() {
        return <>
                    <Button
                        variant="link"
                        size="sm"
                        className="text-muted smpw-filter"
                        data-serie="A"
                        data-provide="datepicker"
                        data-date-autoclose="true"
                        data-date-format="MM yyyy"
                        data-date-min-view-mode="1"
                        data-date-default-view-date={window
                            .moment()
                            .format("MMMM YYYY")}
                        data-date-end-date="0d"
                        style={{
                            borderBottom: '2px solid ' + options.colors[0]
                        }}
                        onChange={e => {
                            console.log(e);
                        }}
                    >{nameSerieA}</Button>

                    <Button
                        variant="link"
                        size="sm"
                        className="text-muted smpw-filter"
                        data-serie="B"
                        data-provide="datepicker"
                        data-date-autoclose="true"
                        data-date-format="MM yyyy"
                        data-date-min-view-mode="1"
                        data-date-default-view-date={window
                            .moment()
                            .subtract(1, 'month')
                            .format("MMMM YYYY")}
                        data-date-end-date="0d"
                        style={{
                            borderBottom: '2px solid ' + options.colors[2]
                        }}
                    >{nameSerieB}</Button>
                    {/* <Button
                        variant="link" 
                        size="sm"
                        disabled={true}
                        style={{
                            borderBottom: '2px solid'
                        }}
                    >
                        <i className="dripicons-calendar"></i>
                    </Button> */}
                </>;
    }

    useEffect(() => {
        getSalesOfMonth()

        window
        .$('.smpw-filter[data-provide="datepicker"]')
        .on("changeDate", onChangeFilterMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (sync > 0) {
            setSerieA({data:[]});
            setSerieB({data:[]});
            setSerieC({data:[]});
            setSerieD({data:[]});

            getSalesOfMonth();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sync]);

    return (
        <Card>
            <Card.Body>
                <div className="card-widgets float-right mt-1">
                    {cardWidgetsFilters()}
                </div>

                <h4 className="header-title mt-2 mb-4"><i className="mdi mdi-calendar-week-begin"></i> Vendas por dia da semana</h4>
                
                <ReactApexChart style={{opacity: loading ? 0.5 : 1}} options={options} series={[serieA, serieC, serieB, serieD]} type="line" height="350" />
            </Card.Body>
            { loading && <div className="card-disabled"><div className="card-portlets-loader"></div></div> }
        </Card>
    )
}