import React from 'react'

import { Button, Card } from 'react-bootstrap'
import api from 'services/api'
import { realCurrency } from "helpers/functions";


export default function Accounts({
    accounts,
    setDataAccounts,
    setActivePageFlow,
    activePageFlow,
    toggleSelectionAccounts,
    // toggleSelectionAccountAlt
}) {

    function toggleSelectionAccountAlt(z,y) {
        let x = accounts;
        let s = x[z].data[y].selected;
        x[z].data[y].selected = typeof s === "undefined" ? true : !s;
        setDataAccounts([...x]);
    }

    function getAccountBalance(e, accountIndex, itemIndex) {
        e.preventDefault();
        let accounts_tmp = accounts;
        accounts_tmp[accountIndex].data[itemIndex].total = 'Carregando...';
        setDataAccounts([ ...accounts_tmp ]);
        api.get('/reports/financial/cashflow/balances', { filters: { accounts: [ accounts_tmp[accountIndex].data[itemIndex].Id ]} })
        .then( ({ data }) => {
            accounts_tmp[accountIndex].data[itemIndex].total = data.balances[0].AccountBalance;
            setDataAccounts([ ...accounts_tmp ]);
        })
        .catch(() => {});
    }

    return (
        <div className="accounts">
            <div className="float-right mb-3 d-none d-sm-inline-flex">
                <Button size="sm" disabled={accounts === null || accounts.length === 0} variant="link" onClick={() => setActivePageFlow(!activePageFlow)}>Visualizar Fluxo</Button>
                <div className="dropdown ml-2" style={{marginTop:7, display: (accounts === null || accounts.length === 0) ? 'none':'block'}}>
                    <a href="./" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-dots-vertical"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a href="./" onClick={e => {e.preventDefault(); toggleSelectionAccounts(true)}} className="dropdown-item">Marcar Todos</a>
                        <a href="./" onClick={e => {e.preventDefault(); toggleSelectionAccounts(false)}} className="dropdown-item">Desmarcar Todos</a>
                    </div>
                </div>

            </div>
            <Card.Title className="mb-4 mt-2">Contas</Card.Title>
            <div className="p-1 accsalt">
                {
                    accounts !== null 
                    ?
                        accounts.length === 0
                        ?
                            <span>Não há dados para exibir</span>
                        :
                        accounts.map((item, idx) => (
                            <div key={idx} className="mb-3">
                                <h5>
                                    {item.bankIcon === false ? <i className="mr-1 mdi mdi-bank"></i> : <i style={{color:item.bankIcon.color}} className={'mr-1 '+item.bankIcon.icon}></i>}
                                    {item.bankName === null ? 'Financeiros' : item.bankName}
                                </h5>
                                <ul className="list-group">
                                    {
                                        item.data.map((xitem, idy) => (
                                            <li key={idy} className="list-group-item" data-selected={xitem.selected === true}>
                                                {xitem.selected && <input type="checkbox" className="mr-2" checked readOnly /> }
                                                <span onClick={() => toggleSelectionAccountAlt(idx, idy)}>{xitem.Description}</span>
                                                <div className="float-right">
                                                    {
                                                        typeof xitem.total === "undefined"
                                                        ?
                                                            (
                                                                <a
                                                                    href="./"
                                                                    onClick={e => getAccountBalance(e, idx, idy)}
                                                                >Saldo</a>
                                                            )
                                                        :
                                                        isNaN(xitem.total) 
                                                        ?
                                                            (
                                                                <>{xitem.total}</>
                                                            )
                                                        :
                                                            (
                                                                <span className={`${xitem.total < 0 ? 'text-danger':'text-success'}`}>{realCurrency(xitem.total)}</span>
                                                            )
                                                        }
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))
                    :
                    <div className="d-flex justify-content-center mt-3 mb-3">
                        <div className="spinner-border" role="status"></div>
                    </div>
                }
            </div>
        </div>
    )
}