import React, { useState, useEffect } from 'react'

import { Row, Col, Card, Form, Button, Modal, ButtonGroup, Badge } from 'react-bootstrap'

import { Link } from 'react-router-dom'

import { getFormData } from 'helpers/functions'
import { LaravelPagination } from 'helpers/components'

import { Toast } from 'helpers/Hyper'

import api from 'services/api'


export default function UsersList({ history }) {

    const [users, dispatchUsers]            = useState(null)

    const [filters, dispatchFilters]        = useState({})

    const [currUsersPage, setCurrUsersPage] = useState(1)

    const [modalShowAddUser, setModalShowAddUser] = useState(false)

    const [modalShowLinkUser, setModalShowLinkUser] = useState(false)

    const [groups, setGroups]               = useState(null)

    const [usersInt, setUsersInt] = useState(null);

    const [addUserMode, setAddUserMode]     = useState(1)

    const [slcGroup, setSlcGroup]           = useState(null)

    const [slcUsrInt, setSlcUsrInt] = useState(null);

    const [addUser, setAddUser]             = useState({
        user: '',
        ckid: false,
        userInId: 0,
        err: null,
        submitting: false
    })

    const [regUser, setRegUser] = useState({
        name: '',
        username: '',
        phone: '',
        email: '',
        password: '',
        ckid: false,
        err: null,
        submitting: false
    })

    const [usersWaitingLink, setUsersWaitingLink] = useState([])

    function serachUsers(e) {
        e.preventDefault();
        let searchBy = getFormData(e);
        setCurrUsersPage(1);
        dispatchFilters(searchBy);
    }
    
    async function getUsers(filters, page) {
        filters.page = page;
        try {
            const { data } = await api.get('/management/users', filters);
            dispatchUsers(data);
        } catch (err) {
            Toast('Erro!','Não foi possível obter a lista de usuários.', undefined, undefined, 'error', 5000);
            dispatchUsers([]);            
        }
    }

    async function linkAddedUser(usr) {
        if (!usr.user) return;

        if (slcGroup === null && !usr.ckid) {
            setAddUser({ ...usr, err: 'Selecione o Grupo' })
            return;
        }
        if (usr.ckid && slcUsrInt === null) {
            setAddUser({ ...usr, err: 'Selecione o Usuário' })
            return;
        }

        setAddUser({ ...usr, err: '', submitting: true });

        let usrs = { user: usr.user, group: slcGroup };

        if (usr.ckid) {
            usrs.userIdTarget = slcUsrInt;
        }

        try {
            const { data } = await api.post('/management/users/link', usrs);

            history.push('/management/users/'+data.userId);

        } catch (err) {
            if (err.response) {
                let stts = {
                    UserNotFound: 'Usuário não encontrado!',
                    UserInstanceAlreadyLinked: 'Usuário já está vinculado!'
                };
                setAddUser({ ...usr, submitting: false, err: stts[err.response.data.status]})
            }
            
        }
    }

    function getGroups() {

        if (groups !== null) return;

        api.get('/management/usersGroups')
        .then(({data}) => {
            setGroups(data);
        })
        .catch(() => {
            Toast('Erro!','Não foi possível obter a lista de grupos.', undefined, undefined, 'error', 5000);
        });
    }

    async function registerUser(e, dtx) {
        e.preventDefault();

        let { err, submitting, ckid, userInId, ...usr } = dtx;

        if (Object.values(usr).map(x => x.length).indexOf(0) !== -1) {
            setRegUser({ ...dtx, err: 'Preencha todos os campos.' });
            return;
        }

        if (usr.username.length < 3) {
            setRegUser({ ...dtx, err: 'Nome de usuário muito curto. Digite no mínimo 3 letras.' });
            return;
        }

        if (slcGroup === null && !ckid) {
            setRegUser({ ...dtx, err: 'Selecione o Grupo' });
            return;
        }

        if (slcUsrInt === null && ckid) {
            setRegUser({ ...dtx, err: 'Selecione o Usuário' });
            return;
        }

        if (ckid) {
            usr.userIdTarget = slcUsrInt;
        }

        usr.phone = '55' + usr.phone.replace(/[^0-9]/g, '');
        usr.group = slcGroup;

        setRegUser({ ...dtx, err: null, submitting: true });
        try {
            const { data } = await api.post('/management/users/register', usr);

            history.push('/management/users/'+data.userId);
        } catch (err) {
            if(err.response) {
                let a = err.response.data;
                setRegUser({ ...dtx, err: Object.values(a)[0][0], submitting: false });
                
            }
        }

    }

    async function getCountRequestUsersLinkInstance() {
        try {
            const { data } = await api.get('/management/users/requestsLinkInstances');
            if (data.length) {
                setUsersWaitingLink(data);
            }
        } catch (e) {}
    }

    async function reproveUserLinkInstance(user) {
        try {
            await api.delete('/management/users/requestsLinkInstances', { userId: user.Id });
            if (usersWaitingLink.length === 1) {
                setModalShowLinkUser(false);
            }
            setUsersWaitingLink([
                ...usersWaitingLink.filter(x => x.Id !== user.Id)
            ]);
        } catch (err) {
            Toast('Erro!','Não foi possível reprovar a solicitação do usuário.', undefined, undefined, 'error', 5000);
            console.error(err.response);
        }
    }

    async function getInternalUsers() {

        if (usersInt !== null) return;

        try {
            const { data } = await api.get('/management/users/int');
            setUsersInt(data);
        } catch (err) {
            Toast('Erro!','Não foi possível obter a lista de usuários.', undefined, undefined, 'error', 5000);
        }
    }

    useEffect(() => {
        if (modalShowAddUser) {
            setTimeout(() => {
                window.$('#alksd23y8027nuihdfb827').parent().removeAttr('tabindex');
            }, 100);
        // if (!addUser.ckid || !regUser.ckid) {
            getGroups();
            setTimeout(() => {
                window.$('#askldjna89yno2,#ifush873nhasdio').select2().on('select2:select', function(e){
                    let id = e.params.data.id;
                    setSlcGroup(id === "0" ? null : parseInt(id));
                });
    
                window.$('#d2g9873rtb2iuuy3').mask('(00) 00000-0000');
            }, 1);
        // } else {
            getInternalUsers();
            setTimeout(() => {
                window.$('#d29783tbaijbshd,#asdj2n783nioan').select2().on('select2:select', function(e){
                    let id = e.params.data.id;
                    setSlcUsrInt(id === "0" ? null : parseInt(id));
                });
            }, 1);
        // }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addUser, regUser, modalShowAddUser])

    useEffect(() => {
        if (!modalShowAddUser) {
            setAddUserMode(1)
            setSlcGroup(null)
            setSlcUsrInt(null)
        }
    }, [modalShowAddUser])

    useEffect(() => {
        getUsers(filters, currUsersPage);
        getCountRequestUsersLinkInstance();
    }, [filters, currUsersPage, dispatchUsers])

    return (
        <>
        <Row className="mt-4">
            <Col md="3">
                <Card className="shadow-none mt-3">
                    <Card.Body className="p-0">
                        <Form onSubmit={serachUsers}>
                            <h3 className="mb-4 mt-0 text-center">Pesquisar</h3>
                            <Form.Group controlId="frmSearchUsers-access">
                                <Form.Control type="text" autoComplete="off" size="sm" name="access" placeholder="Usuário, E-mail ou Telefone" />
                            </Form.Group>
                            <Button type="submit" variant="secondary" className="shadow-none font-12" block><i className="mdi mdi-magnify mr-1"></i>Pesquisar</Button>
                        </Form>
                    </Card.Body>
                    <Button variant="success" className="mt-2 shadow-none font-12" block onClick={() => setModalShowAddUser(true)}><i className="mdi mdi-account-plus mr-1"></i>Adicionar Usuário</Button>
                    {usersWaitingLink.length > 0 && (
                        <Button variant="link" className="mt-4 text-danger shadow-none animated pulse delay-2s" block onClick={() => setModalShowLinkUser(true)}>
                            <i className="mdi mdi-account-settings"></i> {usersWaitingLink.length} {usersWaitingLink.length > 1 ? 'solicitações de acesso pendentes' : 'solicitação de acesso pendente'}
                        </Button>
                    )}
                </Card>
            </Col>
            <Col md="9">
                {
                    users !== null
                    ?
                        <div>
                            {users.total !== 0 ?
                                users.data.map(($User, idx) => (
                                <Card key={idx} className="shadow-sm">
                                    <Card.Body className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            {   $User.ProfilePicture !== null
                                            ?
                                                <img src={process.env.REACT_APP_API_STORAGE_BASEURL + '/users/images/pp/' + $User.ProfilePicture} className="rounded-circle shadow-sm" alt="profile_picture" width="64" />
                                            :
                                                <div style={{width:64,height:64,lineHeight:'64px'}} className="rounded-circle shadow-sm text-center">{$User.Name.substr(0,2).toUpperCase()}</div>
                                            }
                                            <div className="ml-3 d-inline-block">
                                                <h4 className="mb-0 mt-1">{$User.Name}</h4>
                                                <div className="font-10">{$User.Username || <i className="text-muted">Sem Usuário</i>}</div>
                                                <div className="font-10">{$User.Email || <i className="text-muted">Sem E-mail</i>}</div>
                                                <div className="font-10">{$User.Phone || <i className="text-muted">Sem Telefone</i>}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Link to={`/management/users/${$User.Id}`} className="btn btn-warning shadow-none font-12 p-1 mr-2"><i className="mdi mdi-account mr-1"></i>Perfil</Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                                ))
                            :
                                <h2 className="text-center mt-3 text-muted font-weight-light">Nenhum Usuário Encontrado</h2>
                            }
                            <LaravelPagination data={users} onClickPage={toPage => setCurrUsersPage(toPage)} justify="center" rounded />
                        </div>
                    :
                    <></>
                }
            </Col>
        </Row>
        <Modal show={modalShowLinkUser} tabIndex={0} id="asd283g7abjshbdasd234" size="lg" onHide={() => setModalShowLinkUser(false)} centered>
            <Modal.Body>
                <h3 className="text-center">
                    <i className="mdi mdi-account-settings"></i>
                    {" "}Solicitações de Acesso
                </h3>
                <div className="mt-4 px-5">
                    {usersWaitingLink.map(user => (
                        <Card key={user.Id}>
                            <Card.Body>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="align-items-center d-flex">
                                        {user.ProfilePicture !== null
                                        ?
                                        (<img src={process.env.REACT_APP_API_STORAGE_BASEURL + '/users/images/pp/' + user.ProfilePicture} className="rounded-circle shadow-sm" alt="profile_picture" width="64" />)
                                        :
                                        (<div style={{width:64,height:64,lineHeight:'64px'}} className="rounded-circle shadow-sm text-center">{user.Name.substr(0,2).toUpperCase()}</div>)
                                        }
                                        <div className="ml-2 mt-1">
                                            <b>{user.Name}</b>
                                            <br />
                                            <small>Usuário: {user.Username || (<i className="text-muted">Sem Usuário</i>)}</small>
                                            <br />
                                            <small>E-mail: {user.Email}</small>
                                            <br />
                                            <Badge variant="info">{window.moment().to(user.Time)}</Badge>
                                        </div>
                                    </div>
                                    <div>
                                    <ButtonGroup>
                                        <Button
                                            className="shadow-none font-13" 
                                            variant="success" 
                                            size="sm"
                                            onClick={
                                                () => {
                                                    setAddUser({ ...addUser, user: user.Username || user.Email || user.Phone });
                                                    setModalShowLinkUser(false);
                                                    setModalShowAddUser(true);
                                                }
                                            }
                                        >Aprovar</Button>
                                        <Button className="shadow-none font-13" onClick={() => reproveUserLinkInstance(user)} variant="danger" size="sm">Reprovar</Button>
                                    </ButtonGroup>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
                <div className="text-center">
                    <Button variant="link" onClick={() => setModalShowLinkUser(false)} className="p-0 font-12 text-dark">Fechar</Button>
                </div>
            </Modal.Body>
        </Modal>
        <Modal show={modalShowAddUser} id="alksd23y8027nuihdfb827" size="lg" onHide={() => setModalShowAddUser(false)} centered>
            <Modal.Body>
                <Row className="p-3 justify-content-center">
                    <Col xs="12" className="mb-3">
                        <h1 className="text-center"><i className="mdi mdi-account-plus"></i></h1>
                    </Col>
                    
                    <Col md="6" className={addUserMode ? '':'d-none'}>
                        <div className="text-center">
                            <h4>Vincular</h4>
                            <p className="text-muted mt-2">Vincule um usuário já existente.</p>
                            <Form onSubmit={e => e.preventDefault()}>
                                <Form.Group controlId="7402rinpos">
                                    <Form.Control type="text" autoComplete="off" value={addUser.user} onChange={e => setAddUser({ ...addUser, err: null, user: e.currentTarget.value})} placeholder="Usuário, e-mail ou telefone" />
                                </Form.Group>
                                
                                <Form.Check 
                                    custom
                                    type="checkbox"
                                    id="akjsdh2783"
                                    onChange={e => {setSlcGroup(null);setSlcUsrInt(null);setAddUser({ ...addUser, ckid: e.currentTarget.checked })}}
                                    checked={addUser.ckid}
                                    label={`Vincular com usuário interno`}
                                />

                                {!addUser.ckid ? (
                                <Form.Group className="my-3 text-left" controlId="askldjna89yno2">
                                    <Form.Control disabled={groups === null} as="select">
                                        <option value="0">Selecionar Grupo</option>
                                        {groups !== null && groups.map(($Item) => (
                                        <option key={$Item.Id} value={$Item.Id}>{$Item.Group}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                ) : (
                                <div className="my-3">
                                    <Form.Group className="my-3 text-left" controlId="d29783tbaijbshd">
                                        <Form.Control disabled={usersInt === null} as="select">
                                            <option value="0">Selecionar Usuário</option>
                                            {usersInt !== null && usersInt.map(($Usr) => (
                                                <option key={$Usr.ID_Usuario} value={$Usr.ID_Usuario}>{$Usr.ID_Usuario} - {$Usr.NomeCompleto || $Usr.Nome}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                )}
                                {addUser.err !== null && (
                                    <div className="animated shake">
                                        <Badge variant="danger" className="px-2 py-1">{addUser.err}</Badge>
                                    </div>
                                )}
                                <Button variant="success" onClick={() => linkAddedUser(addUser)} disabled={addUser.submitting} className="mt-3 shadow-none" block>{
                                    addUser.submitting ? 'Aguarde...' : 'Vincular'
                                }</Button>
                                <Button variant="link" className="text-dark mt-3 mb-0 p-0 font-12" style={{textDecoration:'underline'}} onClick={() => setAddUserMode(0)}>Cadastrar um novo usuário</Button>
                            </Form>
                        </div>
                    </Col>
                    
                    <Col md="6" className={addUserMode ? 'd-none':''}>
                        <div className="text-center">
                            <h4>Cadastrar</h4>
                            <p className="text-muted mt-2">Digite as informaçao para criar e vincular o usuário.</p>
                            <Form onSubmit={e => registerUser(e, regUser)}>
                                <Form.Group controlId="a8f478cwe">
                                    <Form.Control type="text" value={regUser.name} onChange={e => setRegUser({ ...regUser, err:null, name: e.currentTarget.value })} placeholder="Nome" />
                                </Form.Group>
                                <Form.Group controlId="kalsdoianshd87">
                                    <Form.Control type="text" value={regUser.username} onChange={e => setRegUser({ ...regUser, err:null, username: e.currentTarget.value })} placeholder="Usuário" />
                                </Form.Group>
                                <Form.Group controlId="d2g9873rtb2iuuy3">
                                    <Form.Control type="text" value={regUser.phone} onChange={e => setRegUser({ ...regUser, err:null, phone: e.currentTarget.value })} placeholder="Celular" />
                                </Form.Group>
                                <Form.Group controlId="sdnh890292">
                                    <Form.Control type="email" value={regUser.email} onChange={e => setRegUser({ ...regUser, err:null, email: e.currentTarget.value })} placeholder="E-mail" />
                                </Form.Group>
                                <Form.Group controlId="fdn29n84">
                                    <Form.Control type="password" value={regUser.password} onChange={e => setRegUser({ ...regUser, err:null, password: e.currentTarget.value })} placeholder="Senha" />
                                </Form.Group>
                                
                                <Form.Check 
                                    custom
                                    type="checkbox"
                                    id="aklsndh7829jkasd"
                                    onChange={e => {setSlcGroup(null);setSlcUsrInt(null);setRegUser({ ...regUser, ckid: e.currentTarget.checked })}}
                                    checked={regUser.ckid}
                                    label={`Vincular com usuário interno`}
                                />

                                {!regUser.ckid ? (
                                    <Form.Group className="my-3 text-left" controlId="ifush873nhasdio">
                                        <Form.Control disabled={groups === null} as="select">
                                            <option value="0">Selecionar Grupo</option>
                                            {groups !== null && groups.map(($Item) => (
                                            <option key={$Item.Id} value={$Item.Id}>{$Item.Group}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                ) : (
                                    <Form.Group className="my-3 text-left" controlId="asdj2n783nioan">
                                        <Form.Control disabled={usersInt === null} as="select">
                                            <option value="0">Selecionar Usuário</option>
                                            {usersInt !== null && usersInt.map(($Usr) => (
                                                <option key={$Usr.ID_Usuario} value={$Usr.ID_Usuario}>{$Usr.ID_Usuario} - {$Usr.NomeCompleto || $Usr.Nome}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                )}
                                {regUser.err !== null && (
                                    <div className="animated shake">
                                        <Badge variant="danger" className="px-2 py-1">{regUser.err}</Badge>
                                    </div>
                                )}
                                <Button variant="success" type="submit" disabled={regUser.submitting} className="mt-3 shadow-none" block>{
                                    regUser.submitting ? 'Aguarde...' : 'Cadastrar'
                                }</Button>
                                <Button variant="link" className="text-dark mt-3 mb-0 p-0 font-12" style={{textDecoration:'underline'}} onClick={() => setAddUserMode(1)}>Vincule um usuário já existente</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col xs="12" className="d-flex justify-content-center mt-4">
                        <Button size="sm" className="text-dark font-12" variant="link" onClick={() => setModalShowAddUser(false)}>Fechar</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        </>
    )
}