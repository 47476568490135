import React, { useState } from 'react'

import api from 'services/api'
import { realCurrency } from 'helpers/functions'

import { Row, Col, Card, Table, Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'

const tdStyFixWth = {width:150}
const tdStyFixWthLst = {width:90}

const funcRdcVal = (x,y) => Math.abs(Number(y.Value)) + x;

function RenderTableCashFlowDebits({ data, totalNetVal }) {

  const { costOfSales, others } = data.debits;

  let totalCostOfSales  = costOfSales.reduce(funcRdcVal, 0);
  let totalOthers       = others.reduce(funcRdcVal, 0);

  let items = [];

  items.push(
    <tr key={Math.random().toString(36)}>
      <td className="border-0"><i className="mdi mdi-minus-circle mr-1 text-danger"></i><b>CUSTO DE VENDAS</b></td>
      <td style={tdStyFixWth} className="border-0">&nbsp;</td>
      <td style={tdStyFixWth} className="border-0">&nbsp;</td>
      <td style={tdStyFixWthLst} className="border-0">&nbsp;</td>
    </tr>
  );

  
  if (costOfSales.length) {
    let asd234 = totalNetVal;

    for (let i = 0; i < costOfSales.length; i++) {
      let val = Math.abs(Number(costOfSales[i].Value || 0));
      asd234 -= val;
      let asi9uj = ((val / totalNetVal) * 100).toFixed(2);
      items.push(
        <tr key={costOfSales[i].Id || Math.random().toString(36)}>
          <td className="pl-3 pr-0 py-1">{costOfSales[i].Name || 'NÃO INFORMADO'}<i className="mdi mdi-minus mr-1 text-danger float-right"></i></td>
          <td className="py-1"><b>{realCurrency(val)}</b></td>
          <td className="py-1"><b className={`text-${asd234 > 0 ? 'success':'danger'}`}>{realCurrency(asd234)}</b></td>
          <td className="py-1">
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip>
                  <b className="font-11">{costOfSales[i].Name || 'NÃO INFORMADO'} corresponde a uma baixa de <span className="text-danger">{asi9uj}%</span> ao valor líquido de vendas ({realCurrency(totalNetVal)}).</b>
                </Tooltip>
              }
            >
              <b>{asi9uj}%</b>
            </OverlayTrigger>
          </td>
        </tr>
      );
    }
  
    
    let tnv = totalNetVal - totalCostOfSales;
    let fde = Math.abs(((-totalCostOfSales) / totalNetVal) * 100).toFixed(2);
  
    items.push(
      <tr key={'askdhn9283t7buiabsg9d'}>
        <td className="pb-4">
          <Badge variant={tnv > 0 ? 'success':'danger'} className="px-2 py-1 mr-3 border-0 float-right mt-2">{tnv > 0 ? 'LUCRO':'PREJUÍZO'} COM VENDAS</Badge>
        </td>
        <td>
          <div><b className="font-9">CUSTO</b></div>
          <b>{realCurrency(totalCostOfSales)}</b>
        </td>
        <td>
          <div><b className="font-9">RESULTADO</b></div>
          <b className={`text-${tnv > 0 ? 'success':'danger'}`}>{realCurrency(tnv)}</b>
        </td>
        <td>
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip>
                <b className="font-11">CUSTO DE VENDAS corresponde a uma baixa de <span className="text-danger">{fde}%</span> ao valor líquido de vendas ({realCurrency(totalNetVal)}).</b>
              </Tooltip>
            }
          >
            <div>
              <div><b className="font-9">BAIXA</b></div>
              <i className="mdi mdi-arrow-down-bold mr-1 text-danger"></i><b>{fde}%</b>
            </div>
          </OverlayTrigger>
        </td>
        {/* <td colSpan={4}>
          <div className="d-flex justify-content-end align-items-center mt-1 mb-3">
              <div className="px-2">
                <div><b className="font-9">DÉBITO</b></div>
                <b>{realCurrency(totalCostOfSales)}</b>
              </div>
              <div className="px-2">
                <div><b className="font-9">RESULTADO</b></div>
                <b className={`text-${tnv > 0 ? 'success':'danger'}`}>{realCurrency(tnv)}</b>
              </div>
              <div className="px-2">
                
              </div>
          </div>
        </td> */}
      </tr>
    );
  
  }
  
  if (others.length) {
    items.push(
      <tr key={'asdh823yd09bwf3i09rk03g9'}>
        <td className="border-0"><i className="mdi mdi-minus-circle mr-1 text-danger"></i><b>OUTROS DÉBITOS</b></td>
        <td className="border-0">&nbsp;</td>
        <td className="border-0">&nbsp;</td>
        <td className="border-0">&nbsp;</td>
      </tr>
    );

    let as1234 = totalNetVal - totalCostOfSales;
    let asdnhoj = as1234;

    for (let i = 0; i < others.length; i++) {
      let val = Math.abs(Number(others[i].Value || 0));
      as1234 -= val;
      let mnio = ((val / asdnhoj) * 100).toFixed(2);
      items.push(
        <tr key={others[i].Id || Math.random().toString(36)}>
          <td className="pl-3 pr-0 py-1">{others[i].Name || 'NÃO INFORMADO'}<i className="mdi mdi-minus mr-1 text-danger float-right"></i></td>
          <td className="py-1"><b>{realCurrency(val)}</b></td>
          <td className="py-1"><b className={`text-${as1234 > 0 ? 'success':'danger'}`}>{realCurrency(as1234)}</b></td>
          <td className="py-1">
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip>
                <b className="font-11">{others[i].Name || 'NÃO INFORMADO'} corresponde a uma baixa de <span className="text-danger">{mnio}%</span> ao custo de vendas ({realCurrency(asdnhoj)}).</b>
              </Tooltip>
            }
          >
            <b>{mnio}%</b>
          </OverlayTrigger>
          </td>
        </tr>
      );
    }

    let hgr = (totalNetVal - totalCostOfSales) - totalOthers;
    let mvfjrt = Math.abs((totalOthers / asdnhoj) * 100).toFixed(2);
    // let cbe = ((-totalOthers) / (totalNetVal - totalCostOfSales)) * 100;
  
    items.push(
      <tr key={'askjd9283yrn0928nbwsd02'}>
        <td></td>
        <td>
          <div><b className="font-9">DÉBITO</b></div>
          <b>{realCurrency(totalOthers)}</b>
        </td>
        <td>
          <div><b className="font-9">RESULTADO</b></div>
          <b className={`text-${hgr > 0 ? 'success':'danger'}`}>{realCurrency(hgr)}</b>
        </td>
        <td>
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip>
                <b className="font-11">OUTROS DÉBITOS corresponde a uma baixa de <span className="text-danger">{mvfjrt}%</span> ao custo de vendas ({realCurrency(asdnhoj)}).</b>
              </Tooltip>
            }
          >
            <div>
              <div><b className="font-9">BAIXA</b></div>
              <i className="mdi mdi-arrow-down-bold mr-1 text-danger"></i><b>{mvfjrt}%</b>
            </div>
          </OverlayTrigger>
        </td>
      </tr>
    );
  }

  return (
    <Table bordered={false} size="sm" className="font-12">
      <tbody>
        {items}
      </tbody>
    </Table>
  );
}

function RenderTableCashFlowCredits({ data, totalNetVal }) {

  let credits = data.credits;

  if (!credits.length) {
    return <></>;
  }

  const { costOfSales, others } = data.debits;

  let totalDebitsCostOfSales  = costOfSales.reduce(funcRdcVal, 0);
  let totalDebitsOthers       = others.reduce(funcRdcVal, 0);
  let totalDebits             = totalNetVal - (totalDebitsCostOfSales + totalDebitsOthers);

  let totalCredits = credits.reduce((x, y) => Number(y.Value) + x, 0);
  
  let items = [];

  items.push(
    <tr key={Math.random().toString(36)}>
      <td className="border-0"><i className="mdi mdi-plus-circle mr-1 text-success"></i><b>OUTROS CRÉDITOS</b></td>
      <td style={tdStyFixWth} className="border-0">&nbsp;</td>
      <td style={tdStyFixWth} className="border-0">&nbsp;</td>
      <td style={tdStyFixWthLst} className="border-0">&nbsp;</td>
    </tr>
  );


  credits.sort((x,z) => Number(x.Value) < Number(z.Value) ? 1 : Number(x.Value) > Number(z.Value) ? -1 : 0);
  let mfk44 = totalDebits;
  let sdna45 = totalNetVal - totalDebitsCostOfSales;

  for (let i = 0; i < credits.length; i++) {
    let credit  = credits[i];
    let val     = Number(credit.Value);
    mfk44 += val;
    let kjfwi = ((val / sdna45) * 100).toFixed(2);
    items.push(
      <tr key={credit.IdAccountSynthetic || Math.random().toString(36)}>
        <td className="pl-3 pr-0 py-1">{credit.AccountSyntheticName || 'NÃO INFORMADO'}<i className="mdi mdi-plus mr-1 text-success float-right"></i></td>
        <td className="py-1"><b>{realCurrency(val)}</b></td>
        <td className="py-1"><b className={`text-${mfk44 > 0 ? 'success':'danger'}`}>{realCurrency(mfk44)}</b></td>
        <td className="py-1">
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip>
                <b className="font-11">{credit.AccountSyntheticName || 'NÃO INFORMADO'} corresponde a um aumento de <span className="text-success">{kjfwi}%</span> ao custo de vendas ({realCurrency(sdna45)}).</b>
              </Tooltip>
            }
          >
            <b>{kjfwi}%</b>
          </OverlayTrigger>
        </td>
      </tr>
    );
  }

  let asr = (totalCredits / sdna45) * 100;
  let a2s = (totalDebits + totalCredits);

  items.push(
    <tr key={'askdbt2379diuhd22783234afir'}>
      <td>
      </td>
      <td>
        <div><b className="font-9">CRÉDITO</b></div>
        <b>{realCurrency(totalCredits)}</b>
      </td>
      <td>
        <div><b className="font-9">RESULTADO</b></div>
        <b className={`text-${a2s > 0 ? 'success':'danger'}`}>{realCurrency(a2s)}</b>
      </td>
      <td>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip>
              <b className="font-11">OUTROS CRÉDITOS corresponde a uma alta de <span className="text-success">{Math.abs(asr).toFixed(2)}%</span> ao custo de vendas ({realCurrency(sdna45)}).</b>
            </Tooltip>
          }
        >
          <div>
            <div><b className="font-9">ALTA</b></div>
            <i className="mdi mdi-arrow-up-bold mr-1 text-success"></i><b>{Math.abs(asr).toFixed(2)}%</b>
          </div>
        </OverlayTrigger>
      </td>
    </tr>
  );

  return (
    <Table bordered={false} size="sm" className="font-12">
      <tbody>
        {items}
      </tbody>
    </Table>
  );

}

export default function YieldCardReport({ yDates, yields }) {

  const [companies, setCompanies] = useState(-1);

  async function getCompanies() {

    setCompanies(null)

    try {
        const { data } = await api.get('/auth/session/instance/companies');
        setCompanies(data);
    } catch (err) {
        setCompanies([]);
        if (err.response) {
            if (err.response.status === 500) {
                alert('Erro interno! Não foi possível obter a lista de empresas.');
            }
        }
    }
  }

  const { cashflow } = yields;

  const totalDebits = cashflow.debits.costOfSales.reduce(funcRdcVal, 0)+cashflow.debits.others.reduce(funcRdcVal, 0);
  const totalCredits = cashflow.credits.reduce((x, y) => Number(y.Value) + x, 0);
  const total = (yields.paymentMethods.total - totalDebits) + totalCredits;

  return (
    <Card className="shadow-none">
      <Card.Body>
        <Row>
          <Col md="3">
            <small className="text-muted">Yield</small>
            {
              yDates.startDate.format('MMYYYY') === yDates.endDate.format('MMYYYY')
              ? (
                <h2>{yDates.startDate.format('MMM YYYY')}</h2>
              ) : (
                <>
                  <h3>{yDates.startDate.format('MMM YYYY')}</h3>
                  <h4>{yDates.endDate.format('MMM YYYY')}</h4>
                </>
              )
            }

            <div className="font-12 mt-3">
              <b>Período</b>
              <br />
              <div className="pl-2">
                <span>{yDates.startDate.format('DD/MM/YYYY')} - {yDates.endDate.format('DD/MM/YYYY')}</span>
              </div>
              <br />
              {companies === -1 ? (
                <Button variant="link" onClick={() => getCompanies()} className="font-12 d-print-none pl-0"><i className="mdi mdi-domain mr-1"></i>Mostrar Empresas</Button>
              ) : companies === null ? (
                <small><div className="spinner-border text-muted spinner-border-sm mr-1" role="status"></div>Carregando...</small>
              ) : (
                <div className="animated fadeInUp faster">
                  <b>Empresas</b>
                  <br />
                  {companies.filter(Cp => Cp.Selected).map(Cp => (
                    <div className="ml-2 font-11" key={Cp.Id}>{Cp.Name}</div>
                  ))}
                  <Button variant="link" onClick={() => setCompanies(-1)} className="font-11 d-print-none pl-0">Ocultar</Button>
                </div>
              )}
            </div>
          </Col>
          <Col md="9">
              <div className="card-group">
                <Card data-yd-signal="+" className="d-block card-yd widget-flat text-success shadow-none">
                  <Card.Body className="p-0">
                      <div className="py-2 pl-2">
                          <h6 className="font-weight-normal mt-0">A Vista</h6>
                          <h4 className="mt-2 mb-2">{realCurrency(yields.paymentMethods.instant.total)}</h4>
                          <small><b>{yields.paymentMethods.instant.percent.toFixed(2)}%</b></small>
                      </div>
                  </Card.Body>
                </Card>
                <Card data-yd-signal="-" className="d-block card-yd widget-flat text-info shadow-none">
                    <Card.Body className="p-0">
                        <div className="py-2 pl-2">
                            <h6 className="font-weight-normal mt-0">A Prazo</h6>
                            <h4 className="mt-2 mb-2">{realCurrency(yields.paymentMethods.term.total)}</h4>
                            <small><b>{yields.paymentMethods.term.percent.toFixed(2)}%</b></small>
                        </div>
                    </Card.Body>
                </Card>
                <Card data-yd-signal="-" className="d-block card-yd widget-flat text-danger shadow-none">
                    <Card.Body className="p-0">
                        <div className="py-2 pl-2">
                            <h6 className="font-weight-normal mt-0">Devoluções</h6>
                            <h4 className="mt-2 mb-2">{realCurrency(Math.abs(yields.salesReturnAndTaxes.returns))}</h4>
                        </div>
                    </Card.Body>
                </Card>
                <Card data-yd-signal="=" className="d-block card-yd widget-flat text-warning shadow-none">
                    <Card.Body className="p-0">
                        <div className="py-2 pl-2">
                            <h6 className="font-weight-normal mt-0">Impostos</h6>
                            <h4 className="mt-2 mb-2">{realCurrency(Math.abs(yields.salesReturnAndTaxes.taxes))}</h4>
                        </div>
                    </Card.Body>
                </Card>
                <Card className="d-block card-yd widget-flat text-dark shadow-none">
                      <Card.Body className="p-0">
                          <div className="py-2 pl-2">
                              <h6 className="font-weight-normal mt-0">Vendas Líquidas</h6>
                              <h4 className="mt-2 mb-2">{realCurrency(yields.paymentMethods.total)}</h4>
                              <small>&nbsp;</small>
                          </div>
                      </Card.Body>
                  </Card>
              </div>
              <Row className="mt-3">
                <Col>
                  {/* Cashflow Debits */}
                  <RenderTableCashFlowDebits data={cashflow} totalNetVal={yields.paymentMethods.total} />
                  <RenderTableCashFlowCredits data={cashflow} totalNetVal={yields.paymentMethods.total} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="card-group">
                    <Card className="d-block card-yd widget-flat shadow-none">
                      <Card.Body className="p-0">
                        <div className="py-2 pl-2">
                          <h6 className="mt-0">DÉBITOS</h6>
                          <h4 className="mt-2 mb-2">
                            <i className="mdi mdi-arrow-down-bold mr-1 text-danger"></i>
                            {realCurrency(Math.abs(totalDebits))}
                          </h4>
                        </div>
                      </Card.Body>
                    </Card>
                    <Card className="d-block card-yd widget-flat shadow-none">
                      <Card.Body className="p-0">
                        <div className="py-2 pl-2">
                          <h6 className="mt-0">CRÉDITOS</h6>
                          <h4 className="mt-2 mb-2">
                            <i className="mdi mdi-arrow-up-bold mr-1 text-success"></i>
                            {realCurrency(totalCredits)}
                          </h4>
                        </div>
                      </Card.Body>
                    </Card>
                    <Card className={`d-block card-yd widget-flat shadow-none`}>
                      <Card.Body className="p-0">
                        <div className="py-2 pl-2">
                          <h6 className="mt-0">{total > 0 ? 'LUCRO' : 'PREJUÍZO'} LÍQUIDO</h6>
                          <h4 className={'mt-2 mb-2 text-'+(total > 0 ? 'success' : 'danger')}>{realCurrency(total)}</h4>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}